export const selectDefinedProperties = (obj) =>
	Object.entries(obj || {})
		// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
		.filter(([_, value]) => value !== undefined)
		.reduce((definedProperties, [paramKey, paramValue]) => {
			definedProperties[paramKey] = paramValue;
			return definedProperties;
		}, {});

/**
 * @method isEmpty
 * @param {String | Number | Object} value
 * @returns {Boolean} true & false
 * @description this value is Empty Check
 */
export const isEmpty = (value) => {
	if (value === null) {
		return true;
	} else if (typeof value !== 'number' && value === '') {
		return true;
	} else if (typeof value === 'undefined' || value === undefined) {
		return true;
	} else if (value !== null && typeof value === 'object' && !Object.keys(value).length) {
		return true;
	} else {
		return false;
	}
};
