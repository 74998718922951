import { requestAsync, mergeData } from './';

export default ({ courseId, userId, pageId, force = false }) =>
	requestAsync({
		path: '/api/courses/v1/analytics/page_activity',
		params: { course_id: courseId, user_id: userId, page_id: pageId },
		transform: (payload) => {
			return { pageActivities: { [userId + ':' + pageId]: { ...payload } } };
		},
		update: { pageActivities: mergeData },
		force
	});
