import './AnswerActions.scss';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import AnswerCommentForm from './AnswerCommentForm';
import { formatTime } from 'Utils/format';
import ActivityHeader from '../ActivityHeader';
import { InstructorFeedbackBody } from '@soomo/lib/components/pageElements/Comments';

class AnswerActions extends Component {
	static propTypes = {
		answer: PropTypes.object,
		commentKey: PropTypes.string,
		comment: PropTypes.object,
		posting: PropTypes.string,
		onEditComment: PropTypes.func,
		onSaveComment: PropTypes.func,
		onUnpostAnswer: PropTypes.func,
		onViewAllResponses: PropTypes.func,
		historyButton: PropTypes.object,
		commentRequiresPosting: PropTypes.bool,
		commentWithoutAnswer: PropTypes.bool,
		commentLocked: PropTypes.bool,
		timeZone: PropTypes.string
	};

	constructor(props) {
		super(props);

		this.state = {
			commentFormVisible: false
		};
	}

	setCommentTextareaRef = (ref) => {
		this.commentTextareaRef = ref;
	};

	showCommentFormClickHandler = () => {
		this.setState({ commentFormVisible: true });
		this.props.onEditComment && this.props.onEditComment(true);
		const textarea = ReactDOM.findDOMNode(this.commentTextareaRef);
		if (textarea) textarea.focus();
	};

	hideCommentForm = () => {
		this.setState({ commentFormVisible: false });
		this.props.onEditComment && this.props.onEditComment(false);
	};

	onSaveComment = (draftCommentBody) => {
		this.props.onSaveComment(draftCommentBody).then(this.hideCommentForm);
	};

	unpostAnswerClickHandler = () => {
		this.props.onUnpostAnswer();
		this.hideCommentForm();
	};

	viewAllResponsesClickHandler = (event) => {
		event.preventDefault();
		this.props.onViewAllResponses();
	};

	render() {
		const {
			answer,
			commentKey,
			comment,
			posting,
			onSaveComment,
			onUnpostAnswer,
			onViewAllResponses,
			historyButton,
			commentRequiresPosting,
			commentWithoutAnswer,
			commentLocked,
			timeZone
		} = this.props;
		const { commentFormVisible } = this.state;

		const seenAtHover =
			comment && comment.seen_at != null
				? 'Seen on ' +
				  formatTime({ time: comment.seen_at, timeZone, withoutYear: true, withoutTimeZone: true })
				: null;
		const showComment = comment && comment.body && !commentFormVisible;

		const seenStatus = showComment && (
			<span styleName="CommentSeen" title={seenAtHover}>
				({comment.seen_at ? 'Seen' : 'Unseen'})
			</span>
		);

		return (
			<div>
				{showComment && (
					// the `key` prop here is intentional; we MUST destroy and recreate this subtree upon changing to another
					// student, because otherwise the InstructorFeedbackBody may cause React's VDOM to desync with the real DOM
					// due to video players like Kaltura modifying the DOM upon load. See T-77468.
					<div data-test="answer-actions-instructor-comment" key={commentKey}>
						<ActivityHeader timestamp={comment.last_saved_at} timeZone={timeZone} subject="comment">
							COMMENT {comment.last_saved_at === comment.first_saved_at ? 'ADDED' : 'EDITED'}{' '}
							{seenStatus}
						</ActivityHeader>

						<div styleName="Comment">
							<div data-test="answer-actions-instructor-comment-body" styleName="CommentBody">
								<InstructorFeedbackBody commentBody={comment.body} instructor />
							</div>
						</div>
					</div>
				)}
				<div styleName="Controls">
					{(answer || commentWithoutAnswer) &&
						!commentLocked &&
						onSaveComment &&
						commentFormVisible && (
							<AnswerCommentForm
								savedCommentBody={comment && comment.raw_body}
								onSaveComment={this.onSaveComment}
								onCancel={this.hideCommentForm}
								setRef={this.setCommentTextareaRef}
							/>
						)}
					{!this.state.commentFormVisible && (
						<div styleName="Buttons">
							{(answer || commentWithoutAnswer) && (
								<span>
									{!commentLocked && onSaveComment && (
										<button
											styleName="Button"
											onClick={this.showCommentFormClickHandler}
											disabled={commentRequiresPosting && !posting}>
											{comment && comment.body ? 'Edit Comment' : 'Add Comment'}
										</button>
									)}
									{answer && onUnpostAnswer && (
										<button
											styleName="Button"
											onClick={this.unpostAnswerClickHandler}
											disabled={!posting}>
											Unpost
										</button>
									)}
								</span>
							)}
							{onViewAllResponses && (
								<button styleName="Button" onClick={this.viewAllResponsesClickHandler}>
									View all responses
								</button>
							)}
							{historyButton}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default AnswerActions;
