import React from 'react';

export const SupportPhone = '888-240-0314';
export const SupportEmail = 'support@soomolearning.com';

const ErrorMessage = () => (
	<p>
		Please call support at{' '}
		<a href={`tel:${SupportPhone}`} target="_blank">
			{SupportPhone}
		</a>
		, email us at{' '}
		<a href={`mailto:${SupportEmail}`} target="_blank">
			{SupportEmail}
		</a>
		, or click the round button in the lower right.
	</p>
);

export default ErrorMessage;
