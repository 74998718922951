import React from 'react';
import { bool, func, string } from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import styles from './NudgeArrow.scss';

const NudgeArrow = ({ direction, onClick, disabled }) => (
	<IconButton
		key={direction}
		disabled={disabled}
		size={'large'}
		classes={{ root: styles.NudgeArrow }}
		onClick={onClick}
		aria-label={`go ${direction}`}>
		{direction === 'forward' ? (
			<ArrowRightIcon classes={{ root: styles.NudgeArrowIcon }} />
		) : (
			<ArrowLeftIcon classes={{ root: styles.NudgeArrowIcon }} />
		)}
	</IconButton>
);

NudgeArrow.propTypes = {
	direction: string.isRequired,
	onClick: func,
	disabled: bool
};

export default NudgeArrow;
