/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './UnknownElement.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import pageViewStyles from '../PageView.scss';

const UnknownElement = (props) => {
	const label = props.element.type.replace(/_/g, ' ');
	return (
		<div styleName="styles.UnknownElement">
			<div styleName="pageViewStyles.QuestionType">{label}</div>
			<p>
				{`The ${label} element is not yet viewable in Analytics. We're working to implement it.`}
			</p>
		</div>
	);
};

export default UnknownElement;
