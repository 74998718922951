import React from 'react';

class PageViewNoMatchingStudents extends React.Component {
	render() {
		const style = {
			width: '100%',
			background: 'white',
			display: 'flex',
			flexFlow: 'column nowrap',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: '1.5em'
		};
		return (
			<div style={style}>
				<p style={{ padding: '100px' }}>No students match the currently selected filter.</p>
			</div>
		);
	}
}

export default PageViewNoMatchingStudents;
