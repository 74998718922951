import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { QuestionBulkGrader, GoReactBulkGrader } from './ElementViews';

const ElementView = (props) => {
	const { element } = props;
	let ViewTemplate;

	if (!element) {
		// To avoid error when navigating out of this template
		ViewTemplate = null;
		// Interactive template will be enabled somewhere in the future
		// } else if (/response_board|sa_question|interactive_template/.test(element.type)) {
	} else if (/response_board|sa_question/.test(element.type)) {
		ViewTemplate = QuestionBulkGrader;
	} else if ('score_config' in element) {
		ViewTemplate = GoReactBulkGrader;
	} else {
		throw new Error('Unsupported element type.');
	}

	return ViewTemplate ? (
		<div style={{ width: '100%', height: '100%' }}>
			<ViewTemplate />
		</div>
	) : null;
};

ElementView.propTypes = {
	element: object
};

const mapStateToProps = (state) => {
	let elementId = state.ui.currentViewParams.element;
	if (elementId) elementId = elementId.split(':', 1)[0];

	return {
		element: state.entities.elements[elementId]
	};
};

export default withRouter(connect(mapStateToProps)(ElementView));
