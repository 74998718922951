import React from 'react';
import { css } from 'emotion';

import LoadingShimmer from './LoadingShimmer';

interface Props {
	label: string;
	data: string | undefined;
}

const InfoItem: React.FC<Props> = (props) => {
	const { label, data } = props;

	return (
		<div className={infoItemStyles}>
			<span className="label">{label}</span>
			{data === undefined ? <LoadingShimmer /> : <span>{data}</span>}
		</div>
	);
};

export default InfoItem;

export const infoItemStyles = css`
	display: flex;
	align-items: flex-end;

	&:not(:first-child) {
		margin-top: 15px;
	}

	.label {
		font-weight: 500;
		margin-right: 5px;
	}
`;
