import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as uiActions from 'Actions/uiActions';
import CompletionPercentageCell from 'Components/GridCells/CompletionPercentageCell';
import RequiresActionCell from 'Components/GridCells/RequiresActionCell';

import { metricsQueryConfig } from 'Requests/MetricsQuery';
import { compose } from 'redux';
import { connectRequest } from 'redux-query-react';
import { pick } from 'lodash-es';

const ChapterCompletionCell = ({ data, chapter, uiActions, ...rest }) => {
	const onClick = useCallback(() => {
		uiActions.updateCurrentViewParams({
			gridViewColumnScopeType: 'page',
			scope: chapter.family_id
		});
	}, [chapter.family_id, uiActions]);

	if (data?.requires_action) return <RequiresActionCell onClick={onClick} />;

	return <CompletionPercentageCell {...data} {...rest} onClick={onClick} />;
};

ChapterCompletionCell.propTypes = {
	uiActions: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	chapter: PropTypes.object,
	data: PropTypes.object,
	loaded: PropTypes.bool
};

const mapStateToProps = (state, props) => {
	const userIds = props.fetchRowGroupUserIds({ rowGroup: props.rowGroup });
	const metricsConfig = metricsQueryConfig({
		scopeId: state.entities.toc.id,
		groupBy: 'chapter',
		courseId: state.data.course.id,
		userIds: userIds,
		host: `https://${state.data.api.host}`,
		jwt: state.data.api.jwt
	});
	const bodyAndUrl = pick(metricsConfig, 'body', 'url');
	metricsConfig.queryKey = JSON.stringify({ body: bodyAndUrl.body, url: bodyAndUrl.url });
	return {
		metricsConfig,
		loaded: state.queries[metricsConfig.queryKey]?.isFinished ?? true,
		data: state.entities.metrics[`${props.user.id}:${props.chapter.id}`]
	};
};

const mapDispatchToProps = (dispatch) => ({
	uiActions: bindActionCreators(uiActions, dispatch)
});

const mapPropsToConfig = (props) => {
	return props.metricsConfig;
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	connectRequest(mapPropsToConfig)
)(ChapterCompletionCell);
