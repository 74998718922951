import { requestAsync, mergeData } from './';

export default ({ courseId, elementId, force }) =>
	requestAsync({
		path: '/api/courses/v1/answers',
		params: { course_id: courseId, element_id: elementId },
		transform: (payload) => {
			const entities = {
				elementAnswerIds: {},
				postedAnswerIds: {},
				answers: {},
				answerPostings: {}
			};
			let elementsActivity, elementActivity, answerIds;

			Object.keys(payload).forEach((userId) => {
				elementsActivity = payload[userId];

				// HERE IS THE PLACE OF EXCEPTION, elementActivity is undefined
				Object.keys(elementsActivity.elements).forEach((elementId) => {
					entities.postedAnswerIds[elementId] || (entities.postedAnswerIds[elementId] = []);
					elementActivity = elementsActivity.elements[elementId];
					answerIds = [];
					elementActivity.answers.forEach((answer) => {
						if (!answer.id) {
							// See index.tsx:26
							answer.id = window.getSyntheticId();
						}
						answerIds.push(answer.id);
						entities.answers[answer.id] = { ...answer, user_id: userId };
						entities.answerPostings[answer.id] = answer.posted_at || answer.submitted_at;

						if (answer.posted_at) {
							entities.postedAnswerIds[elementId].push(answer.id);
						}
					});
					entities.elementAnswerIds[userId + ':' + elementId] = answerIds;
				});
			});

			return entities;
		},
		update: {
			elementAnswerIds: mergeData,
			postedAnswerIds: mergeData,
			answers: mergeData,
			answerPostings: mergeData
		},
		force
	});
