const compareUsersIds = (u1_id, u2_id) => {
	if (u1_id < u2_id) return -1;
	if (u2_id < u1_id) return 1;
	return 0;
};

export const sortUserByLastName = (userIds, usersById) =>
	[...userIds].sort((id1, id2) => {
		const u1 = usersById[id1];
		const u2 = usersById[id2];

		const u1_last = u1.last_name.toUpperCase();
		const u2_last = u2.last_name.toUpperCase();
		if (u1_last < u2_last) return -1;
		if (u2_last < u1_last) return 1;

		const u1_first = u1.first_name.toUpperCase();
		const u2_first = u2.first_name.toUpperCase();

		if (u1_first < u2_first) return -1;
		if (u2_first < u1_first) return 1;

		return compareUsersIds(id1, id2);
	});

export const sortUserByTotalTimeSpent = (userIds, usersById) =>
	[...userIds].sort((id1, id2) => {
		const u1 = usersById[id1];
		const u2 = usersById[id2];

		const u1_time_spent = u1.total_time_spent;
		const u2_time_spent = u2.total_time_spent;

		if (u1_time_spent < u2_time_spent) return -1;
		if (u2_time_spent < u1_time_spent) return 1;

		return compareUsersIds(id1, id2);
	});

export const sortUserByCourseCompletion = (userIds, usersById) =>
	[...userIds].sort((id1, id2) => {
		const u1 = usersById[id1];
		const u2 = usersById[id2];

		const u1_completion = u1.course_completion;
		const u2_completion = u2.course_completion;

		if (u1_completion < u2_completion) return -1;
		if (u2_completion < u1_completion) return 1;

		return compareUsersIds(id1, id2);
	});

export const sortUserByCourseScore = (userIds, usersById) =>
	[...userIds].sort((id1, id2) => {
		const u1 = usersById[id1];
		const u2 = usersById[id2];

		const u1_score = u1.course_score;
		const u2_score = u2.course_score;

		if (u1_score < u2_score) return -1;
		if (u2_score < u1_score) return 1;

		return compareUsersIds(id1, id2);
	});

const sortUserIdsCompletions = (userIds, usersById, sortBy) => {
	const [sortField, sortDirection] = sortBy.split(' ');

	let sorted = [];
	switch (sortField) {
		case 'user.last_name': {
			sorted = sortUserByLastName(userIds, usersById);
			break;
		}
		case 'user.total_time_spent': {
			sorted = sortUserByTotalTimeSpent(userIds, usersById);
			break;
		}
		case 'user.course_completion': {
			sorted = sortUserByCourseCompletion(userIds, usersById);
			break;
		}
		case 'user.course_score': {
			sorted = sortUserByCourseScore(userIds, usersById);
			break;
		}
		default: {
			throw new Error(`Invalid sort field: ${sortField}`);
		}
	}

	return sortDirection === 'DESC' ? sorted.reverse() : sorted;
};

export default sortUserIdsCompletions;
