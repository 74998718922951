import styles from './QuestionBulkGraderView.scss';

import React from 'react';
import { array, func, number, object, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import pageActivityQuery from 'Requests/PageActivityQuery';
import metricsQuery from 'Requests/MetricsQuery';
import elementActivityQuery from 'Requests/ElementActivityQuery';
import elementsActivityQuery from 'Requests/ElementsActivityQuery';
import unpostAnswerMutation from 'Requests/UnpostAnswerMutation';
import saveWebtextCommentMutation from 'Requests/SaveWebtextCommentMutation';
import interactiveTemplateQuery from 'Requests/InteractiveTemplateQuery';
import { formatTimeShort } from 'Utils/format';
import cn from 'classnames';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import RaisedButton from 'Components/RaisedButton';
import ConfirmDialog from 'Components/ConfirmDialog';
import * as uiActions from 'Actions/uiActions';
import { withRouter } from 'react-router-dom';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table } from 'react-virtualized';
import ResizingTextarea from 'react-textarea-autosize';

import WebtextBuilderReview from 'Components/WebtextBuilderReview';
import filterUserIds from 'Filters/FilterUserIdsBySearchQuery';
import sortUserIdsCompletions from 'Sorts/SortUserIdsCompletions';
import sortUserIdsAnswers from 'Sorts/SortUserIdsAnswers';
import AnswerPinnedCell from './AnswerPinnedCell';
import TippyRollover from '../TippyRollover';

// row selected icons
const svgStyle = {
	height: '16px',
	width: '16px',
	border: '1px solid #999'
};
const checkedIcon = <svg style={{ ...svgStyle, backgroundColor: '#5aa0ff' }} />;
const uncheckedIcon = <svg style={{ ...svgStyle, backgroundColor: 'white' }} />;

class QuestionBulkGrader extends React.Component {
	constructor(props) {
		super(props);

		// NOTE: Assuming this won't change!
		this.timeZone = props.course.time_zone;

		this.cache = new CellMeasurerCache({
			fixedWidth: true,
			minHeight: 24
		});

		this.sortOptions = [
			{ label: 'Most Recent', primaryText: 'Most Recent', value: 'answer.saved_at DESC' },
			{ label: 'Least Recent', primaryText: 'Least Recent', value: 'answer.saved_at ASC' },
			{ label: 'A-Z', primaryText: 'Alphabetical (A-Z)', value: 'user.last_name ASC' },
			{ label: 'Z-A', primaryText: 'Alphabetical (Z-A)', value: 'user.last_name DESC' },
			{ label: 'Longest Response', primaryText: 'Longest Response', value: 'answer.length DESC' },
			{ label: 'Shortest Response', primaryText: 'Shortest Response', value: 'answer.length ASC' },
			{ label: 'Posted', primaryText: 'Posted', value: 'answer.posted_at DESC' }
		];

		this.sortOptionsByValue = this.sortOptions.reduce(function (memo, sortOption) {
			memo[sortOption.value] = sortOption;
			return memo;
		}, {});

		this.state = {
			selected: {},
			sortBy: this.sortOptions[0],
			buttonsVisible: true,
			commentBoxVisible: false,
			commentBoxText: '',
			confirmUnpostDialogVisible: false
		};

		this.selectedUsers = this.selectedUsers.bind(this);
		this.rowHeight = this.rowHeight.bind(this);
		this.rowSelected = this.rowSelected.bind(this);
		this.updateSortBy = this.updateSortBy.bind(this);
		this.toggleCheckboxes = this.toggleCheckboxes.bind(this);
		this.selectedHeaderCellRenderer = this.selectedHeaderCellRenderer.bind(this);
		this.selectedCellRenderer = this.selectedCellRenderer.bind(this);
		this.responsesCellRenderer = this.responsesCellRenderer.bind(this);
		this.responsesHeaderCellRenderer = this.responsesHeaderCellRenderer.bind(this);
		this.rowClassName = this.rowClassName.bind(this);
		this.sortedUserIds = this.sortedUserIds.bind(this);

		this.showInContext = this.showInContext.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.answers !== nextProps.answers) {
			this.cache.clearAll();
		}
	}

	componentDidUpdate() {
		this.fetchData();
	}

	fetchData = () => {
		const { dispatch, course, userIds, question } = this.props;
		dispatch(elementActivityQuery({ courseId: course.id, elementId: question.id }));

		if (question.type === 'interactive_template') {
			userIds.forEach((userId) =>
				dispatch(
					interactiveTemplateQuery({
						courseId: course.id,
						userId,
						interactiveTemplateId: question.id
					})
				)
			);
		}
	};

	backToPageView = () => {
		this.props.uiActions.updateCurrentViewParams({
			scope: this.props.question.page_id,
			student: this.props.pageViewStudent,
			element: null
		});
	};

	showInContext() {
		const { question } = this.props;
		this.props.uiActions.updateCurrentViewParams({
			scope: question.page_id,
			element: null,
			showInContext: true,
			scrollTo: question.id
		});
	}

	cellDataGetter = ({ dataKey, rowData }) => {
		if (dataKey === 'responses') {
			const { course, user, question, response, lastSavedAt, comment, status } = rowData;
			return { course, user, question, response, lastSavedAt, comment, status };
		} else if (dataKey === 'selected') {
			const { user } = rowData;
			return { selected: this.state.selected[user.id], userId: user.id };
		} else if (dataKey === 'pinned') {
			const { course, answer } = rowData;
			return { course, answer };
		} else {
			return rowData[dataKey];
		}
	};

	rowClassName({ index }) {
		if (index === -1) {
			return styles.TableHeaderRow;
		} else {
			return cn(styles.TableRow, { odd: index % 2, [styles.TableRowEven]: index % 2 === 0 });
		}
	}

	updateSortBy(ev) {
		this.setState({ sortBy: this.sortOptionsByValue[ev.target.value] });
		this.cache.clearAll();
	}

	rowSelected(userId) {
		return function () {
			this.setState({
				...this.state,
				selected: {
					...this.state.selected,
					[userId]: !this.state.selected[userId]
				}
			});
		}.bind(this);
	}

	toggleCheckboxes() {
		if (this.selectedUsers().length) {
			this.setState({ selected: {} });
		} else {
			const selected = {};
			this.props.userIds.forEach(function (userId) {
				selected[userId] = true;
			});
			this.setState({ selected });
		}
	}

	selectedHeaderCellRenderer() {
		return (
			<Checkbox
				checked={!!this.selectedUsers().length}
				onChange={this.toggleCheckboxes}
				checkedIcon={checkedIcon}
				icon={uncheckedIcon}
			/>
		);
	}

	selectedCellRenderer({ cellData }) {
		return (
			<Checkbox
				checked={!!cellData.selected}
				onChange={this.rowSelected(cellData.userId)}
				checkedIcon={checkedIcon}
				icon={uncheckedIcon}
			/>
		);
	}

	pinnedRolloverRendered({ component }) {
		return (
			<TippyRollover
				content="Pin good responses to the top of the list of responses viewed by students"
				className={styles.AnswersPinRollover}>
				{component}
			</TippyRollover>
		);
	}

	pinnedHeaderCellRenderer = () => {
		if (!this.pinnedHeaderCell) {
			this.pinnedHeaderCell = this.pinnedRolloverRendered({ component: <div>Pin</div> });
		}
		return this.pinnedHeaderCell;
	};

	pinnedCellRenderer = ({ cellData }) => {
		const { answer } = cellData;
		return answer
			? this.pinnedRolloverRendered({ component: <AnswerPinnedCell answer={answer} /> })
			: null;
	};

	responsesHeaderCellRenderer() {
		const sortOptions = this.sortOptions;
		const sortBy = this.state.sortBy;

		const menuItems = sortOptions.map(function (sortOption, idx) {
			return (
				<MenuItem
					key={idx}
					value={sortOption.value}
					label={sortOption.label}
					classes={{ selected: styles.SortByMenuItemSelected }}>
					{sortOption.primaryText}
				</MenuItem>
			);
		});

		return (
			<div styleName="UserResponseRow">
				<div styleName="TableSortBy">
					<strong>Sort By: </strong>
					<Select
						value={sortBy.value}
						onChange={this.updateSortBy}
						variant="standard"
						disableUnderline={true}
						classes={{
							root: styles.SortBySelectRoot,
							select: styles.SortBySelectMenu,
							icon: styles.SortByIcon
						}}>
						{menuItems}
					</Select>
				</div>
				<div styleName="TableResponsesHeading">Responses</div>
			</div>
		);
	}

	responsesCellRenderer({ cellData, rowIndex, parent }) {
		if (!cellData) return '&nbsp;';

		const { course, user, question, response, lastSavedAt, comment, status } = cellData;

		let table = (
			<div styleName="UserResponseTable">
				<div styleName="UserResponseRow">
					<div styleName="UserResponseCell">
						<div styleName="UserName">
							{user.first_name} {user.last_name}
						</div>
						{lastSavedAt && (
							<div styleName="Timestamp">
								{formatTimeShort({
									time: lastSavedAt,
									timeZone: this.timeZone,
									withoutTimeZone: true
								})}
							</div>
						)}
					</div>
					<div styleName="UserResponseCell">
						{status === 'Posted' && response}
						{status === 'Drafted' && (
							<em>
								{`Students must click "${
									question.type === 'response_board' ? 'Post' : 'Submit'
								}" in order to receive points and for the text to display here.`}
							</em>
						)}
					</div>
				</div>
				{comment && comment.body && (
					<div styleName="UserResponseRow UserResponseCommentRow">
						<div styleName="UserResponseCell">
							<div styleName="InstructorName">
								{comment.author_name || course.primary_instructor_name}
							</div>
							<div styleName="Timestamp">
								{formatTimeShort({
									time: comment.last_saved_at,
									timeZone: this.timeZone,
									withoutTimeZone: true
								})}
							</div>
						</div>
						<div styleName="UserResponseCell">{comment.body}</div>
					</div>
				)}
			</div>
		);

		return (
			<CellMeasurer cache={this.cache} columnIndex={0} parent={parent} rowIndex={rowIndex}>
				{table}
			</CellMeasurer>
		);
	}

	statusHeaderCellRenderer() {
		return <div>Status</div>;
	}

	statusCellRenderer({ cellData }) {
		return cellData ? String(cellData) : '';
	}

	rowHeight({ index }) {
		const height = this.cache.rowHeight({ index });
		return height;
	}

	sortedUserIds() {
		const { userIds, usersById, searchQuery } = this.props;
		const {
			sortBy: { value: sortBy }
		} = this.state;
		const [sortField] = sortBy.split(' ');

		// TODO NOTE Revise
		// searchQuery will never be defined here if we just use an app, because it is cleared when we leave the GRID
		// We can land here with it only from the URL
		const filteredUsersIds = filterUserIds(userIds, usersById, searchQuery);

		if (sortField === 'user.last_name') {
			return sortUserIdsCompletions(filteredUsersIds, usersById, sortBy);
		}
		const { answers, answerPostings } = this.props;
		return sortUserIdsAnswers(filteredUsersIds, answers, answerPostings, sortBy);
	}

	selectedUsers() {
		const users = [];
		Object.keys(this.state.selected)
			.filter((userId) => !!this.state.selected[userId])
			.forEach((userId) => users.push(this.props.usersById[userId]));
		return users;
	}

	confirmUnpostResponses = () => {
		this.setState({ confirmUnpostDialogVisible: true });
	};

	render() {
		const {
			api,
			toc,
			course,
			chapter,
			page,
			question,
			cyoKey,
			usersById,
			answers,
			interactiveTemplatesData,
			answerPostings,
			unpostAnswer,
			comments,
			currentUser
		} = this.props;
		const questionBody = cyoKey ? question.dictionary.body[cyoKey] : question.body;
		const sortedUserIds = this.sortedUserIds();

		const composeComment = () => {
			this.setState({ buttonsVisible: false, commentBoxVisible: true });
		};

		const updateCommentBoxText = (ev) => {
			this.setState({ commentBoxText: ev.target.value });
		};

		const postComment = () => {
			const { currentUser, saveWebtextComment } = this.props;
			const { commentBoxText: commentBody } = this.state;
			const users = this.selectedUsers();
			users.forEach(function (user) {
				saveWebtextComment({
					course,
					page,
					recipient: user,
					thing: question,
					commentBody,
					commentAuthorName: currentUser.name
				});
			});
			this.setState({ buttonsVisible: true, commentBoxVisible: false, commentBoxText: '' });
		};

		const cancelCommentCompose = () => {
			this.setState({ buttonsVisible: true, commentBoxVisible: false });
		};

		const unpostResponses = () => {
			const users = this.selectedUsers();
			let answer, answerPosting;
			users.forEach(function (user) {
				answer = answers[user.id];
				answerPosting = answerPostings[user.id];
				if (answer && answerPosting)
					unpostAnswer({ course, chapter, page, answer, user: currentUser });
			});
		};

		const rowGetter = ({ index }) => {
			const userId = sortedUserIds[index];
			const user = usersById[userId];
			const answer = answers[userId];
			const interactiveTemplateData = interactiveTemplatesData[userId];
			const answerPosting = answerPostings[userId];
			const comment = comments[userId];
			const status = answer ? (answerPosting ? 'Posted' : 'Drafted') : 'Not Started';

			let response;
			if (question.type === 'interactive_template') {
				response = (
					<WebtextBuilderReview
						api={api}
						course={course}
						page={page}
						toc={toc}
						interactiveTemplate={question}
						interactiveTemplateData={interactiveTemplateData}
						user={user}
					/>
				);
			} else {
				response = answer && answer.body;
			}

			return {
				selected: this.state.selected[userId],
				course,
				user,
				question,
				answer,
				response,
				comment,
				status,
				lastSavedAt: answer && answer.saved_at
			};
		};

		const onDialogClose = () => {
			this.setState({ confirmUnpostDialogVisible: false });
		};

		const dialog = (
			<ConfirmDialog
				open={this.state.confirmUnpostDialogVisible}
				onConfirm={unpostResponses}
				onClose={onDialogClose}>
				<p>
					{`Unposting a response will allow the student to edit it and post again. Students are notified of all unposted responses via email. (Unposting does NOT delete a student's saved answer.)`}
				</p>
				<p>Would you like to unpost responses for these students?</p>
			</ConfirmDialog>
		);

		return (
			<div styleName="Container">
				{dialog}
				<h2
					styleName="QuestionBody"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: questionBody }}
				/>
				{this.state.commentBoxVisible && (
					<div styleName="CommentComposer">
						<div>
							<label>To:</label>
							<input
								type="text"
								value={this.selectedUsers()
									.map((u) => `${u.first_name} ${u.last_name}`)
									.join(', ')}
								disabled={true}
							/>
						</div>
						<div>
							<label>Message:</label>
							<ResizingTextarea value={this.state.commentBoxText} onChange={updateCommentBoxText} />
						</div>
						<div styleName="CommentComposerControls">
							<RaisedButton label="Post Comment" onClick={postComment} />
							<a href="#" onClick={cancelCommentCompose} styleName="CommentComposerCancelLink">
								Cancel
							</a>
						</div>
					</div>
				)}
				<Paper elevation={1} square={true} styleName="Paper">
					{this.state.buttonsVisible && (
						<div styleName="Buttons">
							<RaisedButton label="Back to Page" onClick={this.backToPageView} />
							<RaisedButton
								label="Compose Comment"
								disabled={!Object.values(this.state.selected).filter((v) => v).length}
								onClick={composeComment}
							/>
							<RaisedButton
								label="Unpost"
								disabled={!Object.values(this.state.selected).filter((v) => v).length}
								onClick={this.confirmUnpostResponses}
							/>
							{false && (
								<RaisedButton
									label="Show in Context"
									styleName="ShowInContext"
									onClick={this.showInContext}
								/>
							)}
						</div>
					)}
					<div styleName="AutoSizer">
						<AutoSizer>
							{({ width, height }) => (
								<Table
									deferredMeasurementCache={this.cache}
									headerHeight={40}
									width={width}
									height={height}
									rowGetter={rowGetter}
									rowCount={sortedUserIds.length}
									rowHeight={this.rowHeight}
									rowClassName={this.rowClassName}
									styleName="Table">
									<Column
										dataKey="selected"
										width={60}
										cellDataGetter={this.cellDataGetter}
										cellRenderer={this.selectedCellRenderer}
										headerRenderer={this.selectedHeaderCellRenderer}
										headerClassName={styles.TableSelectedHeadingCell}
										styleName="TableSelectedCell"
									/>
									{question.type === 'response_board' && (
										<Column
											dataKey="pinned"
											label="Pin"
											width={60}
											cellDataGetter={this.cellDataGetter}
											cellRenderer={this.pinnedCellRenderer}
											headerRenderer={this.pinnedHeaderCellRenderer}
											headerClassName={styles.TablePinnedHeadingCell}
											styleName="TablePinnedCell"
										/>
									)}
									<Column
										dataKey="responses"
										label="Responses"
										width={210}
										flexGrow={1}
										cellDataGetter={this.cellDataGetter}
										cellRenderer={this.responsesCellRenderer}
										headerRenderer={this.responsesHeaderCellRenderer}
										headerClassName={styles.TableResponsesHeadingCell}
										styleName="TableResponsesCell"
									/>
									<Column
										dataKey="status"
										label="Status"
										width={120}
										cellRenderer={this.statusCellRenderer}
										headerRenderer={this.statusHeaderCellRenderer}
										headerClassName={styles.TableStatusHeadingCell}
										styleName="TableStatusCell"
									/>
								</Table>
							)}
						</AutoSizer>
					</div>
				</Paper>
			</div>
		);
	}
}

QuestionBulkGrader.propTypes = {
	api: object.isRequired,
	toc: object.isRequired,
	currentUser: object.isRequired,
	webtexts: object,
	course: object.isRequired,
	userIds: array,
	usersById: object,
	searchQuery: string,
	chapter: object.isRequired,
	page: object.isRequired,
	question: object.isRequired,
	cyoKey: string,
	answers: object.isRequired,
	interactiveTemplatesData: object.isRequired,
	answerPostings: object.isRequired,
	comments: object.isRequired,
	uiActions: object.isRequired,
	unpostAnswer: func.isRequired,
	saveWebtextComment: func.isRequired,
	pageViewStudent: number.isRequired,
	hoverVisible: func,
	hoverContent: func,
	dispatch: func
};

const mapStateToProps = (state) => {
	const { scope: pageId, element: elementParam, pageViewStudent } = state.ui.currentViewParams;
	const { webtextBuilders } = state.entities;

	const [elementId, cyoKey] = elementParam.split(':', 2);

	const currentUser = state.entities.currentUser;
	const webtexts = state.data.webtexts;
	const course = state.entities.course;
	const usersById = state.entities.users;
	const searchQuery = state.ui.currentViewParams.searchQuery;
	const page = state.entities.pages[pageId];
	const chapter = state.entities.chapters[page.chapter_id];
	const question = state.entities.elements[elementId];

	const answers = {};
	const interactiveTemplatesData = {};
	const answerPostings = {};
	const comments = {};
	const emptyArray = [];
	let answerId;

	const userIds = [];
	state.entities.studentIds.forEach(function (userId) {
		if (cyoKey) {
			const user = usersById[userId];
			if (user.decisions[question.depends_on[0]] !== cyoKey) {
				return null;
			}
		}

		userIds.push(userId);

		answerId = (state.entities.elementAnswerIds[userId + ':' + elementId] || emptyArray)[0];
		if (answerId) {
			answers[userId] = state.entities.answers[answerId];
			interactiveTemplatesData[userId] = state.entities.webtextBuilders[userId + ':' + elementId];
			answerPostings[userId] = state.entities.answerPostings[answerId];
		}

		comments[userId] = state.entities.webtextComments[`${userId}:${question.id}`];
	});

	return {
		api: state.data.api,
		toc: state.entities.toc,
		currentUser,
		webtexts,
		course,
		userIds,
		usersById,
		searchQuery,
		chapter,
		page,
		question,
		cyoKey,
		answers,
		interactiveTemplatesData,
		answerPostings,
		comments,
		pageViewStudent,
		webtextBuilders
	};
};

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uiActions: bindActionCreators(uiActions, dispatch),
	unpostAnswer: ({ course, chapter, page, answer, user }) =>
		dispatch(
			unpostAnswerMutation({ courseId: course.id, answerId: answer.id, userId: user.id })
		).then(() => {
			dispatch(
				pageActivityQuery({
					courseId: course.id,
					userId: answer.user_id,
					pageId: page.id,
					force: true
				})
			);
			dispatch(
				metricsQuery({
					courseId: course.id,
					userIds: [answer.user_id],
					scopeId: chapter.id,
					groupby: 'page',
					force: true
				})
			);
		}),
	saveWebtextComment: ({ course, page, recipient, thing, commentBody, commentAuthorName }) =>
		dispatch(
			saveWebtextCommentMutation({
				courseId: course.id,
				recipientId: recipient.id,
				thingFamilyId: thing.id,
				rawCommentBody: commentBody,
				commentAuthorName
			})
		).then(() => {
			// Updates answer history, which includes comment updates
			dispatch(
				elementsActivityQuery({
					courseId: course.id,
					userId: recipient.id,
					pageId: page.id,
					elementIds: [thing.id],
					force: true
				})
			);
		})
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionBulkGrader));
