import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { formatScore } from 'Utils/format';
import scoreCellStyles from './ScoreCell.scss';
import LoadingShimmer from '../../containers/Views/AggregatedElements/LoadingShimmer';

class ScoreCell extends React.PureComponent {
	static propTypes = {
		score: PropTypes.number,
		lowScore: PropTypes.bool,
		onClick: PropTypes.func,
		loaded: PropTypes.bool
	};

	render() {
		const { score, lowScore, onClick, loaded } = this.props;
		if (!loaded) return <LoadingShimmer />;
		const styleNames = cx(scoreCellStyles.Contents, lowScore && scoreCellStyles.LowScore);

		return (
			<div className={scoreCellStyles.Wrapper} onClick={onClick}>
				{score != null && <div className={styleNames}>{formatScore({ score })}</div>}
			</div>
		);
	}
}

export default ScoreCell;
