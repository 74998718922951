import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { object, string, func, bool } from 'prop-types';
import cn from 'classnames';
import { DateTime, Interval } from 'luxon';

import AnswerViewVoiceRecording from '@soomo/lib/components/pageElements/VoiceRecording/notebook/AnswerViewVoiceRecording';
import { CallStatus, VoiceRecording } from '@soomo/lib/components/pageElements/VoiceRecording';
import ThemeProvider from '@soomo/lib/styles/themes/ThemeProvider';
import blue_velvet from '@soomo/lib/styles/themes/blue_velvet';

import * as uiActions from 'Actions/uiActions';
import ActivityHeader from 'Components/ActivityHeader';
import { formatTime } from 'Utils/format';

import AnswerActions from './AnswerActions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import pageViewStyles from '../PageView.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './VoiceRecording.scss';

class VoiceRecordingContainer extends Component {
	constructor() {
		super();
		this.state = { transcript: null };
	}

	renderInContext = () => {
		let { voiceRecording, answer } = this.props;
		voiceRecording = {
			...voiceRecording,
			...{ pin: 'XXXXXX', phone: '(XXX) XXX-XXXX', family_id: voiceRecording.id }
		};
		const completed = answer && answer.completed;
		let secondsSinceUpdate = null;
		if (completed) {
			const updatedAt = DateTime.fromISO(answer.saved_at);
			secondsSinceUpdate = Interval.fromDateTimes(updatedAt, DateTime.local()).length('seconds');
		}
		return (
			<VoiceRecording
				voiceRecording={voiceRecording}
				lmsSupportsFileUpload={false}
				recordingUrl={completed && answer.data?.recordingUrl}
				secondsSinceUpdate={secondsSinceUpdate}
				callStatus={completed ? CallStatus.Completed : CallStatus.NotIdentified}
			/>
		);
	};

	render() {
		const { postedAt, voiceRecording, commentKey, comment, timeZone, user, answer, inContext } =
			this.props;

		if (inContext) {
			return this.renderInContext();
		} else {
			return (
				<div styleName="styles.VoiceRecording">
					{answer && postedAt && (
						<div styleName={cn('pageViewStyles.Symbol', 'pageViewStyles.IsCompleteSymbol')} />
					)}
					<div styleName="styles.ActivitySummary">
						<span styleName="pageViewStyles.QuestionType">Voice Recording</span>

						{answer && answer.data && postedAt && (
							<>
								<span>
									<span>&nbsp;&mdash;&nbsp;</span>
									<span>LAST SAVED {formatTime({ time: answer.savedAt, timeZone })}</span>
								</span>
								<span>
									&nbsp;&mdash;&nbsp;LAST AUTO-SUBMISSION{' '}
									{formatTime({ time: answer.lastAutosubmitAt, timeZone })}
								</span>
							</>
						)}
					</div>

					<h2
						styleName="styles.QuestionBody"
						dangerouslySetInnerHTML={{ __html: voiceRecording.column_name }}
					/>

					{!answer && <ActivityHeader>NOT STARTED</ActivityHeader>}

					{answer && answer.savedAt && !answer.completed && (
						<ActivityHeader timestamp={answer?.savedAt} timeZone={timeZone}>
							LAST SAVE <span style={{ fontStyle: 'italic' }}>(Not yet submitted)</span>
						</ActivityHeader>
					)}

					{answer && postedAt && (
						<div styleName="styles.QuestionBody">
							<ThemeProvider theme={blue_velvet}>
								<AnswerViewVoiceRecording
									recordingUrl={answer.data?.recordingUrl}
									transcript={answer.data?.transcript}
									containerClass="notebook-element"
								/>
							</ThemeProvider>
						</div>
					)}

					<AnswerActions
						answer={answer}
						commentKey={commentKey}
						comment={comment}
						posting={postedAt}
						question={voiceRecording}
						user={user}
						onUnpostAnswer={this.onUnpostAnswer}
						onViewAllResponses={this.onViewAllResponses}
						commentWithoutAnswer={true}
						commentRequiresPosting={false}
					/>
				</div>
			);
		}
	}
}

VoiceRecordingContainer.propTypes = {
	voiceRecording: object.isRequired,
	user: object.isRequired,
	activity: object,
	comment: object,
	answer: object,
	timeZone: string.isRequired,
	inContext: bool.isRequired,
	dispatch: func,
	postedAt: string
};

const mapStateToProps = (state, props) => {
	const { student: userId } = state.ui.currentViewParams;
	const commentKey = `${userId}:${props.voiceRecording.id}`;
	const comment = state.entities.webtextComments[commentKey];
	const answer = props.activity && props.activity.answers && props.activity.answers[0];
	const postedAt = answer ? state.entities.answerPostings[answer.id] : null;
	return {
		answer,
		commentKey,
		comment,
		postedAt,
		timeZone: state.entities.course.time_zone
	};
};

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uiActions: bindActionCreators(uiActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(VoiceRecordingContainer);
