import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import pinAnswerMutation from '../../../requests/PinAnswerMutation';
import unpinAnswerMutation from '../../../requests/UnpinAnswerMutation';
import { useAppDispatch, useAppSelector } from '../../../store';

const svgStyle = { height: '19px', width: '19px' };

const checkedIcon = (
	<svg style={{ ...svgStyle, backgroundColor: 'transparent' }}>
		<path
			d="M11.104,0 L10.505,0.599 L10.984,2.519 L5.467,7.237 L1.749,7.357 L0.99,8.116 L4.747,11.874 L0,18.621 L6.747,13.874 L10.505,17.631 L11.264,16.871 L11.384,13.154 L16.102,7.637 L18.021,8.116 L18.621,7.517 L11.104,0 Z"
			fill="#5aa0ff"
		/>
	</svg>
);

const uncheckedIcon = (
	<svg style={{ ...svgStyle, backgroundColor: 'transparent' }}>
		<path
			d="M11.104,0 L10.505,0.599 L10.984,2.519 L5.467,7.237 L1.749,7.357 L0.99,8.116 L4.747,11.874 L0,18.621 L6.747,13.874 L10.505,17.631 L11.264,16.871 L11.384,13.154 L16.102,7.637 L18.021,8.116 L18.621,7.517 L11.104,0 Z"
			fill="#fff"
			stroke="#999"
			strokeWidth="0.75"
		/>
	</svg>
);

const AnswerPinnedCell = ({ answer }) => {
	const dispatch = useAppDispatch();
	const courseId = useAppSelector((state) => state.data.course.id);
	const currentUser = useAppSelector((state) => state.entities.currentUser);

	const togglePin = () => {
		if (answer.pinned_at) {
			dispatch(unpinAnswerMutation({ courseId, answerId: answer.id }));
		} else {
			dispatch(pinAnswerMutation({ courseId, answerId: answer.id, currentUser }));
		}
	};

	return (
		<Checkbox
			checked={!!answer.pinned_at}
			onChange={togglePin}
			checkedIcon={checkedIcon}
			icon={uncheckedIcon}
		/>
	);
};

AnswerPinnedCell.propTypes = {
	answer: PropTypes.object.isRequired
};

export default AnswerPinnedCell;
