/* eslint-disable react/display-name */

import { StatusCode } from 'status-code-enum';
import * as types from './actionTypes';
import { setHighlightTiming, updateCurrentViewParams } from './uiActions';
import { setTocChapterIds, setTocFilteringEnabled } from './tocActions';
import { setStudentsFilteringEnabled } from './studentsActions';
import { handleWebtextInteractionUpdate } from './interactionUpdateActions';

import getCurrentUserInfo from '../requests/CurrentUserInfoQuery';
import getCourseInfo from '../requests/CourseInfoQuery';
import getWebtextManifest from '../requests/WebtextManifestQuery';
import getEnrollments from '../requests/EnrollmentsQuery';
import getCoursePageCompletionSummaryData from '../requests/CoursePageCompletionSummaryQuery';
import getCoursePageTimingsSummaryData from '../requests/CoursePageTimingsSummaryQuery';
import getCourseStudentGradesData from '../requests/CourseStudentGradesQuery';
import getCourseWebtextComments from '../requests/CourseWebtextCommentsQuery';

export const unrecoverableError = () => ({
	type: types.SET_DATA_UNRECOVERABLE_ERROR,
	payload: true
});

export const setDataApiHost = (host) => ({
	type: types.SET_DATA_API_HOST,
	payload: { host }
});

export const setDataWebtextsHost = (host) => ({
	type: types.SET_DATA_WEBTEXTS_HOST,
	payload: { host }
});

export const setJWT = (jwt) => ({
	type: types.SET_JWT,
	payload: { jwt }
});

export const setCourseId = (courseId) => ({
	type: types.SET_COURSE_ID,
	payload: { courseId }
});

export const setCourseImageUrl = (courseImageUrl) => ({
	type: types.SET_COURSE_IMAGE_URL,
	payload: { courseImageUrl }
});

export const requiredEntitiesAvailable = () => ({
	type: types.REQUIRED_ENTITIES_AVAILABLE
});

export const handlePushNotification = (message) => (dispatch, getState) => {
	const state = getState();
	switch (message.type) {
		case 'webtext_comment.update': {
			dispatch(getCourseWebtextComments({ course: state.data.course, force: true }));
			break;
		}
		case 'webtext.interaction.update': {
			dispatch(handleWebtextInteractionUpdate(message));
			break;
		}
		default:
			console.log(message);
	}
};

const get = (url) => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.onload = () => {
			if (xhr.readyState === 4) {
				if (xhr.status === StatusCode.SuccessOK) {
					resolve(xhr);
				} else {
					reject(xhr);
				}
			}
		};
		xhr.send(null);
	});
};

export const loadInitialData = () => (dispatch, getState) => {
	const state = getState();
	const { course } = state.data;

	const errorHandler = (err) => {
		console.log(err.message);
	}; // just log for now.

	dispatch(getCourseInfo({ courseId: course.id }))
		.then((response) => {
			if (['qa'].indexOf(response.body.course.primary_role) > -1) {
				dispatch(setHighlightTiming(true));
			}
			dispatch(updateCurrentViewParams({}));

			if (response.body.course.cohort_size > 1) {
				// dispatch(getCohortMetrics({ course })).catch(errorHandler)
				// dispatch(getCohortPageCompletionSummaryData({ course })).catch(errorHandler)
				// dispatch(getCohortPageTimingsSummaryData({ course })).catch(errorHandler)
			}
		})
		.catch(errorHandler);

	dispatch(getWebtextManifest({ course }))
		.then((response) => {
			dispatch(updateCurrentViewParams({}));
			dispatch(setTocChapterIds({ chapterIds: response.body.toc.chapter_ids }));
			if (response.body.toc.question_counts.wt > 0) {
				dispatch(setTocFilteringEnabled(true));
				dispatch(setStudentsFilteringEnabled(true));
			}
		})
		.catch(errorHandler);

	dispatch(getEnrollments({ courseId: course.id }))
		.then(() => {
			dispatch(updateCurrentViewParams({}));
		})
		.catch(errorHandler);

	dispatch(getCurrentUserInfo())
		.then((response) => {
			if (response.body.user.roles.indexOf('soomo_admin') > -1) {
				dispatch(setHighlightTiming(true));
				// dispatch(setAllSectionsRowEnabled(true))
			}
		})
		.catch(errorHandler);

	dispatch(getCoursePageTimingsSummaryData({ courseId: course.id })).catch(errorHandler);
	dispatch(getCoursePageCompletionSummaryData({ courseId: course.id })).catch(errorHandler);
	dispatch(getCourseStudentGradesData({ courseId: course.id })).catch(errorHandler);

	dispatch(getCourseWebtextComments({ courseId: course.id })).catch(errorHandler);

	return null;
};
