export const consoleLogActions = true;
export const useMockData = true;

export const selectorTypes = {
	SCOPE: 'scope',
	STUDENT: 'student',
	ELEMENT: 'element',
	QUICK_ACTION_LINKS: 'quickActionLinks'
};

export const viewTemplateTypes = {
	GRID: 'grid',
	PAGE: 'page',
	AGGREGATED_PAGE: 'aggregated_page',
	ELEMENT: 'element',
	MESSAGE: 'message'
};

export const arrowDirections = {
	FORWARD: 'forward',
	BACK: 'back'
};

export const selectorToggleColor = 'rgba(255, 255, 255, 0.87)';

export const questionTypes = [
	// Placeholder
	{ id: 'multipleChoice', label: 'Multiple Choice' },
	{ id: 'shortAnswer', label: 'Short Answer' },
	{ id: 'responseBoard', label: 'Response Board' }
];

export const errors = {
	NO_SELECTION_ERROR: 'Oops, select something before submitting!'
};

export const interactiveElementsTypes = [
	'mc_question',
	'poll_question',
	'sa_question',
	'response_board',
	'interactive_template',
	'external_tool',
	'self_assessment',
	'mc_question_pool',
	'voice_recording'
];
