/* eslint-disable react/no-danger */
import styles from './TocMenu.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { viewTemplateTypes } from '../../constants';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

const onListItemClick = (id, onSelect) => {
	return (event) => {
		event.stopPropagation();
		onSelect(event, id);
	};
};

const getPageListItems = ({ pages, onSelect, withPageGroup, visible }) =>
	pages.map(({ id, label }) => (
		<ListItem key={id} data-within-group={withPageGroup} classes={{ root: styles.PageListItem }}>
			<Button
				role="link"
				disabled={!visible}
				onClick={onListItemClick(id, onSelect)}
				classes={{ root: styles.PageLabel }}>
				<span dangerouslySetInnerHTML={{ __html: label }} />
			</Button>
		</ListItem>
	));

const getPageGroupListItems = ({ pageGroups, onSelect, currentViewTemplate, visible }) =>
	pageGroups.map(({ id, label, pages }) => {
		let itemId = id;

		if (currentViewTemplate !== viewTemplateTypes.GRID) {
			if (pages.length > 0) {
				itemId = pages[0].id;
			}
		}

		return [
			<ListItem key={id} classes={{ root: styles.PageGroupListItem }}>
				<Button
					role="link"
					disabled={!visible}
					onClick={onListItemClick(itemId, onSelect)}
					classes={{ root: styles.PageGroupLabel }}>
					<span dangerouslySetInnerHTML={{ __html: label }} />
				</Button>
			</ListItem>,
			pages.length > 0 &&
				getPageListItems({
					pages,
					onSelect,
					withPageGroup: true,
					visible
				})
		];
	});

class ChapterListItem extends Component {
	static propTypes = {
		currentViewTemplate: PropTypes.string,
		chapterOutline: PropTypes.object,
		open: PropTypes.bool,
		onToggle: PropTypes.func,
		onSelect: PropTypes.func
	};

	onToggle = (event) => {
		event.stopPropagation();
		this.props.onToggle();
	};

	render() {
		const { chapterOutline, onSelect, currentViewTemplate } = this.props;

		const chapterItems = chapterOutline.hasPageGroups
			? getPageGroupListItems({
					pageGroups: chapterOutline.pageGroups,
					onSelect,
					currentViewTemplate,
					visible: this.props.open
			  })
			: getPageListItems({
					pages: chapterOutline.pages,
					onSelect,
					visible: this.props.open
			  });

		const collapsibleItemId = `${chapterOutline.id}-collapse`;

		return [
			<ListItem
				key={chapterOutline.id}
				classes={{ root: styles.ChapterListItem }}
				onClick={this.onToggle}>
				<Button aria-expanded={!!this.props.open} classes={{ root: styles.ChapterLabel }}>
					<span dangerouslySetInnerHTML={{ __html: chapterOutline.label }} />
				</Button>
				{chapterItems.length > 0 && !chapterOutline.singleCollapsedPage && (
					<IconButton
						aria-hidden={true}
						tabIndex={-1}
						classes={{ root: styles.ChapterCollapseToggle }}>
						{this.props.open ? <ExpandLess /> : <ExpandMore />}
					</IconButton>
				)}
			</ListItem>,
			chapterItems.length > 0 && !chapterOutline.singleCollapsedPage && (
				<Collapse key={collapsibleItemId} id={collapsibleItemId} in={this.props.open}>
					{chapterItems}
				</Collapse>
			)
		];
	}
}

export default ChapterListItem;
