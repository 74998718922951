import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { queryMiddleware } from 'redux-query';
import superagentInterface from 'redux-query-interface-superagent';
import { createReduxLocationActions } from 'redux-location-state';
import rootReducer, { getEntities, getQueries } from '../reducers';
import {
	setViewParamsFromSelectorFilters,
	setViewTemplateFromViewParamsMiddleware,
	trackViewParamChangesMiddleware
} from './viewMiddlewares';
import { mapLocationToState, paramsSetup } from './reduxLocation';
const developmentMode = process.env.NODE_ENV !== 'production';

export default (initialState, history) => {
	function createNetworkInterface() {
		/**
		 * Custom Redux Query Network Interface extension.
		 * Attaches default `Accept` header (`application/json`).
		 * Attaches `Content-Type` header when a json body is provided.
		 */
		return function (url, method, config) {
			const headers = {
				Accept: 'application/json',
				...(config.headers || {})
			};

			if (!headers['Content-Type'] && (method === 'POST' || method === 'PUT') && config.body) {
				headers['Content-Type'] = 'application/json';
			}

			headers['X-Requested-With'] = 'XMLHttpRequest';

			config.headers = headers;
			return superagentInterface(url, method, config);
		};
	}

	const reduxQueryMiddleware = queryMiddleware(createNetworkInterface(), getQueries, getEntities);

	const { locationMiddleware, reducersWithLocation } = createReduxLocationActions(
		paramsSetup,
		mapLocationToState,
		history,
		rootReducer
	);

	const middlewares = [
		thunkMiddleware,
		reduxQueryMiddleware,
		trackViewParamChangesMiddleware,
		setViewTemplateFromViewParamsMiddleware,
		setViewParamsFromSelectorFilters,
		locationMiddleware
	];

	const composeEnhanced = developmentMode
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({ trace: true, traceLimit: 15 }) || compose
		: compose;

	const store = createStore(
		reducersWithLocation,
		initialState,
		composeEnhanced(applyMiddleware(...middlewares))
	);

	if (developmentMode && module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../reducers', () => {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const nextReducer = require('../reducers').default;
			store.replaceReducer(nextReducer);
		});
	}

	return store;
};
