import { getRequestAsync, requestAsync, mergeData } from './';

const overallClassPageActivityQueryConfig = ({ courseId, pageId, decisionChoice, force }) => ({
	path: '/api/courses/v1/analytics/overall_class_page_activity',
	params: {
		course_id: courseId,
		page_id: pageId,
		...(decisionChoice && { decision_choice: decisionChoice })
	},
	transform: (payload) => {
		return { overallClassPageActivity: { [pageId]: { ...payload } } };
	},
	update: { overallClassPageActivity: mergeData },
	force
});

export const getOverallClassPageActivityQuery = ({ courseId, pageId, decisionChoice, force }) =>
	getRequestAsync(
		overallClassPageActivityQueryConfig({
			courseId,
			pageId,
			decisionChoice,
			force
		})
	);

export default ({ courseId, pageId, decisionChoice, force = false }) =>
	requestAsync(
		overallClassPageActivityQueryConfig({
			courseId,
			pageId,
			decisionChoice,
			force
		})
	);
