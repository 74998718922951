export const sortUserByAnswerSavedAt = (usersIds, answers) =>
	[...usersIds].sort((id1, id2) => {
		const answer1 = answers[id1];
		const answer2 = answers[id2];
		if (answer1 && answer2) {
			if (answer1.saved_at < answer2.saved_at) return -1;
			if (answer2.saved_at < answer1.saved_at) return 1;
			return id1 >= id2 ? 1 : -1;
		}

		if (answer1) return 1;
		if (answer2) return -1;
		return id1 >= id2 ? 1 : -1;
	});

export const sortUserByAnswerLength = (usersIds, answers) =>
	[...usersIds].sort((id1, id2) => {
		const answer1 = answers[id1];
		const answer2 = answers[id2];
		if (answer1 && answer2) {
			if (answer1.body.length < answer2.body.length) return -1;
			if (answer2.body.length < answer1.body.length) return 1;
			return id1 >= id2 ? 1 : -1;
		}

		if (answer1) return 1;
		if (answer2) return -1;
		return id1 >= id2 ? 1 : -1;
	});

export const sortUserByAnswerPostedAt = (usersIds, answers, answerPostings) => {
	let posting1, posting2, answer1, answer2;
	return [...usersIds].sort((id1, id2) => {
		posting1 = answerPostings[id1];
		posting2 = answerPostings[id2];
		if (posting1 && posting2) {
			if (posting1 < posting2) return -1;
			if (posting2 < posting1) return 1;
			return id1 >= id2 ? 1 : -1;
		}

		if (posting1) return 1;
		if (posting2) return -1;

		answer1 = answers[id1];
		answer2 = answers[id2];
		if (answer1 && answer2) {
			if (answer1.saved_at < answer2.saved_at) return -1;
			if (answer2.saved_at < answer1.saved_at) return 1;
			return id1 >= id2 ? 1 : -1;
		}

		if (answer1) return 1;
		if (answer2) return -1;
		return id1 >= id2 ? 1 : -1;
	});
};

const sortUserIdsAnswers = (userIds, answers, answerPostings, sortBy) => {
	const [sortField, sortDirection] = sortBy.split(' ');

	let sorted = [];
	switch (sortField) {
		case 'answer.saved_at': {
			sorted = sortUserByAnswerSavedAt(userIds, answers);
			break;
		}
		case 'answer.length': {
			sorted = sortUserByAnswerLength(userIds, answers);
			break;
		}
		case 'answer.posted_at': {
			sorted = sortUserByAnswerPostedAt(userIds, answers, answerPostings);
			break;
		}
		default: {
			throw new Error(`Invalid sort field: ${sortField}`);
		}
	}

	return sortDirection === 'DESC' ? sorted.reverse() : sorted;
};

export default sortUserIdsAnswers;
