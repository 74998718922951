export default {
	// This seems to just turn italics on in DOCX...
	escapeContent: function (value) {
		value = value.replace(/<em>/gim, '<i>');
		value = value.replace(/<\/em>/gim, '</i>');
		value = value.replace(/<i>/gim, '{{I}}');
		value = value.replace(/<\/i>/gim, '{{/I}}');
		value = this.entityEscapeContent(value);
		value = value.replace(
			/\{\{I\}\}/,
			'</w:t></w:r><w:r><w:rPr><w:i w:val="true"/></w:rPr><w:t xml:space="preserve">'
		);
		value = value.replace(
			/\{\{\/I\}\}/,
			'</w:t></w:r><w:r><w:rPr><w:i w:val="false"/></w:rPr><w:t xml:space="preserve">'
		);
		return value;
	},

	// Not sure what this does, but throwing error when parsing with React.
	entityEscapeContent: function (value) {
		return value; // .replace /[\u00A0-\u9999<>\&\'\"]/gim, (i) -> "&\##{i.charCodeAt(0)};"
	},

	scrubbedHtml: function (text) {
		return String(text)
			.replace(/<em>/gim, '<i>')
			.replace(/<\/em>/gim, '</i>')
			.replace(/<i>/gim, '[i]')
			.replace(/<\/i>/gim, '[/i]')
			.replace(/<strong>/gim, '<b>')
			.replace(/<\/strong>/gim, '</b>')
			.replace(/<b>/gim, '[b]')
			.replace(/<\/b>/gim, '[/b]')
			.replace(/<u>/gim, '[u]')
			.replace(/<\/u>/gim, '[/u]')
			.replace(/<p>/gim, '[p]')
			.replace(/<\/p>/gim, '[/p]')
			.replace(/<mark/gim, '[mark]')
			.replace(/<\/mark>/gim, '[/mark]')
			.replace(/<span>/gim, '[span]')
			.replace(/<\/span>/gim, '[/span]')
			.replace(/<[^>]+>/gm, '')
			.replace(/\[i\]/gm, '<i>')
			.replace(/\[\/i\]/gm, '</i>')
			.replace(/\[b\]/gm, '<b>')
			.replace(/\[\/b\]/gm, '</b>')
			.replace(/\[u\]/gm, '<u>')
			.replace(/\[\/u\]/gm, '</u>')
			.replace(/\[p\]/gm, '<p>')
			.replace(/\[\/p\]/gm, '</p>')
			.replace(/\[mark\]/gm, '<mark')
			.replace(/\[\/mark\]/gm, '</mark>')
			.replace(/\[span\]/gm, '<span>')
			.replace(/\[\/span\]/gm, '</span>')
			.replace(/&nbsp;/gm, ' ')
			.replace(/\s+/gm, ' ');
	},

	removeParagraphTags: function (text) {
		return String(text)
			.replace(/<br>/gim, ' ')
			.replace(/<\/p>\s*<p>/gim, ' ')
			.replace(/<p>/gim, '')
			.replace(/<\/p>/gim, '');
	},

	replacePagePlaceholder: function (text, replacement) {
		return text.replace(/x{2,}|_{2,}/g, replacement);
	}
};
