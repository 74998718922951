/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import cn from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';

import { WebtextContent } from '@soomo/lib/components/page/Page';
import { scrollToElement } from '@soomo/lib/utils/elements';
import themes from '@soomo/lib/styles/themes';
import ThemeProvider from '@soomo/lib/styles/themes/ThemeProvider';
import FontProvider from '@soomo/lib/styles/themes/FontProvider';

import styles from './WebtextContentView.scss';

interface WebtextContentViewProps {
	theme: string;
	inContext?: boolean;
	scrollTo?: string;
	isLoading?: boolean;
}

const WebtextContentView: React.FC<WebtextContentViewProps> = (props) => {
	const { children, theme, inContext, scrollTo, isLoading } = props;

	useEffect(() => {
		if (inContext && scrollTo) {
			setTimeout(() => {
				const element = document.getElementById(scrollTo);
				element && scrollToElement(element);
			}, 500);
		}
	}, [scrollTo, inContext]);

	return (
		<div className={cn(styles.Root, inContext && styles.WebtextContentInContext)}>
			<div>
				{isLoading && (
					<div styleName="PageViewLoading">
						<div styleName="PageViewLoadingIndicator">
							<CircularProgress size={25} styleName="PageViewLoadingSpinner" />
							<span>Loading...</span>
						</div>
					</div>
				)}
				<ThemeProvider theme={themes[theme]}>
					<FontProvider />
					<WebtextContent>{children}</WebtextContent>
				</ThemeProvider>
			</div>
		</div>
	);
};

export default WebtextContentView;
