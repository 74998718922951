export const replaceData = (prev, current) => ({ ...current });

export const mergeData = (prev, current) => ({ ...prev, ...current });

export const mergeRecords = (prev, current) => {
	const result = { ...prev };
	Object.keys(current).forEach(function (recordId) {
		result[recordId] = { ...prev[recordId], ...current[recordId] };
	});
	return result;
};
