import * as types from '../actions/actionTypes.js';
import initialState from './initialState';
import { selectDefinedProperties } from 'Utils/objects';

export default function uiReducer(state = initialState.ui, action) {
	switch (action.type) {
		case types.SET_INITIAL_VIEW: {
			const definedInitialViewParams = selectDefinedProperties(action.payload);
			return {
				...state,
				initialView: definedInitialViewParams,
				currentViewParams: {
					...state.currentViewParams,
					...definedInitialViewParams
				}
			};
		}
		case types.UPDATE_CURRENT_VIEW_PARAMS: {
			/*
			 * Not override state values with undefined values.
			 * Empty values from state should impact on the location, not vice versa
			 */
			const definedViewParams = selectDefinedProperties(action.payload);
			return {
				...state,
				currentViewParams: {
					...state.currentViewParams,
					...definedViewParams
				},
				viewTemplate: action.viewTemplate
			};
		}

		case types.GRID_VIEW_SCROLL_TO_COLUMN:
			return {
				...state,
				gridViewScrollToColumn: action.payload.columnIndex
			};

		case types.SET_HIGHLIGHT_TIMING:
			return {
				...state,
				highlightTiming: action.payload
			};

		case types.SET_COLUMN_SCOPE_TYPE_SELECTABLE:
			return {
				...state,
				columnScopeTypeSelectable: action.payload
			};

		case types.SET_ALL_SECTIONS_ROW_ENABLED:
			return {
				...state,
				allSectionsRowEnabled: action.payload
			};

		default:
			return state;
	}
}
