import { getRequestAsync, requestAsync, mergeData } from './';

const elementsActivityQueryConfig = ({ courseId, userId, pageId, elementIds, force }) => ({
	path: '/api/courses/v1/answers',
	params: { course_id: courseId, user_id: userId, page_id: pageId },
	transform: (payload) => {
		const entities = {
			elementAnswerIds: {},
			answers: {},
			answerPostings: {}
		};

		/**
		 * todo: Remove duplication of entity merge logic in ElementActivityQuery
		 * Multiple implementations of the same logic for transforms
		 * can result in upstream request transforms being overwritten by later
		 * requests that lack the same transform logic.
		 */
		elementIds.forEach(function (elementId) {
			const elementActivity = payload.elements[elementId];
			const answerIds = [];
			elementActivity &&
				elementActivity.answers.forEach(function (answer) {
					if (!answer.id) {
						// See index.tsx:27
						answer.id = window.getSyntheticId();
					}
					answerIds.push(answer.id);
					entities.answers[answer.id] = { ...answer, user_id: userId };
					entities.answerPostings[answer.id] = answer.posted_at || answer.submitted_at;
				});
			entities.elementAnswerIds[`${userId}:${elementId}`] = answerIds;
		});

		return entities;
	},
	update: {
		elementAnswerIds: mergeData,
		answers: mergeData,
		answerPostings: mergeData
	},
	force
});

export const getElementsActivityQuery = ({ courseId, userId, pageId, elementIds, force = false }) =>
	getRequestAsync(
		elementsActivityQueryConfig({
			courseId,
			userId,
			pageId,
			elementIds,
			force
		})
	);

export default ({ courseId, userId, pageId, elementIds, force = false }) =>
	requestAsync(
		elementsActivityQueryConfig({
			courseId,
			userId,
			pageId,
			elementIds,
			force
		})
	);
