import React, { FC, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { StatusCode } from 'status-code-enum';
import { Redirect, useParams } from 'react-router-dom';

import { useJwtTokenPayload } from '@soomo/lib/hooks';

import { setCourseId, setJWT } from 'Actions/dataActions';
import { setInitialView, updateCurrentViewParams } from 'Actions/uiActions';
import { useQuery } from 'Hooks/useQuery';
import jwtTokenQuery from 'Requests/JwtTokenQuery';
import ScreenLoading from 'Components/ScreenLoading';
import ModalDialog from 'Components/ModalDialog';
import { OneTimeTokenPayload } from 'Types/index';
import { PERSISTED_CONFIG_KEY, useAppDispatch, useAppSelector } from 'Store/index';
import { setLocalStorage } from 'Services/index';

import './JwtAuthGateway.scss';

const JwtAuthGateway: FC = () => {
	const dispatch = useAppDispatch();

	const token = useAppSelector((state) => state.data.api.jwt);

	const pathParams = useParams<{ courseId?: string }>();
	const queryParams = useQuery();

	const { courseId } = pathParams;
	const oneTimeToken = queryParams.get('jwt');
	const oneTimeTokenPayload = useJwtTokenPayload<OneTimeTokenPayload>(oneTimeToken);

	const [isConfigApplied, setConfigApplied] = useState(false);
	const [error, setError] = useState<boolean | Error>(!oneTimeTokenPayload);

	useEffect(() => {
		if (!courseId || !oneTimeTokenPayload) return;

		// TODO Replace with the destructuring assigment after core api update will be delivered
		const initialView = oneTimeTokenPayload.extra?.initial_view || {};
		// const {
		// 	extra: { initial_view: initialView }
		// } = oneTimeTokenPayload;

		dispatch(jwtTokenQuery({ oneTimeToken }))
			.then((result) => {
				const { status, transformed: accessToken } = result;
				if (status !== StatusCode.SuccessOK) {
					return Promise.reject(Error(`Access token result status is ${status}`));
				}

				batch(() => {
					dispatch(setCourseId(courseId));
					dispatch(setJWT(accessToken));

					if (Object.keys(initialView).length) {
						dispatch(setInitialView(initialView));
					}
					dispatch(updateCurrentViewParams({ courseId }));
				});

				setLocalStorage({
					[PERSISTED_CONFIG_KEY]: { jwt: accessToken }
				});

				setConfigApplied(true);
			})
			.catch((error) => {
				setError(error);
				console.error(`Cannot fetch access token for the course ${courseId}`, error);
			});
	}, [courseId, dispatch, oneTimeToken, oneTimeTokenPayload]);

	return (
		<div styleName="JwtAuthGateway">
			{error ? (
				<ModalDialog open={true}>
					Your redirection link was broken. Please go back to your webtext and re-click the
					Analytics link.
				</ModalDialog>
			) : (
				<>
					<ScreenLoading />
					{isConfigApplied && token && <Redirect to="/" />}
				</>
			)}
		</div>
	);
};

export default JwtAuthGateway;
