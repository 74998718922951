import { requestAsync as rqRequestAsync } from 'redux-query';

export default ({ oneTimeToken }) =>
	(dispatch, getState) => {
		const state = getState();
		const apiHost = state.data.api.host;

		const path = '/api/auth/v1/token';
		const url = window.Playwright ? `http://${apiHost}${path}` : `https://${apiHost}${path}`;

		return dispatch(
			rqRequestAsync({
				url,
				transform: (payload, text) => text,
				options: {
					headers: {
						Accept: 'text/plain',
						Authorization: `Bearer ${oneTimeToken}`
					}
				},
				update: {}
			})
		);
	};
