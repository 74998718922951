import * as types from '../actions/actionTypes.js';
import initialState from './initialState';

export default function (state = initialState.data, action) {
	switch (action.type) {
		case types.SET_DATA_UNRECOVERABLE_ERROR:
			return {
				...state,
				unrecoverableError: action.payload
			};

		case types.SET_DATA_API_HOST:
			return {
				...state,
				api: {
					...state.api,
					host: action.payload.host
				}
			};

		case types.SET_DATA_WEBTEXTS_HOST:
			return {
				...state,
				webtexts: {
					...state.webtexts,
					host: action.payload.host
				}
			};

		case types.SET_JWT:
			return {
				...state,
				api: {
					...state.api,
					jwt: action.payload.jwt
				}
			};

		case types.SET_COURSE_ID:
			return {
				...state,
				course: {
					...state.course,
					id: action.payload.courseId
				}
			};

		case types.SET_COURSE_IMAGE_URL:
			return {
				...state,
				course: {
					...state.course,
					imageUrl: action.payload.courseImageUrl
				}
			};

		case types.REQUIRED_ENTITIES_AVAILABLE: {
			return {
				...state,
				requiredEntitiesAvailable: true
			};
		}

		default:
			return state;
	}
}
