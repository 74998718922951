import {
	SET_STUDENTS_FILTERS,
	SET_TOC_FILTERS,
	UPDATE_CURRENT_VIEW_PARAMS
} from '../actions/actionTypes';
import trackPageViewParams from '../requests/TrackPageViewMutation';
import { viewTemplateTypes } from '../constants';
import { updateCurrentViewParams } from 'Actions/uiActions';
import { setTocFilters } from 'Actions/tocActions';
import TocOutlineSelector from 'Containers/TocMenu/TocOutlineSelector';
import { setStudentsFilters } from 'Actions/studentsActions';
import { StudentsSelector } from 'Containers/StudentList/StudentsSelector';
import { isEmpty } from 'Utils/objects';

export const trackViewParamChangesMiddleware = (store) => (next) => (action) => {
	if (action.type === UPDATE_CURRENT_VIEW_PARAMS) {
		const state = store.getState();
		const { course } = state.data;
		const { currentViewParams } = state.ui;
		const deviceInfo = {
			screen: {
				width: window.screen.width,
				height: window.screen.height,
				dimensions: '' + window.screen.width + 'x' + window.screen.height
			},
			viewport: {
				width: window.innerWidth,
				height: window.innerHeight,
				dimensions: '' + window.innerWidth + 'x' + window.innerHeight
			}
		};
		store.dispatch(trackPageViewParams({ courseId: course.id, currentViewParams, deviceInfo }));
	}

	return next(action);
};

const selectViewTemplate = ({ scopeType, studentUser, element, welcomeAcknowledged }) => {
	if (!welcomeAcknowledged) {
		return viewTemplateTypes.MESSAGE;
	}
	if (scopeType !== 'page') {
		return viewTemplateTypes.GRID;
	}
	if (
		element &&
		(/response_board|sa_question|interactive_template/.test(element.type) ||
			'score_config' in element)
	) {
		return viewTemplateTypes.ELEMENT;
	}
	if (studentUser) {
		return viewTemplateTypes.PAGE;
	}
	if (scopeType === 'page' && !studentUser) {
		return viewTemplateTypes.AGGREGATED_PAGE;
	}
	return viewTemplateTypes.GRID;
};

export const setViewTemplateFromViewParamsMiddleware = (store) => (next) => (action) => {
	if (action.type === UPDATE_CURRENT_VIEW_PARAMS) {
		const state = store.getState();
		const { scopeTypes, elements, users } = state.entities;
		const { currentViewParams, initialView } = state.ui;

		const viewParams = { ...currentViewParams, ...action.payload };
		const {
			scope,
			gridViewScope,
			student,
			pageViewStudent,
			element: viewElement,
			welcomed
		} = viewParams;

		const welcomeAcknowledged = welcomed || Object.keys(initialView).length > 0;

		/*
		 * Here we can sanitize the payload for the UPDATE_CURRENT_VIEW_PARAMS and
		 * don't pass props if they cannot be applied for the current course
		 */
		const scopeType = scopeTypes[scope];
		const gridViewScopeType = scopeTypes[gridViewScope];
		if (!isEmpty(scopeTypes)) {
			if (!scopeType) {
				action.payload.scope = null;
			}
			if (!gridViewScopeType) {
				action.payload.gridViewScope = null;
			}
		}

		const studentUser = users?.[student];
		const pageViewStudentUser = users?.[pageViewStudent];
		if (!isEmpty(users)) {
			if (!studentUser) {
				action.payload.student = null;
			}
			if (!pageViewStudentUser) {
				action.payload.pageViewStudent = null;
			}
		}

		const elementId = viewElement?.split(':', 1)?.[0];
		const element = elements?.[elementId];
		if (!isEmpty(elements) && !element) {
			action.payload.element = null;
		}

		const viewTemplate = selectViewTemplate({
			scopeType,
			studentUser,
			element,
			welcomeAcknowledged
		});

		if (state.data.requiredEntitiesAvailable) {
			// when leaving grid view:
			// 1. remember scope
			// 2. clear search query
			if (
				state.ui.viewTemplate === viewTemplateTypes.GRID &&
				viewTemplate !== viewTemplateTypes.GRID
			) {
				action.payload.gridViewScope = currentViewParams.scope;
				action.payload.searchQuery = '';
			}

			// when entering grid view:
			// 1. clear TOC filters
			// 2. clear Students filters
			if (
				state.ui.viewTemplate !== viewTemplateTypes.GRID &&
				viewTemplate === viewTemplateTypes.GRID
			) {
				store.dispatch(setTocFilters({ filters: [] }));
				store.dispatch(setStudentsFilters({ filters: [] }));
			}
		}

		action.viewTemplate = viewTemplate;
	}

	const nextAction = next(action);

	// Manually trigger UI update
	if (
		action.type === 'REDUX-LOCATION-POP-ACTION' &&
		action?.payload?.query?.ui?.currentViewParams
	) {
		/*
		 * Passing an empty object to just trigger the middleware.
		 * All state props are were already set in the mapLocationToState function
		 */
		store.dispatch(updateCurrentViewParams({}));
	}

	return nextAction;
};

export const setViewParamsFromSelectorFilters = (store) => (next) => (action) => {
	const nextAction = next(action);

	if (action.type === SET_TOC_FILTERS && action.payload.filters.length) {
		const state = store.getState();
		const tocOutline = TocOutlineSelector(state);
		const studentIds = state.entities.studentIds;
		let scope = state.ui.currentViewParams.scope;
		if (!scope || tocOutline.pageIds.indexOf(scope) === -1) {
			scope = tocOutline.pageIds[0];
		}
		const student = state.ui.currentViewParams.student || studentIds[0];
		store.dispatch(updateCurrentViewParams({ scope, student }));
	}

	if (
		(action.type === SET_STUDENTS_FILTERS && action.payload.filters.length) ||
		(action.type === UPDATE_CURRENT_VIEW_PARAMS &&
			action.payload.scope != null &&
			action.viewTemplate !== 'grid' &&
			action.viewTemplate !== 'aggregated_page' &&
			action.viewTemplate !== 'element')
	) {
		const state = store.getState();
		if (state.data.requiredEntitiesAvailable) {
			const studentIds = StudentsSelector(state)
				.filter((student) => student.matches)
				.map((student) => student.id);
			let student = state.ui.currentViewParams.student;
			if (studentIds.length && studentIds.indexOf(student) === -1) {
				student = studentIds[0];
			}
			store.dispatch(updateCurrentViewParams({ student }));
		}
	}

	return nextAction;
};
