import React from 'react';

import { VoiceRecordingElement } from '@soomo/lib/types/WebtextManifest';

import BaseTable from './BaseTable';

import pageViewStyles from '../PageView.scss';

interface Props {
	element: VoiceRecordingElement;
	isPageActivityLoaded: boolean;
	aggregatedElement?: any;
	totalStudentsCount?: number;
}

const VoiceRecording: React.FC<Props> = (props) => {
	const { element, isPageActivityLoaded, totalStudentsCount, aggregatedElement } = props;
	const { submitted = 0, drafted = 0 } = aggregatedElement || {};

	const headerRow = ['Status', `Students (out of ${totalStudentsCount})`];
	const dataRows = [
		['Submitted', submitted],
		['Drafted', drafted],
		['Not started', totalStudentsCount - submitted - drafted]
	];

	return (
		<div>
			<div className={pageViewStyles.ActivitySummary}>
				<span className={pageViewStyles.QuestionType}>Voice Recording</span>
			</div>

			<BaseTable header={headerRow} rows={dataRows} isDataLoaded={isPageActivityLoaded} />
		</div>
	);
};

export default VoiceRecording;
