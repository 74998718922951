import popoverStyles from '../SelectorPanel/PopoverSelector.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import List from '@mui/material/List';

import TocOutlineSelector from './TocOutlineSelector';
import TocFilterListItem from './TocFilterListItem';
import ChapterListItem from './ChapterListItem';
import { toggleTocChapterOpen } from 'Actions/tocActions';
import { viewTemplateTypes } from '../../constants';
import './TocMenu.scss';

class TocMenu extends Component {
	static propTypes = {
		outline: PropTypes.object,
		chapterOpen: PropTypes.object,
		onSelect: PropTypes.func,
		onChapterToggle: PropTypes.func,
		tocFilteringEnabled: PropTypes.bool,
		currentViewTemplate: PropTypes.string
	};

	onChapterToggle = (chapterId) => {
		return () => {
			this.props.onChapterToggle({ chapterId });
		};
	};

	render() {
		const { currentViewTemplate, tocFilteringEnabled } = this.props;
		const filteringEnabled = currentViewTemplate !== viewTemplateTypes.GRID && tocFilteringEnabled;

		return (
			<div className={popoverStyles.PagesPopoverMenu}>
				<List>
					{filteringEnabled ? <TocFilterListItem /> : null}
					{this.props.outline.chapters.map((chapterOutline) => (
						<ChapterListItem
							key={chapterOutline.id}
							tocOutline={this.props.outline}
							chapterOutline={chapterOutline}
							onSelect={this.props.onSelect}
							onToggle={this.onChapterToggle(chapterOutline.id)}
							open={this.props.chapterOpen[chapterOutline.id]}
							currentViewTemplate={this.props.currentViewTemplate}
						/>
					))}
				</List>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	outline: TocOutlineSelector(state),
	chapterOpen: state.toc.chapterOpen,
	tocFilteringEnabled: state.toc.filteringEnabled,
	currentViewTemplate: state.ui.viewTemplate
});

const mapDispatchToProps = (dispatch) => ({
	onChapterToggle: ({ chapterId }) => {
		dispatch(toggleTocChapterOpen({ chapterId }));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TocMenu);
