import React from 'react';
import cn from 'classnames';

import styles from './BaseTable.scss';
import LoadingShimmer from './LoadingShimmer';

interface Props {
	header?: string[];
	rows?: (string | number | JSX.Element)[][];
	isDataLoaded?: boolean;
}

const BaseTable: React.FC<Props> = (props) => {
	const { header, rows, isDataLoaded = true } = props;

	return (
		<table className={styles.BaseTable}>
			<thead>
				<tr>
					{header?.map((item, index) => (
						<th key={`header-${index}`}>{item}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{rows?.map((row, i) => (
					<tr key={i} className={cn(i % 2 === 0 ? styles.TableRowEven : styles.TableRowOdd)}>
						<th>{row[0]}</th>

						{row.slice(1).map((item, j) => (
							<td key={`data-${i}-${j}`}>{isDataLoaded ? item : <LoadingShimmer />}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default BaseTable;
