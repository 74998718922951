import selectorPanelStyles from '../SelectorPanel/SelectorPanel.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { tocFilterLabels } from 'Containers/TocMenu/TocFilters';

function FilterExplanation({ tocFilters }) {
	return (
		<div className={selectorPanelStyles.FilterBox}>
			<h3 className={selectorPanelStyles.FilterDescriptionLabel}>You are now filtering to see:</h3>
			<div className={selectorPanelStyles.FilterDescription}>
				<div>
					Only <strong>webtext pages</strong> that contain:
					<ul style={{ listStyle: 'none', padding: '0 20px' }}>
						{tocFilters.map((filter) => (
							<li key={filter}>{tocFilterLabels[filter]}</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}

FilterExplanation.propTypes = {
	tocFilters: PropTypes.array.isRequired
};

export default FilterExplanation;
