import * as types from '../actions/actionTypes.js';
import initialState from './initialState';
import { tocFilterOptions } from 'Containers/TocMenu/TocFilters';

const allChaptersOpen = (state) => {
	return state.chapterIds.reduce((obj, chapterId) => {
		obj[chapterId] = true;
		return obj;
	}, {});
};

const allChaptersClosed = (state) => {
	return state.chapterIds.reduce((obj, chapterId) => {
		obj[chapterId] = false;
		return obj;
	}, {});
};

export default function (state = initialState.toc, action) {
	switch (action.type) {
		case types.SET_TOC_CHAPTER_IDS: {
			const { chapterIds } = action.payload;
			return {
				...state,
				chapterIds
			};
		}

		case types.TOGGLE_TOC_CHAPTER_OPEN: {
			const { chapterId } = action.payload;
			return {
				...state,
				chapterOpen: {
					...state.chapterOpen,
					[chapterId]: !state.chapterOpen[chapterId]
				}
			};
		}

		case types.SET_TOC_CHAPTER_OPEN: {
			const { chapterId, open } = action.payload;
			return {
				...state,
				chapterOpen: {
					...state.chapterOpen,
					[chapterId]: open
				}
			};
		}

		case types.SET_ALL_TOC_CHAPTERS_OPEN: {
			return {
				...state,
				chapterOpen: allChaptersOpen(state)
			};
		}

		case types.SET_TOC_FILTERS: {
			// extra logic to ensure we order filters same as they appear in the list.
			const filters = tocFilterOptions
				.map((opt) => opt[0])
				.filter((f) => action.payload.filters.indexOf(f) !== -1);
			const nextState = { ...state, filters };

			if (filters.length) {
				nextState.chapterOpen = allChaptersOpen(state);
			} else {
				nextState.chapterOpen = allChaptersClosed(state);
			}

			return nextState;
		}

		case types.SET_TOC_FILTERING_ENABLED: {
			return {
				...state,
				filteringEnabled: action.payload.enabled
			};
		}

		default:
			return state;
	}
}
