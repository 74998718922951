import { createSelector } from 'reselect';
import sortUserIdsCompletions from 'Sorts/SortUserIdsCompletions';
import TocOutlineSelector from '../TocMenu/TocOutlineSelector';

export const StudentsSelector = createSelector(
	(state) => state.entities.studentIds,
	(state) => state.ui.currentViewParams.student,
	(state) => state.entities.users,
	TocOutlineSelector,
	(state) => state.students.filters,
	(state) => state.entities.pages[state.ui.currentViewParams.scope],
	(state) => state.entities.elements,
	(state) => state.entities.webtextComments,
	(state) => state.entities.elementAnswerIds,
	(state) => state.entities.answers,
	(
		studentIds_,
		selectedStudentId,
		usersById,
		tocOutline,
		studentsFilters,
		currentPage,
		elementsById,
		webtextComments,
		elementAnswerIds,
		answers
	) => {
		let studentIds = studentIds_.concat();
		const studentStatuses = {};
		const studentMatches = {};

		if (currentPage && tocOutline.pageIds.indexOf(currentPage.id) !== -1) {
			const pageWritingTemplates = currentPage.element_ids.reduce((list, id) => {
				const element = elementsById[id];
				if (element.type === 'interactive_template') {
					list.push(element);
				}
				return list;
			}, []);

			if (pageWritingTemplates.length) {
				studentIds = studentIds.filter((studentId) => {
					let numCompleted = 0;
					let numComments = 0;
					let numRevised = 0;

					pageWritingTemplates.forEach((wt) => {
						const comment = webtextComments[`${studentId}:${wt.id}`];
						if (comment) {
							numComments++;
						}

						const answerIds = elementAnswerIds[`${studentId}:${wt.id}`];
						if (answerIds && answerIds.length) {
							const answer = answers[answerIds[0]]; // WT only has one Answer record
							const responseData = answer.body ? JSON.parse(answer.body) : null;
							if (responseData && !responseData._draft) {
								numCompleted++;
								if (comment && answer.saved_at > comment.last_saved_at) {
									numRevised++;
								}
							}
						}
					});

					const status = {
						completed: numComments === 0 && numCompleted === pageWritingTemplates.length,
						inProgress:
							numComments === 0 && numCompleted > 0 && numCompleted < pageWritingTemplates.length,
						noResponse: numComments === 0 && numCompleted === 0,
						alreadyCommented: numComments > 0,
						revised: numRevised > 0
					};

					studentMatches[studentId] = studentsFilters.length
						? studentsFilters.some((filter) => status[filter])
						: true;

					if (studentMatches[studentId]) {
						studentStatuses[studentId] = status;
					}

					return studentMatches[studentId] || studentId === selectedStudentId;
				});
			}
		}

		const sortedStudentsIds = sortUserIdsCompletions(studentIds, usersById, 'user.last_name ASC');
		return sortedStudentsIds.map((studentId) => ({
			...usersById[studentId],
			status: studentStatuses[studentId] || {},
			matches: studentMatches[studentId] !== false
		}));
	}
);

export const StudentIdsSelector = createSelector(StudentsSelector, (students) =>
	students.map((student) => student.id)
);

export const NoMatchingStudentsSelector = createSelector(
	StudentsSelector,
	(students) => students.filter((student) => student.matches).length === 0
);
