import { roundGradeDenominator, roundGradeNumerator } from '@soomo/lib/notebook/utils';
import { css } from 'emotion';
import React from 'react';

import { useAppSelector } from 'Store/index';

interface Props {
	user: any;
	columnIndex?: number;
}

const GradeAverageCell: React.VFC<Props> = ({ user, columnIndex }) => {
	const studentGradesResponse = useAppSelector((state) => state.entities.studentGrades);
	const {
		student_grades: studentGrades,
		gradebook_columns: gradebookColumns,
		points_in_course: pointsInCourse,
		lms_gradebook_precision: lmsGradebookPrecision,
		average_points: averagePoints
	} = studentGradesResponse;

	const studentGrade = studentGrades[user?.id];
	let numerator = 0;
	const denominator =
		columnIndex != null ? gradebookColumns[columnIndex].possible_points : pointsInCourse;
	if (studentGrade) {
		numerator =
			columnIndex != null
				? studentGrade.column_grades[columnIndex]?.current_points ?? 0
				: studentGrade.current_points_sum;
	} else {
		numerator =
			columnIndex != null ? averagePoints.column_averages[columnIndex] : averagePoints.total;
	}

	return (
		<span className={averageCellStyles}>
			{roundGradeNumerator(numerator, lmsGradebookPrecision)} /{' '}
			{roundGradeDenominator(denominator, lmsGradebookPrecision)}
		</span>
	);
};

export default GradeAverageCell;

const averageCellStyles = css`
	font-weight: bold;
`;
