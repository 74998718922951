import DocxTemplater from 'docxtemplater';
import JSZipUtils from 'jszip-utils';
import WRunModule from '@soomo/docxtemplater-wrun-module';
import FootNoteModule from '@soomo/docxtemplater-footnote-module';
import Utils from './Utils';
import OutputContext from './OutputContext';

export default function (builder, selections, outputType) {
	outputType || (outputType = 'blob');
	return new Promise(function (resolve, reject) {
		const data = Object.assign(JSON.parse(JSON.stringify(selections)), courseDecisionData(builder));

		const templateUrl = builder.config.docx.templateUrl;

		const output = new OutputContext(builder, selections);
		const { apaReferenceList, turabianFootNotes, turabianBibliography } = output;

		const additionalData = {};
		Object.keys(data).forEach((key) => {
			const value = data[key];
			const item = { source: key };
			if (typeof value === 'object') {
				if (value.kind === 'fill-in-with-citations') {
					item.kind = value.kind;
					const str = output.getFillInWithCitationOutput({
						item,
						onScreen: false,
						useMarkerCitations: item.redactorEnabled
					});
					if (item.redactorEnabled) {
						data[key] = htmlToDocx(str);
					} else {
						data[key] = convertNbspToSpace(str);
					}
				}
			} else if (typeof value === 'string') {
				let str = normalizeWhitespace(data[key]);
				const docxKey = '#{key}-run-docx';
				const plainTextKey = '#{key}-plain-text';
				if (str.match(/<[^>]+>/gm)) {
					additionalData[plainTextKey] = stripTags(str);
					additionalData[docxKey] = htmlToDocx(str);
					data[key] = additionalData[docxKey];
				} else {
					str = Utils.entityEscapeContent(str);
					data[key] = str;
					additionalData[plainTextKey] = stripTags(str);
					additionalData[docxKey] = `<w:r><w:t xml:space='preserve'>${str}</w:t></w:r>`;
				}
			}
		});

		Object.assign(data, additionalData);

		if (builder.config.docx.styleCitations) {
			Object.keys(data).forEach(function (key) {
				if (key.match(/_citation$/)) {
					data[key] = styleCitation(data[key]);
				}
			});
		}

		if (builder.citationStyle === 'apa') {
			data.references = generateReferences(apaReferenceList);
		} else if (builder.citationStyle === 'turabian') {
			data.references = generateReferences(turabianBibliography);
		} else {
			data.references = '';
		}

		JSZipUtils.getBinaryContent(templateUrl, function (err, content) {
			if (err) return reject(err);

			const doc = new DocxTemplater(content);

			if (builder.citationStyle === 'turabian') {
				doc.attachModule(new FootNoteModule({ footnotes: turabianFootNotes }));
			}

			doc.attachModule(new WRunModule());

			doc.setData(data);
			doc.render();
			const out = doc.getZip().generate({ type: outputType });

			resolve(out);
		});
	});
}

function courseDecisionData(builder) {
	const result = {};

	builder.importedCourseDecisions.forEach((decisionName) => {
		if (decisionName !== 'citation_style') {
			const decisionData = builder.getDecisionOption(decisionName);
			Object.keys(decisionData).forEach((key) => {
				if (typeof decisionData[key] === 'string') {
					result[decisionName + '.' + key] = decisionData[key];
				}
			});
		}
	});

	return result;
}

function convertNbspToSpace(str) {
	return str.replace(/&nbsp;/gm, ' ');
}

function normalizeWhitespace(str) {
	str = str.replace(/&nbsp;/gm, ' ');
	str = str.replace(/\s+/gm, ' ');

	return str;
}

function normalizeTags(str) {
	str = str.replace(/<em>/gim, '<i>');
	str = str.replace(/<\/em>/gim, '</i>');

	str = str.replace(/<strong>/gim, '<b>');
	str = str.replace(/<\/strong>/gim, '</b>');

	return str;
}

function preserveTags(str) {
	str = str.replace(/<i>/gim, '{{I}}');
	str = str.replace(/<\/i>/gim, '{{/I}}');

	str = str.replace(/<b>/gim, '{{B}}');
	str = str.replace(/<\/b>/gim, '{{/B}}');

	str = str.replace(/<p>/gim, '{{P}}');
	str = str.replace(/<\/p>/gim, '{{/P}}');

	return str;
}

function stripTags(str) {
	str = str.replace(/<(?:.|\n)*?>/gm, '');

	// str = angular.element('<textarea />').html(str).text()
	const el = document.createElement('textarea');
	el.innerHtml = str;
	str = el.innerText;

	str = Utils.entityEscapeContent(str);

	return str;
}

function restoreTagsAsDocx(str) {
	str = str.replace(/\{\{I\}\}\{\{B\}\}/gm, '{{B}}{{I}}');
	str = str.replace(/\{\{\/I\}\}\{\{\/B\}\}/gm, '{{/B}}{{/I}}');

	str = str.replace(
		/\{\{B\}\}\{\{I\}\}/gm,
		'</w:t></w:r><w:r><w:rPr><w:b w:val="true"/><w:i w:val="true"/></w:rPr><w:t xml:space="preserve">'
	);
	str = str.replace(
		/\{\{\/B\}\}\{\{\/I\}\}/gm,
		'</w:t></w:r><w:r><w:rPr><w:b w:val="false"/><w:i w:val="false"/></w:rPr><w:t xml:space="preserve">'
	);

	str = str.replace(
		/\{\{I\}\}/,
		'</w:t></w:r><w:r><w:rPr><w:i w:val="true"/></w:rPr><w:t xml:space="preserve">'
	);
	str = str.replace(
		/\{\{\/I\}\}/,
		'</w:t></w:r><w:r><w:rPr><w:i w:val="false"/></w:rPr><w:t xml:space="preserve">'
	);

	str = str.replace(
		/\{\{B\}\}/gm,
		'</w:t></w:r><w:r><w:rPr><w:b w:val="true"/></w:rPr><w:t xml:space="preserve">'
	);
	str = str.replace(
		/\{\{\/B\}\}/gm,
		'</w:t></w:r><w:r><w:rPr><w:b w:val="false"/></w:rPr><w:t xml:space="preserve">'
	);

	str = str.replace(/\{\{\/P\}\}\s*\{\{P\}\}/gm, '<w:br/>');
	str = str.replace(/\{\{P\}\}/gm, '');
	str = str.replace(/\{\{\/P\}\}/gm, '');

	return str;
}

function htmlToDocx(str) {
	str = normalizeWhitespace(str);
	str = normalizeTags(str);
	str = preserveTags(str);
	str = str.replace(/<sup>/g, '').replace(/<\/sup>/g, '');
	str = stripTags(str);
	str = restoreTagsAsDocx(str);

	return `<w:r><w:t xml:space='preserve'>${str}</w:t></w:r>`;
}

function styleCitation(str) {
	str = str.replace(/<i>/gim, '{{I}}');
	str = str.replace(/<\/i>/gim, '{{/I}}');
	str = stripTags(str);
	str = str.replace(
		/\{\{I\}\}/,
		'</w:t></w:r><w:r><w:rPr><w:i w:val="true"/></w:rPr><w:t xml:space="preserve">'
	);
	str = str.replace(
		/\{\{\/I\}\}/,
		'</w:t></w:r><w:r><w:rPr><w:i w:val="false"/></w:rPr><w:t xml:space="preserve">'
	);

	return `<w:p><w:pPr><w:ind w:left='720' w:hanging='720'/><w:spacing w:line='480' w:lineRule='auto'/></w:pPr><w:r><w:t xml:space='preserve'>${str}</w:t></w:r></w:p>`;
}

function generateReferences(references) {
	let str = '';
	const values = Object.keys(references)
		.sort()
		.map((key) => references[key]);

	values.forEach(function (value) {
		value = value.replace(/<em>/gim, '<i>');
		value = value.replace(/<\/em>/gim, '</i>');
		value = value.replace(/<i>/gim, '{{I}}');
		value = value.replace(/<\/i>/gim, '{{/I}}');

		value = Utils.entityEscapeContent(value);

		value = value.replace(
			/\{\{I\}\}/,
			'</w:t></w:r><w:r><w:rPr><w:i w:val="true"/></w:rPr><w:t xml:space="preserve">'
		);
		value = value.replace(
			/\{\{\/I\}\}/,
			'</w:t></w:r><w:r><w:rPr><w:i w:val="false"/></w:rPr><w:t xml:space="preserve">'
		);

		str += `<w:p><w:pPr><w:ind w:left='720' w:hanging='720'/><w:spacing w:line='480' w:lineRule='auto'/></w:pPr><w:r><w:t xml:space='preserve'>${value}</w:t></w:r></w:p>`;
	});

	return str;
}
