import React from 'react';

import { SAQuestionElement } from '@soomo/lib/types/WebtextManifest';

import AnalyticsLink from 'Components/AnalyticsLink';
import { Page } from 'Types/index';
import BaseTable from './BaseTable';

import pageViewStyles from '../PageView.scss';
import styles from '../AggregatedPageView.scss';

interface SAQuestionAggregated {
	posted: number;
	drafted: number;
}

interface Props {
	element: SAQuestionElement;
	isPageActivityLoaded: boolean;
	aggregatedElement?: SAQuestionAggregated;
	totalStudentsCount?: number;
	page: Page;
	uiActions: any;
}

const SAQuestion: React.FC<Props> = (props) => {
	const { element, isPageActivityLoaded, aggregatedElement, totalStudentsCount, uiActions, page } =
		props;
	const { posted = 0, drafted = 0 } = aggregatedElement || {};

	const headerRow = ['Status', `Students (out of ${totalStudentsCount})`];
	const dataRows = [
		['Posted', posted],
		['Drafted', drafted],
		['Not started', totalStudentsCount - posted - drafted]
	];

	const onViewAllResponses = () => {
		uiActions.updateCurrentViewParams({
			scope: page.id,
			element: element.id,
			student: null,
			pageViewStudent: null
		});
	};

	return (
		<div>
			<div className={pageViewStyles.ActivitySummary}>
				<span className={pageViewStyles.QuestionType}>Short-Answer Question</span>
				<span>&nbsp;&mdash;&nbsp;</span>
				<AnalyticsLink variant="text-uppercase" onClick={onViewAllResponses}>
					view all responses
				</AnalyticsLink>
			</div>
			<h2
				className={pageViewStyles.QuestionBody}
				dangerouslySetInnerHTML={{ __html: element.body }}
			/>

			<BaseTable header={headerRow} rows={dataRows} isDataLoaded={isPageActivityLoaded} />

			<div className={styles.AggregatedQuestionInfo}>
				<AnalyticsLink variant="text" onClick={onViewAllResponses}>
					View all responses
				</AnalyticsLink>
			</div>
		</div>
	);
};

export default SAQuestion;
