import store from '../store';

let isUserLogged = false;

const initializeLibrary = (libraryInitializationCallback) => {
	const initializationInterval = setInterval(() => {
		const state = store.getState();
		if (!state.entities.currentUser.id || !state.data.api.host) return;

		const user = state.entities.currentUser;
		if (__DEV__ && !isUserLogged) {
			console.log(user);
			isUserLogged = true;
		}

		libraryInitializationCallback(user);

		clearInterval(initializationInterval);
	}, 500);
};

export default {
	initializeFullstory: () => {
		const fullstoryInitCallback = (user) => {
			const { FS } = window;
			if (FS && user.fullstory_enabled) {
				FS('start');
				FS('setIdentity', { uid: String(user.id) });
			} else if (__DEV__) {
				console.log('FS not available.');
			}
		};
		initializeLibrary(fullstoryInitCallback);
	},
	initializeRollbar: () => {
		const rollbarInitCallback = (user) => {
			const { Rollbar } = window;
			if (!Rollbar) return;

			Rollbar.configure({
				payload: {
					person: {
						id: user.id,
						username: user.email,
						email: user.email
					}
				}
			});
		};
		initializeLibrary(rollbarInitCallback);
	},
	initializeIntercom: () => {
		const intercomInitCallback = (user) => {
			const { Intercom, __intercom_app_id__ } = window;
			if (!Intercom) return;

			Intercom('boot', {
				user_hash: user.intercom_hash,
				user_id: String(user.id),
				name: user.name,
				email: user.email,
				app_id: __intercom_app_id__
			});
		};
		initializeLibrary(intercomInitCallback);
	}
};
