import { mutateAsync as rqMutateAsync } from 'redux-query';

const mutateAsync =
	({ method, path, params = {}, transform = (data) => ({ ...data }), update, optimisticUpdate }) =>
	(dispatch, getState) => {
		const state = getState();
		const { api } = state.data;

		if (!api.jwt) {
			return new Promise((resolve, reject) => reject(new Error('Unauthorized')));
		}

		let url = window.Playwright ? `http://${api.host}${path}` : `https://${api.host}${path}`;

		return dispatch(
			rqMutateAsync({
				url,
				body: JSON.stringify(params),
				transform,
				update,
				optimisticUpdate,
				options: {
					method: method || 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${api.jwt}`,
						Accept: 'application/json'
					}
				}
			})
		);
	};

export { mutateAsync };
