import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import { studentsFilterData, studentsFilterOptions } from './StudentsFilters';
import styles from './StudentList.scss';

const StudentsFilterListHeader = ({ studentsFilters, noMatchingStudents }) => {
	const filterDescription = useMemo(() => {
		if (studentsFilters.length === 1) {
			const [filter] = studentsFilters;
			return studentsFilterData[filter];
		}
		if (studentsFilters.length > 1 && studentsFilters.length < studentsFilterOptions.length) {
			return 'Multiple';
		}
		return 'All Students';
	}, [studentsFilters]);

	return (
		<>
			<ListItem
				key="StudentsFilterListHeader"
				classes={{ root: styles.StudentsFilterListHeaderItem }}>
				<h3 className={styles.StudentsFiltersDescription}>Viewing: {filterDescription}</h3>
			</ListItem>
			{studentsFilters.length > 0 && noMatchingStudents && (
				<ListItem
					key="NoStudentsMatchFilter"
					classes={{ root: styles.StudentsFilterListHeaderItem }}>
					<em>No students match this filter.</em>
				</ListItem>
			)}
		</>
	);
};

StudentsFilterListHeader.propTypes = {
	studentsFilters: PropTypes.array,
	noMatchingStudents: PropTypes.bool
};

export default StudentsFilterListHeader;
