import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import BaseGrid from './BaseGrid';
import ChapterCompletionCell from './ChapterCompletionCell';
import ChapterTimingCell from './ChapterTimingCell';
import ChapterScoreCell from './ChapterScoreCell';
import ChapterHeaderCellHover from './ChapterHeaderCellHover';
import { updateCurrentViewParams } from 'Actions/uiActions';

const chapterSelector = createSelector(
	(state) => state.entities.chapters,
	(state) => state.entities.page_groups,
	(state) => state.entities.pages,
	(state) => state.ui.currentViewParams.scope,
	(chapters, pageGroups, pages, scopeId) => {
		let chapter = chapters[scopeId];
		if (!chapter) {
			const page = pages[scopeId];
			if (page) {
				chapter = chapters[page.chapter_id];
			} else {
				const pageGroup = pageGroups[scopeId];
				if (pageGroup) {
					chapter = chapters[pageGroup.chapter_id];
				}
			}
		}
		return chapter || null;
	}
);

class ChaptersGrid extends React.Component {
	getScope = ({ columnIndex }) => this.props.chaptersById[this.props.chapterIds[columnIndex]];

	getScrollToColumn = () => {
		const { chapter, chapterIds } = this.props;
		return chapter ? chapterIds.indexOf(chapter.id) : null;
	};

	renderHeaderCell = ({ columnIndex }) => {
		const chapter = this.getScope({ columnIndex });

		let spanContent;
		if (chapter.chapter_number && chapter.chapter_number.trim().length > 0) {
			spanContent = chapter.chapter_number;
		} else {
			spanContent = chapter.chapter_name[0];
		}

		return <span>{spanContent}</span>;
	};

	renderCompletionCell = ({ columnIndex, ...passthroughProps }) => (
		<ChapterCompletionCell chapter={this.getScope({ columnIndex })} {...passthroughProps} />
	);

	renderTimingCell = ({ columnIndex, ...passthroughProps }) => (
		<ChapterTimingCell chapter={this.getScope({ columnIndex })} {...passthroughProps} />
	);

	renderScoreCell = ({ columnIndex, ...passthroughProps }) => (
		<ChapterScoreCell chapter={this.getScope({ columnIndex })} {...passthroughProps} />
	);

	headerCellHoverContent = ({ columnIndex }) => {
		const chapterId = this.props.chapterIds[columnIndex];
		return <ChapterHeaderCellHover chapterId={chapterId} />;
	};

	render() {
		if (!this.props.chapterIds) return null;

		return (
			<BaseGrid
				columnCount={this.props.chapterIds.length}
				getScope={this.getScope}
				scrollToColumn={this.getScrollToColumn()}
				renderHeaderCell={this.renderHeaderCell}
				renderCompletionCell={this.renderCompletionCell}
				renderTimingCell={this.renderTimingCell}
				renderScoreCell={this.renderScoreCell}
				headerCellHoverContent={this.headerCellHoverContent}
				loaded={this.props.loaded}
				{...this.props}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	chapter: chapterSelector(state),
	chapterIds: state.entities.toc.chapter_ids,
	chaptersById: state.entities.chapters
});

const mapDispatchToProps = (dispatch) => ({
	updateCurrentViewParams: (params) => {
		dispatch(updateCurrentViewParams(params));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ChaptersGrid);
