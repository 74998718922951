import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import BaseGrid from './BaseGrid';
import PageGroupCompletionCell from './PageGroupCompletionCell';
import PageGroupTimingCell from './PageGroupTimingCell';
import PageGroupScoreCell from './PageGroupScoreCell';
import PageGroupHeaderCellHover from './PageGroupHeaderCellHover';
import ChapterCompletionCell from './ChapterCompletionCell';
import ChapterTimingCell from './ChapterTimingCell';
import ChapterScoreCell from './ChapterScoreCell';
import ChapterHeaderCellHover from './ChapterHeaderCellHover';
import { updateCurrentViewParams } from 'Actions/uiActions';
import { colors } from 'Theme';

const chapterHasPageGroups = (tocConfig, chapter) => {
	const chapterConfig = tocConfig.chapters.types[chapter.chapter_type] || {};
	const chapterSettings = chapterConfig.settings || {};
	return chapterSettings.page_groups_enabled;
};

const pageGroupOrChapterSelector = createSelector(
	(state) => state.entities.toc.config,
	(state) => state.entities.chapters,
	(state) => state.entities.page_groups,
	(state) => state.entities.pages,
	(state) => state.ui.currentViewParams.scope,
	(tocConfig, chapters, pageGroups, pages, scopeId) => {
		let pageGroup = pageGroups[scopeId];
		if (pageGroup) return pageGroup;

		let chapter = chapters[scopeId];
		if (chapter && chapterHasPageGroups(tocConfig, chapter)) {
			pageGroup = pageGroups[chapter.page_group_ids[0]];
		}

		if (!pageGroup && !chapter) {
			const page = pages[scopeId];
			if (page) {
				chapter = chapters[page.chapter_id];
				if (chapter && chapterHasPageGroups(tocConfig, chapter)) {
					pageGroup = pageGroups[page.page_group_id];
				}
			}
		}

		return pageGroup || chapter || null;
	}
);

class PageGroupsGrid extends React.Component {
	isPageGroup = (scope) => Object.prototype.hasOwnProperty.call(scope, 'chapter_id');

	getScope = ({ columnIndex }) => {
		const scopeId = this.props.pageGroupOrChapterIds[columnIndex];
		return this.props.pageGroupsById[scopeId] || this.props.chaptersById[scopeId];
	};

	renderColumnGroupCell = ({ columnIndex }, extraStyle) => {
		const pageGroup = this.getScope({ columnIndex });
		const chapter = this.props.chaptersById[pageGroup.chapter_id];

		if (chapter && chapter.page_group_ids[0] === pageGroup.id) {
			const cellWidth = 56; // see BaseGrid#getColumnWidth
			const fullWidth = cellWidth * chapter.page_group_ids.length;
			const lineWidth = (cellWidth - 2) * chapter.page_group_ids.length;

			extraStyle.display = 'flex';
			extraStyle.flexDirection = 'row';
			extraStyle.alignItems = 'center';

			const style = {
				width: fullWidth,
				margin: '0 auto',
				textAlign: 'center',
				color: colors.appAccentColor,
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				fontSize: '0.7em',
				lineHeight: '1.3em'
			};

			const lineStyle = {
				width: lineWidth,
				height: '8px',
				margin: '5px auto 0',
				border: `1px solid ${colors.appAccentColor}`,
				borderBottomWidth: '0'
			};

			return (
				<div>
					<div style={style}>{chapter.chapter_number}</div>
					<div style={lineStyle} />
				</div>
			);
		} else {
			return null;
		}
	};

	getScrollToColumn = () => {
		const { pageGroupOrChapter, pageGroupOrChapterIds } = this.props;
		return pageGroupOrChapter ? pageGroupOrChapterIds.indexOf(pageGroupOrChapter.id) : null;
	};

	getMaximumColumnGroupSize = () => {
		const { chaptersById } = this.props;
		const chapterLengths = Object.values(chaptersById).map((ch) => ch.page_group_ids.length);
		return chapterLengths.length ? Math.max.apply(null, chapterLengths) : 0;
	};

	renderHeaderCell = ({ columnIndex }) => {
		const scope = this.getScope({ columnIndex });

		let spanContent;
		const number = scope.page_group_number || scope.chapter_number;
		if (number && number.trim().length > 0) {
			spanContent = number;
		} else {
			const name = scope.name || scope.chapter_name;
			spanContent = name[0];
		}

		return <span>{spanContent}</span>;
	};

	renderCompletionCell = ({ columnIndex, ...passthroughProps }) => {
		const scope = this.getScope({ columnIndex });

		if (this.isPageGroup(scope)) {
			return <PageGroupCompletionCell pageGroup={scope} {...passthroughProps} />;
		} else {
			return <ChapterCompletionCell chapter={scope} {...passthroughProps} />;
		}
	};

	renderTimingCell = ({ columnIndex, ...passthroughProps }) => {
		const scope = this.getScope({ columnIndex });

		if (this.isPageGroup(scope)) {
			return <PageGroupTimingCell pageGroup={scope} {...passthroughProps} />;
		} else {
			return <ChapterTimingCell chapter={scope} {...passthroughProps} />;
		}
	};

	renderScoreCell = ({ columnIndex, ...passthroughProps }) => {
		const scope = this.getScope({ columnIndex });

		if (this.isPageGroup(scope)) {
			return <PageGroupScoreCell pageGroup={scope} {...passthroughProps} />;
		} else {
			return <ChapterScoreCell chapter={scope} {...passthroughProps} />;
		}
	};

	headerCellHoverContent = ({ columnIndex }) => {
		const scope = this.getScope({ columnIndex });

		if (this.isPageGroup(scope)) {
			return <PageGroupHeaderCellHover pageGroupId={scope.id} />;
		} else {
			return <ChapterHeaderCellHover chapterId={scope.id} />;
		}
	};

	render() {
		if (!this.props.pageGroupOrChapterIds) return null;

		return (
			<BaseGrid
				columnCount={this.props.pageGroupOrChapterIds.length}
				getScope={this.getScope}
				scrollToColumn={this.getScrollToColumn()}
				renderColumnGroupCell={this.renderColumnGroupCell}
				maximumColumnGroupSize={this.getMaximumColumnGroupSize()}
				renderHeaderCell={this.renderHeaderCell}
				renderCompletionCell={this.renderCompletionCell}
				renderTimingCell={this.renderTimingCell}
				renderScoreCell={this.renderScoreCell}
				headerCellHoverContent={this.headerCellHoverContent}
				{...this.props}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	const chapters = state.entities.toc.chapter_ids.map((id) => state.entities.chapters[id]);

	const pageGroupOrChapter = pageGroupOrChapterSelector(state);

	const pageGroupOrChapterIds = chapters.reduce((memo, chapter) => {
		if (chapter.page_group_ids.length === 0) {
			return memo.concat([chapter.id]);
		} else {
			return memo.concat(chapter.page_group_ids);
		}
	}, []);
	return {
		pageGroupOrChapter,
		pageGroupOrChapterIds,
		pageGroupsById: state.entities.page_groups,
		chaptersById: state.entities.chapters
	};
};

const mapDispatchToProps = (dispatch) => ({
	updateCurrentViewParams: (params) => {
		dispatch(updateCurrentViewParams(params));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PageGroupsGrid);
