import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuestionCountStats from './QuestionCountStats';

const ChapterHeaderCellHover = ({ chapter, chapterStats }) => {
	const averageTimeInChapterInWords = chapterStats.avg_web_time_spent
		? `${Math.ceil(chapterStats.avg_web_time_spent / 60).toFixed(0)}m`
		: '0m';

	const averageScoreInChapter =
		chapterStats.avg_score != null ? (
			(chapterStats.avg_score * 100).toFixed(0) + '%'
		) : (
			<span>&mdash;</span>
		);

	return (
		<div>
			<div className="header">
				<h1
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: chapter.chapter_name }}
				/>
			</div>
			<div className="content">
				<h2>Summary</h2>
				<p>
					<span className="label">Words:</span>
					<span>{chapter.word_count}</span>
				</p>
				<p>
					<QuestionCountStats chapter={chapter} />
				</p>
				<p>
					<span className="label">Average Time in Chapter:</span>
					<span>
						<i className="ss-clock" /> {averageTimeInChapterInWords}
					</span>
				</p>
				<p>
					<span className="label">Average Score in Chapter:</span>
					<span>{averageScoreInChapter}</span>
				</p>
			</div>
			<div className="footer">
				<p className="note">
					<i className="ss-clock" /> Total time spent working on pages: clicking, typing, scrolling.
				</p>
			</div>
		</div>
	);
};

ChapterHeaderCellHover.propTypes = {
	chapter: PropTypes.object,
	chapterStats: PropTypes.object
};

export default connect((state, props) => {
	const chapter = state.entities.chapters[props.chapterId];

	const chapterStats = {
		avg_web_time_spent: state.entities.courseTimingLoaded
			? state.entities.courseAverageTimings[chapter.id] || 0
			: null,
		avg_score: state.entities.courseAverageScores[chapter.id]
	};

	return {
		chapter,
		chapterStats
	};
})(ChapterHeaderCellHover);
