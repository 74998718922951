import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuestionCountStats from './QuestionCountStats';

const PageHeaderCellHover = ({
	page,
	pageStats,
	lowCutoffTime,
	highCutoffTime,
	policyDocument
}) => {
	const averageTimeOnPageInWords = pageStats.avg_web_time_spent
		? `${Math.ceil(pageStats.avg_web_time_spent / 60).toFixed(0)}m`
		: '0m';

	const averageScoreOnPage =
		pageStats.avg_score != null ? (
			(pageStats.avg_score * 100).toFixed(0) + '%'
		) : (
			<span>&mdash;</span>
		);

	const lowCutoffTimeInWords =
		lowCutoffTime != null
			? lowCutoffTime < 60
				? `${lowCutoffTime.toFixed(0)}s`
				: `${Math.ceil(lowCutoffTime / 60).toFixed(0)}m`
			: null;

	const highCutoffTimeInWords =
		highCutoffTime != null
			? highCutoffTime < 60
				? `${highCutoffTime.toFixed(0)}s`
				: `${Math.ceil(highCutoffTime / 60).toFixed(0)}m`
			: null;

	let lowScoreCutoff;
	if (__DEV__) {
		let policy = policyDocument.policies[policyDocument.instances[page.id]];
		policy || (policy = policyDocument.policies[policyDocument.defaults['NG::Traditional::Page']]);
		lowScoreCutoff = policy.attempts_allowed > 0 ? '70%' : '90%';
	}

	let page_title = page.page_name;
	if (page.page_nav_label) page_title = `<em>${page.page_nav_label}</em>: ${page_title}`;

	return (
		<div>
			<div className="header">
				<h1
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: page_title }}
				/>
			</div>
			<div className="content">
				<h2>Summary</h2>
				<p>
					<span className="label">Words:</span>
					<span>{page.word_count}</span>
					{false && (
						<span>
							<span className="label not-first-label">Video Length:</span>
							<span>{page.video_duration}</span>
						</span>
					)}
				</p>
				<p>
					<QuestionCountStats page={page} />
				</p>
				<p>
					<span className="label">Average Time on Page:</span>
					<span>
						<i className="ss-clock" /> {averageTimeOnPageInWords}
					</span>
				</p>
				<p>
					<span className="label">Average Score on Page:</span>
					<span>{averageScoreOnPage}</span>
				</p>
				{__DEV__ && lowCutoffTimeInWords && (
					<p>
						<span className="label">Low Cutoff Time:</span>
						<span>{lowCutoffTimeInWords}</span>
					</p>
				)}
				{__DEV__ && highCutoffTimeInWords && (
					<p>
						<span className="label">High Cutoff Time:</span>
						<span>{highCutoffTimeInWords}</span>
					</p>
				)}
				{__DEV__ && lowScoreCutoff && (
					<p>
						<span className="label">Low Score Cutoff:</span>
						<span>{lowScoreCutoff}</span>
					</p>
				)}
				{/*TODO Revise if we still need it*/}
				{false && (
					<p>
						<span className="label">{`Average Time of "A" Student:`}</span>
						<span>
							<i className="ss-clock" /> &mdash;
						</span>
					</p>
				)}
			</div>
			<div className="footer">
				<p className="note">
					<i className="ss-clock" /> Total time spent working on pages: clicking, typing, scrolling.
				</p>
			</div>
		</div>
	);
};

PageHeaderCellHover.propTypes = {
	page: PropTypes.object,
	pageStats: PropTypes.object,
	lowCutoffTime: PropTypes.number,
	highCutoffTime: PropTypes.number,
	policyDocument: PropTypes.object
};

export default connect((state, props) => {
	const page = state.entities.pages[props.pageId];

	const pageStats = {
		word_count: page.word_count,
		video_duration: page.video_duration,
		question_counts: page.question_counts,
		avg_web_time_spent: state.entities.courseTimingLoaded
			? state.entities.courseAverageTimings[page.id] || 0
			: null,
		avg_score: state.entities.courseAverageScores[page.id]
	};

	return {
		page: page,
		pageStats: pageStats,
		lowCutoffTime: state.entities.courseLowTimingCutoffs[props.pageId],
		highCutoffTime: state.entities.courseHighTimingCutoffs[props.pageId],
		policyDocument: state.entities.course.policy_document
	};
})(PageHeaderCellHover);
