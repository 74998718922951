// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './Decision.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import pageViewStyles from '../PageView.scss';

import React, { Component } from 'react';
import { object, bool } from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { DecisionPoint } from '@soomo/lib/components/pageElements';
import themes from '@soomo/lib/styles/themes';
import ThemeProvider from '@soomo/lib/styles/themes/ThemeProvider';

class Decision extends Component {
	render() {
		const { toc, decision, user, inContext } = this.props;
		const decisionConfig = toc.config.course_decisions[decision.name];

		const userDecision = user ? user.decisions[decision.name] : null;
		const lastSavedAt = null; // TODO: Get last saved info

		if (inContext) {
			return (
				<ThemeProvider theme={themes[toc.theme]}>
					<DecisionPoint
						decision={{
							...decision,
							options: decisionConfig.options
						}}
						api={{
							load: () => Promise.resolve({ userDecision, lastSavedAt }),
							save: () => Promise.resolve(),
							reset: () => Promise.resolve()
						}}
						readOnly={true}
						key={decision.key + '-' + user.id}
					/>
				</ThemeProvider>
			);
		} else {
			const userDecisionOption = userDecision
				? decisionConfig.options.find((option) => option.key === userDecision)
				: null;

			return (
				<div styleName="styles.DecisionPoint">
					{userDecisionOption && (
						<div styleName={cn('pageViewStyles.Symbol', 'pageViewStyles.IsCompleteSymbol')} />
					)}
					<div styleName="styles.ActivitySummary">
						<span styleName="pageViewStyles.QuestionType">Decision Point</span>
					</div>
					<h2
						styleName="styles.DecisionPrompt"
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{ __html: decision.prompt }}
					/>
					{userDecisionOption && <p styleName="styles.UserDecision">{userDecisionOption.name}</p>}
				</div>
			);
		}
	}
}

Decision.propTypes = {
	toc: object.isRequired,
	decision: object.isRequired,
	user: object,
	inContext: bool.isRequired
};

const mapStateToProps = (state) => ({
	toc: state.entities.toc
});

export default connect(mapStateToProps)(Decision);
