import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatDuration } from 'Utils/format';
import TippyRollover from '../TippyRollover';
import styles from '../GridView.scss';

class CohortTimingCell extends React.PureComponent {
	static propTypes = {
		scope: PropTypes.object.isRequired,
		scopeTypes: PropTypes.object.isRequired,
		cohortTimingLoaded: PropTypes.bool.isRequired,
		cohortAverageTimings: PropTypes.object.isRequired,
		onlyMinutes: PropTypes.bool,
		isScrolling: PropTypes.bool
	};

	render() {
		const {
			cohortAverageTimings,
			cohortTimingLoaded,
			scope,
			scopeTypes,
			onlyMinutes,
			isScrolling
		} = this.props;

		let explanation;

		const scopeType = scopeTypes[scope.id];
		const totalTimeSpent = cohortTimingLoaded ? cohortAverageTimings[scope.id] || 0 : null;

		if (scopeType === 'toc') {
			explanation =
				'Average active time for all students in all sections who have some active time in the webtext.';
		} else if (scopeType === 'chapter') {
			explanation =
				'Average active time in this chapter for all students in all sections who visited the chapter.';
		} else if (scopeType === 'pageGroup') {
			explanation =
				'Average active time in this group of pages for all students in all sections who visited at least one page in the group.';
		} else if (scopeType === 'page') {
			explanation =
				'Average active time on this page for all students in all sections who visited the page.';
		}

		const timeSpentInWords = formatDuration({ duration: totalTimeSpent, onlyMinutes });

		const style = {
			display: 'inline-block',
			padding: '0 8px'
		};

		return (
			<TippyRollover
				content={
					isScrolling ? null : <span className={styles.CellHoverExplanation}>{explanation}</span>
				}>
				<span style={style}>{timeSpentInWords}</span>
			</TippyRollover>
		);
	}
}

const mapStateToProps = (state) => ({
	cohortAverageTimings: state.entities.cohortAverageTimings,
	cohortTimingLoaded: state.entities.cohortTimingLoaded,
	scopeTypes: state.entities.scopeTypes
});

export default connect(mapStateToProps)(CohortTimingCell);
