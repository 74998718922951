import React from 'react';

class NotScorableIcon extends React.PureComponent {
	render() {
		return (
			<i
				className="ss-icon ss-ban"
				style={{
					position: 'relative',
					top: '2px',
					opacity: 0.08,
					fontSize: '16px',
					lineHeight: '16px',
					fontWeight: 100
				}}
			/>
		);
	}
}

export default NotScorableIcon;
