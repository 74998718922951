import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CompletionCell from 'Components/GridCells/CompletionCell';
import RequiresActionCell from 'Components/GridCells/RequiresActionCell';
import { metricsQueryConfig } from 'Requests/MetricsQuery';
import { compose } from 'redux';
import { connectRequest } from 'redux-query-react';
import { pick } from 'lodash-es';

const PageCompletionCell = ({ data, loaded }) => {
	if (data?.requires_action) return <RequiresActionCell />;

	return (
		<CompletionCell
			completion={data?.completion}
			fullyCompleted={data?.fullyCompleted}
			opened={data?.opened}
			loaded={loaded}
		/>
	);
};

PageCompletionCell.propTypes = {
	user: PropTypes.object.isRequired,
	page: PropTypes.object.isRequired,
	chapter: PropTypes.object,
	data: PropTypes.object,
	loaded: PropTypes.bool,
	metricsConfig: PropTypes.object
};

const mapStateToProps = (state, props) => {
	const userIds = props.fetchRowGroupUserIds({ rowGroup: props.rowGroup });
	const metricsConfig = metricsQueryConfig({
		scopeId: props.chapter.id,
		groupBy: 'page',
		courseId: state.data.course.id,
		userIds: userIds,
		host: `https://${state.data.api.host}`,
		jwt: state.data.api.jwt
	});
	const bodyAndUrl = pick(metricsConfig, 'body', 'url');
	metricsConfig.queryKey = JSON.stringify({ body: bodyAndUrl.body, url: bodyAndUrl.url });

	return {
		metricsConfig,
		loaded: state.queries[metricsConfig.queryKey]?.isFinished ?? true,
		data: state.entities.metrics[`${props.user.id}:${props.page.id}`]
	};
};

const mapPropsToConfig = (props) => {
	return props.metricsConfig;
};

export default compose(
	connect(mapStateToProps),
	connectRequest(mapPropsToConfig)
)(PageCompletionCell);
