import styles from './Timeline.scss';

import React from 'react';
import { Timeline as TimelineFromLibs } from '@soomo/lib/components/pageElements';

const Timeline = ({ element }) => (
	<div className={styles.Timeline}>
		<TimelineFromLibs element={element} />
	</div>
);

export default Timeline;
