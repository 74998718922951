import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import trackingLibraries from 'Services/trackingLibraries';
import websocket from 'Services/webSocket';
import requiredEntities from 'Services/requiredApplicationEntities';
import ModalDialog from 'Components/ModalDialog';
import Header from '../Header';
import Layout from '../Layout';
import ScreenLoading from 'Components/ScreenLoading';
import SelectorPanel from '../SelectorPanel';
import ViewFrame from '../ViewFrame';

import './NotebookApp.scss';

interface NotebookAppProps {
	userName: string;
	courseId?: string;
	signedOut: boolean;
	requiredEntitiesAvailable: boolean;
	unrecoverableError: boolean;
	welcomeAcknowledged: boolean;
}

const NotebookApp: FC<NotebookAppProps> = ({
	userName,
	courseId,
	signedOut,
	requiredEntitiesAvailable,
	unrecoverableError,
	welcomeAcknowledged
}) => {
	useEffect(() => {
		websocket.connect();
	}, []);

	useEffect(() => {
		trackingLibraries.initializeFullstory();
		trackingLibraries.initializeIntercom();
		trackingLibraries.initializeRollbar();
	}, []);

	useEffect(() => {
		requiredEntities.load();
	}, []);

	if (signedOut) {
		return (
			<ModalDialog open={true}>
				You are signed out. Please return to your webtext and re-click the Analytics link.
			</ModalDialog>
		);
	}

	if (!courseId) {
		return (
			<ModalDialog open={true}>
				You didn&apos;t select the course you want to view. Please return to your webtext and
				re-click the Analytics link.
			</ModalDialog>
		);
	}

	if (unrecoverableError) {
		return (
			<ModalDialog open={true}>
				There was an error with your request. Please go back to your webtext and re-click the
				Analytics link.
			</ModalDialog>
		);
	}

	if (welcomeAcknowledged && !requiredEntitiesAvailable) {
		return <ScreenLoading />;
	}

	return (
		<div styleName="NotebookApp">
			<Header />
			<Layout>
				{welcomeAcknowledged && <SelectorPanel />}
				<ViewFrame />
			</Layout>
		</div>
	);
};

const getUserName = (state: RootState) => {
	const users = Object.values(state.entities.users) as { first_name: string; last_name: string }[];
	if (users?.length > 0) {
		const firstUser = users[0];
		return `${firstUser.first_name} ${firstUser.last_name}`;
	}
	return null;
};

const mapStateToProps = (state: RootState) => ({
	userName: getUserName(state),
	courseId: state.data.course.id,
	signedOut: !state.data.api.jwt,
	requiredEntitiesAvailable: state.data.requiredEntitiesAvailable,
	unrecoverableError: state.data.unrecoverableError,
	welcomeAcknowledged:
		state.ui.currentViewParams.welcomed || Object.keys(state.ui.initialView).length > 0
});

export default connect(mapStateToProps)(NotebookApp);
