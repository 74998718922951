import React from 'react';
import { connect } from 'react-redux';

import { ChartFigure as ChartFigureFromLibs } from '@soomo/lib/components/pageElements';
import type { ChartElement } from '@soomo/lib/types/WebtextManifest';

import { ElementActivity } from 'Types/index';

interface Props {
	chart: ChartElement;
	activity: ElementActivity;
	tabsUi: boolean;
}

const ChartFigure: React.FC<Props> = (props) => {
	const { chart, tabsUi } = props;

	return <ChartFigureFromLibs chart={{ ...chart, tabsUi }} monochromeCharts={false} />;
};

const mapStateToProps = (state) => {
	const page = state.entities.pages[state.ui.currentViewParams.scope];

	return {
		tabsUi:
			state.entities.toc.config.pages.types[page.page_type].elements['Chart']?.settings?.tabs_ui ||
			false
	};
};

export default connect(mapStateToProps)(ChartFigure);
