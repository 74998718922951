import * as types from './actionTypes.js';

export const setStudentsFilters = ({ filters }) => ({
	type: types.SET_STUDENTS_FILTERS,
	payload: { filters }
});

export const setStudentsFilteringEnabled = (enabled) => ({
	type: types.SET_STUDENTS_FILTERING_ENABLED,
	payload: { enabled }
});
