import 'c3/c3.css';
import React, { Component } from 'react';
import { object, number } from 'prop-types';
import * as d3 from 'd3-shape';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { color } from 'd3-color';
import c3 from 'c3';

class PollResults extends Component {
	static propTypes = {
		poll: object.isRequired,
		results: object.isRequired,
		width: number.isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			totalResponseCount: 0,
			columns: []
		};
	}

	UNSAFE_componentWillMount() {
		this.updateData(this.props);
	}

	componentDidMount() {
		this.updateChart();
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		this.updateData(newProps);
	}

	componentDidUpdate() {
		this.updateChart();
	}

	componentWillUnmount() {
		if (this.chart) this.chart.destroy();
	}

	updateData = (props) => {
		const { poll, results } = props;

		let totalResponseCount = 0;
		const columns = poll.choices.map(function (choice) {
			totalResponseCount = totalResponseCount + results[choice.body];

			let choiceBodyLabel = choice.body;
			if (choiceBodyLabel.length > 60) {
				choiceBodyLabel = choiceBodyLabel.slice(0, 60) + '...';
			}

			return [choiceBodyLabel, results[choice.body]];
		});

		this.setState({
			columns,
			totalResponseCount
		});
	};

	updateChart = () => {
		const { columns } = this.state;
		const { width } = this.props;

		if (this.chart) this.chart.destroy();

		// chartElement is not available, which suggests
		// component is no yet or no longer mounted.
		if (!this.chartElement) return;

		this.chart = c3.generate({
			bindto: this.chartElement,
			data: {
				columns: columns,
				type: 'pie'
			},
			pie: {
				expand: false
			},
			size: {
				height: 200,
				width: width || 700
			},
			legend: {
				position: 'right'
			}
		});
	};

	render() {
		const { columns, totalResponseCount } = this.state;

		const data = columns.map((col) => col[1]);

		const outerRadius = 80;
		const colors = schemeCategory10;
		const opacity = 0.7;
		const strokeWidth = data.filter((number) => number > 0).length > 1 ? 1 : 0;

		const arc = d3.arc();
		const arcs = d3
			.pie()(data)
			.map((arcData, idx) => (
				<path
					key={idx}
					d={arc({ ...arcData, innerRadius: 0, outerRadius: outerRadius })}
					fill={colors[idx % 10]}
					stroke="white"
					strokeWidth={strokeWidth}
					fillOpacity={opacity}
					transform={`translate(${outerRadius + 1},${outerRadius + 1})`}
				/>
			));

		const labelFontSize = 13;

		const labels = d3
			.pie()(data)
			.map((arcData, idx) => {
				const count = data[idx];
				const pct = Math.round((count / totalResponseCount) * 100.0);
				if (pct < 5) return null;

				const labelText = `${pct}%`;

				const pos = arc.centroid({
					startAngle: arcData.startAngle,
					endAngle: arcData.endAngle,
					outerRadius: outerRadius,
					innerRadius: outerRadius / 2.2
				});
				const x = pos[0] + outerRadius + 1;
				const y = pos[1] + outerRadius + 1 + labelFontSize / 3;

				return (
					<text key={idx} x={x} y={y} textAnchor="middle" fontSize={labelFontSize} fill="#333">
						{labelText}
					</text>
				);
			});

		const width = outerRadius * 2 + 2;
		const height = width;

		return (
			<div style={{ display: 'flex', paddingTop: '10px' }}>
				<div style={{ flex: 'none' }}>
					<svg width={width} height={height}>
						{arcs}
						{labels}
					</svg>
				</div>
				<div style={{ flexGrow: 1, padding: '10px 60px' }}>
					{this.state.columns.map((col, idx) => (
						<p
							key={idx}
							style={{
								position: 'relative',
								fontSize: '0.8em',
								lineHeight: '1.1em',
								padding: '5px',
								margin: 0
							}}>
							<span
								style={{
									position: 'absolute',
									top: '7px',
									background: colors[idx % 10],
									opacity,
									border: `1px solid ${color(colors[idx % 10]).darker()}`,
									width: '0.8em',
									height: '0.8em',
									display: 'inline-block'
								}}
							/>
							<span style={{ display: 'inline-block', margin: '0 25px 0 20px' }}>{col[0]}</span>
							<span
								style={{
									position: 'absolute',
									right: '5px',
									textAlign: 'right'
								}}>
								{data[idx]}
							</span>
						</p>
					))}
					<p
						style={{
							marginTop: '10px',
							borderTop: '1px solid #333',
							paddingTop: '10px',
							fontSize: '0.8em',
							paddingRight: '5px',
							textAlign: 'right'
						}}>
						Total responses: {totalResponseCount}
					</p>
				</div>
			</div>
		);
	}
}

export default PollResults;
