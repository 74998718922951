import jQuery from 'jquery';

const CITE_REGEXP = /#cite/i;

export default function (builder, selections) {
	const markedCitations = {};

	builder.config.prompts.forEach(function (prompt) {
		prompt.items.forEach(function (item) {
			if (!item.citationsEnabled) return;

			const selection = selections[item.dest];
			if (!selection) return;

			const value = selection.text;
			if (!value) return;

			const prevCitations = selection.citations || [];
			const nextCitations = [];

			if (item.redactorEnabled) {
				const root = jQuery.parseHTML('<div>' + value + '</div>')[0];
				const markerNodeIds = Array.from(root.querySelectorAll('mark')).map((node) => node.id);
				if (markerNodeIds.length === 0) return;

				const citationsMap = {};
				prevCitations.forEach(function (citation) {
					citationsMap[citation.identifer || citation.identifier] = citation;
				});

				markerNodeIds.forEach(function (markerNodeId, index) {
					const citation = citationsMap[markerNodeId];
					nextCitations.push(citation);
					markedCitations[markerNodeId] = citation;

					if (builder.citationStyle === 'turabian') {
						const ref = document.getElementById(markerNodeId);
						if (ref) {
							ref.setAttribute('data-after', index + 1);
						} else if (window.Rollbar) {
							window.Rollbar.error('Unable to find citation ref', { id: markerNodeId });
						}
					}
				});
			} else {
				const splits = value.split(CITE_REGEXP);

				if (splits.length === 1) return;

				splits.forEach(function (value, index) {
					if (index < splits.length - 1) {
						if (value === '') {
							if (index === 0) {
								const citation = { context: splits[index + 1], index };
								if (prevCitations[index]) {
									citation.key = prevCitations[index].key;
									citation.attributes = prevCitations[index].attributes;
								}
								nextCitations.push(citation);
							}
						} else {
							const citation = { context: value, index };
							if (prevCitations[index]) {
								citation.key = prevCitations[index].key;
								citation.attributes = prevCitations[index].attributes;
							}
							nextCitations.push(citation);
						}
					}
				});
			}

			if (nextCitations.length) {
				selection.citations = nextCitations;
			} else {
				delete selection.citations;
			}
		});
	});

	return {
		markedCitations
	};
}
