import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import saveWebtextCommentMutation from 'Requests/SaveWebtextCommentMutation';
import unpostAnswerMutation from 'Requests/UnpostAnswerMutation';
import elementsActivityQuery from 'Requests/ElementsActivityQuery';

import AnswerActions from 'Components/AnswerActions';
import ConfirmDialog from 'Components/ConfirmDialog';

class AnswerActionsWithState extends Component {
	static propTypes = {
		user: PropTypes.object,
		question: PropTypes.object,
		answer: PropTypes.object,
		commentKey: PropTypes.string,
		comment: PropTypes.object,
		posting: PropTypes.string,
		hideCommentButton: PropTypes.bool,
		onEditComment: PropTypes.func,
		onUnpostAnswer: PropTypes.func,
		onViewAllResponses: PropTypes.func,
		historyButton: PropTypes.node,
		commentRequiresPosting: PropTypes.bool,
		commentWithoutAnswer: PropTypes.bool,
		commentLocked: PropTypes.bool,

		// mapStateToProps
		currentUser: PropTypes.object,
		course: PropTypes.object,
		page: PropTypes.object,
		timeZone: PropTypes.string,

		// mapDispatchToProps
		saveWebtextComment: PropTypes.func,
		unpostAnswer: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.state = {
			confirmUnpostDialogVisible: false
		};
	}

	onSaveComment = (commentBody) => {
		const { currentUser, course, page, user, question, saveWebtextComment } = this.props;

		return saveWebtextComment({
			course,
			page,
			recipient: user,
			thing: question,
			commentBody,
			commentAuthorName: currentUser.name
		});
	};

	onUnpostAnswer = () => {
		this.setState({ confirmUnpostDialogVisible: true });
		return false;
	};

	confirmUnpostAnswerClickHandler = () => {
		const { course, answer, unpostAnswer, onUnpostAnswer, currentUser } = this.props;
		return unpostAnswer({ course, answer, user: currentUser }).then(() => {
			this.hideConfirmUnpostDialog();
			onUnpostAnswer();
		});
	};

	hideConfirmUnpostDialog = () => {
		this.setState({ confirmUnpostDialogVisible: false });
	};

	render() {
		const {
			answer,
			comment,
			posting,
			hideCommentButton,
			onEditComment,
			onUnpostAnswer,
			onViewAllResponses,
			historyButton,
			commentRequiresPosting,
			commentWithoutAnswer,
			commentLocked,
			timeZone,
			commentKey
		} = this.props;

		return (
			<>
				{onUnpostAnswer && (
					<ConfirmDialog
						open={this.state.confirmUnpostDialogVisible}
						onConfirm={this.confirmUnpostAnswerClickHandler}
						onClose={this.hideConfirmUnpostDialog}
						title="Unpost Student Response?"
						dataTest="unpost-student-response-dialog">
						<div>
							Unposting will revert the student’s response to a draft state and remove any
							associated completion points.
						</div>
						<p>
							Be sure to leave a comment for the student so they can post an updated response and
							restore any associated points.
						</p>
					</ConfirmDialog>
				)}
				<AnswerActions
					answer={answer}
					comment={comment}
					commentKey={commentKey}
					posting={posting}
					onEditComment={onEditComment}
					onSaveComment={hideCommentButton ? null : this.onSaveComment}
					onUnpostAnswer={onUnpostAnswer ? this.onUnpostAnswer : null}
					onViewAllResponses={onViewAllResponses}
					historyButton={historyButton}
					commentRequiresPosting={commentRequiresPosting !== false}
					commentWithoutAnswer={commentWithoutAnswer}
					commentLocked={commentLocked}
					timeZone={timeZone}
				/>
			</>
		);
	}
}

const mapStateToProps = (state, props) => ({
	currentUser: state.entities.currentUser,
	course: state.entities.course,
	page: state.entities.pages[props.question.page_id],
	timeZone: state.entities.course.time_zone
});

const mapDispatchToProps = (dispatch) => ({
	saveWebtextComment: ({ course, page, recipient, thing, commentBody, commentAuthorName }) =>
		dispatch(
			saveWebtextCommentMutation({
				courseId: course.id,
				recipientId: recipient.id,
				thingFamilyId: thing.id,
				rawCommentBody: commentBody,
				commentAuthorName
			})
		).then(() => {
			// Updates answer history, which includes comment updates
			dispatch(
				elementsActivityQuery({
					courseId: course.id,
					userId: recipient.id,
					pageId: page.id,
					elementIds: [thing.id],
					force: true
				})
			);
		}),
	unpostAnswer: ({ course, answer, user }) =>
		dispatch(unpostAnswerMutation({ courseId: course.id, answerId: answer.id, userId: user.id }))
});

export default connect(mapStateToProps, mapDispatchToProps)(AnswerActionsWithState);
