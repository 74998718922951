import defaultBackground from '../../assets/images/welcome_bg.jpg';
import styles from './MessageView.scss';

import React, { Component } from 'react';
import { object, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as uiActions from '../../actions/uiActions';
import LinearProgress from '@mui/material/LinearProgress';

const isIE = window.navigator.userAgent.indexOf('Trident') > -1;

class MessageView extends Component {
	componentDidMount() {
		this.props.uiActions.updateCurrentViewParams({});
	}

	onBegin = () => {
		this.props.uiActions.updateCurrentViewParams({
			welcomed: true
		});
	};

	render() {
		const { requiredEntitiesAvailable, welcomeAcknowledged, courseImageUrl } = this.props;

		const backgroundStyle = {
			background: `url('${courseImageUrl || defaultBackground}') no-repeat center center fixed`,
			backgroundSize: 'cover',
			filter: 'blur(15px) brightness(0.5)'
		};
		if (isIE) backgroundStyle.opacity = '0.5';

		return (
			<div styleName="Container">
				<div styleName="Background" style={backgroundStyle} />
				{welcomeAcknowledged === false && (
					<div styleName="WelcomeContainer">
						<h1>Welcome to Analytics</h1>
						<h2>Three ways you can help more students succeed:</h2>
						<ol>
							<li>
								<span>
									1. Remind students who are missing from the roster to access their webtext.
								</span>
							</li>
							<li>
								<span>
									2. Offer encouragement or guidance to students with low completion or low scores.
								</span>
							</li>
							<li>
								<span>
									3. Review student responses and provide some feedback&mdash;they want to hear from
									you!
								</span>
							</li>
						</ol>
						<div styleName="Begin">
							{!requiredEntitiesAvailable && (
								<div styleName="ProgressContainer">
									<LinearProgress
										classes={{ bar: styles.ProgressBar }}
										style={{ background: 'transparent' }}
									/>
								</div>
							)}
							{requiredEntitiesAvailable && (
								<button onClick={this.onBegin} style={{ cursor: 'pointer' }}>
									Begin &rarr;
								</button>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

MessageView.propTypes = {
	requiredEntitiesAvailable: bool.isRequired,
	welcomeAcknowledged: bool.isRequired,
	uiActions: object.isRequired,
	courseImageUrl: string
};

const mapStateToProps = (state) => ({
	courseImageUrl: state.data.course.imageUrl,
	requiredEntitiesAvailable: state.data.requiredEntitiesAvailable,
	welcomeAcknowledged:
		state.ui.currentViewParams.welcomed || Object.keys(state.ui.initialView).length > 0
});

const mapDispatchToProps = (dispatch) => ({
	uiActions: bindActionCreators(uiActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageView);
