import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { formatDuration } from 'Utils/format';
import timingStyles from './TimingCell.scss';
import LoadingShimmer from '../../containers/Views/AggregatedElements/LoadingShimmer';

class TimingCell extends React.PureComponent {
	static propTypes = {
		timeSpent: PropTypes.number,
		lowTime: PropTypes.bool,
		highTime: PropTypes.bool,
		onlyMinutes: PropTypes.bool,
		minuteLabel: PropTypes.string,
		loaded: PropTypes.bool
	};

	render() {
		const { timeSpent, lowTime, highTime, onlyMinutes, minuteLabel, loaded } = this.props;
		if (!loaded) return <LoadingShimmer />;

		const styleNames = cx(
			timingStyles.Contents,
			highTime && timingStyles.Hightime,
			lowTime && timingStyles.Lowtime
		);

		const timeSpentInWords = timeSpent
			? formatDuration({ duration: timeSpent, onlyMinutes, noSeconds: true, minuteLabel })
			: null;

		return <div className={styleNames}>{timeSpentInWords}</div>;
	}
}

export default TimingCell;
