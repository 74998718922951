import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { bindActionCreators } from 'redux';
import uniq from 'lodash.uniq';

import * as uiActions from 'Actions/uiActions';
import RaisedButton from 'Components/RaisedButton';
import { getOverallClassPageActivityQuery } from 'Requests/OverallClassPageActivityQuery';
import { Course, Page } from 'Types/index';
import { isEmpty } from 'Utils/objects';

import * as AggregatedElements from './AggregatedElements';
import DecisionChoiceBlock from './AggregatedElements/DecisionChoiceBlock';
import WebtextContentView from './WebtextContentView';

import { interactiveElementsTypes } from '../../constants';

import styles from './PageView.scss';

interface OverallClassElementsActivity {
	elements: Record<string, any>;
	totalStudentsCount: number;
}

interface Props {
	toc: any;
	course: Course;
	gridViewScope: string;
	page?: Page;
	elements: Array<any>;
	overallClassElementsActivity: OverallClassElementsActivity;
	dispatch: any;
	uiActions: any;
}

const AggregatedPageView: React.FC<Props> = (props) => {
	const { toc, course, gridViewScope, page, elements, overallClassElementsActivity, uiActions } =
		props;

	const pageDependencies = uniq([
		...(page?.internal_dependencies ?? []),
		...(page?.external_dependencies ?? [])
	]);
	const isDecisionDependent = pageDependencies.length !== 0;

	const courseDecision = pageDependencies[0];
	const isAcademicIntegrity = courseDecision?.startsWith('academic_integrity');
	const options = courseDecision && toc.config.course_decisions?.[courseDecision]?.options;

	const [selectedDecisionChoice, setSelectedDecisionChoice] = useState('');

	useEffect(() => {
		const defaultOption = sessionStorage.getItem(courseDecision);
		setSelectedDecisionChoice(defaultOption || '');
	}, [courseDecision]);

	const currentElements = useMemo(() => {
		// checking whether the options include the selectedDecisionChoice for the edge case
		// when selectedDecisionChoice isn't updated yet after a page switch
		if (selectedDecisionChoice && options?.map(({ key }) => key).includes(selectedDecisionChoice)) {
			return elements.map((element) => {
				const updatedElement = { ...element };
				if (!element.dictionary) return updatedElement;

				Object.keys(element.dictionary).forEach((key) => {
					updatedElement[key] = element.dictionary[key][selectedDecisionChoice];
				});

				if (element.choices) {
					const updatedChoices = element.choices.map((choice) => {
						const updatedChoice = { ...choice };
						Object.keys(choice.dictionary).forEach((key) => {
							updatedChoice[key] = choice.dictionary[key][selectedDecisionChoice];
						});
						return updatedChoice;
					});

					updatedElement.choices = updatedChoices;
				}

				if (element.type === 'interactive_template') {
					updatedElement['config'] = JSON.parse(updatedElement['config']);
				}

				return updatedElement;
			});
		}

		return elements;
	}, [elements, selectedDecisionChoice, options]);

	const overallClassPageActivityRequest = useMemo(
		() =>
			page &&
			(!isDecisionDependent ||
				isAcademicIntegrity ||
				(isDecisionDependent && selectedDecisionChoice))
				? getOverallClassPageActivityQuery({
						courseId: course.id,
						pageId: page.id,
						force: true,
						...(selectedDecisionChoice && { decisionChoice: selectedDecisionChoice })
				  })
				: null,
		[course.id, page, selectedDecisionChoice, isDecisionDependent, isAcademicIntegrity]
	);

	const [{ isFinished: isPageActivityLoaded }] = useRequest(overallClassPageActivityRequest);

	const backToGridView = () => {
		uiActions.updateCurrentViewParams({
			scope: gridViewScope,
			student: null
		});
	};

	const onDecisionChoiceChange = (value: string) => {
		sessionStorage.setItem(courseDecision, value);
		setSelectedDecisionChoice(value);
	};

	return (
		<div style={{ width: '100%', background: 'white', display: 'flex', flexFlow: 'column nowrap' }}>
			<div className={styles.Buttons}>
				<RaisedButton label="Back to Overview" onClick={backToGridView} />
			</div>

			{!currentElements.length && (
				<h2 className={styles.NoInteractiveContent}>This page contains no interactive content.</h2>
			)}

			<div style={{ overflowY: 'auto' }}>
				{isDecisionDependent && !isAcademicIntegrity && (
					<DecisionChoiceBlock
						courseDecision={courseDecision}
						selectedDecisionChoice={selectedDecisionChoice}
						options={options}
						onChange={onDecisionChoiceChange}
					/>
				)}

				{(!isDecisionDependent ||
					isAcademicIntegrity ||
					(isDecisionDependent && selectedDecisionChoice)) && (
					<WebtextContentView theme={toc.theme}>
						{currentElements.map((element) => {
							let content;

							switch (element.type) {
								case 'mc_question':
									content = (
										<AggregatedElements.MCQuestion
											element={element}
											isPageActivityLoaded={isPageActivityLoaded}
											aggregatedElement={overallClassElementsActivity.elements?.[element.family_id]}
											totalStudentsCount={overallClassElementsActivity.totalStudentsCount}
										/>
									);
									break;

								case 'response_board':
									content = (
										<AggregatedElements.ResponseBoard
											element={element}
											isPageActivityLoaded={isPageActivityLoaded}
											aggregatedElement={overallClassElementsActivity.elements?.[element.family_id]}
											totalStudentsCount={overallClassElementsActivity.totalStudentsCount}
											page={page}
											uiActions={uiActions}
										/>
									);
									break;

								case 'sa_question':
									content = (
										<AggregatedElements.SAQuestion
											element={element}
											isPageActivityLoaded={isPageActivityLoaded}
											aggregatedElement={overallClassElementsActivity.elements?.[element.family_id]}
											totalStudentsCount={overallClassElementsActivity.totalStudentsCount}
											page={page}
											uiActions={uiActions}
										/>
									);
									break;

								case 'poll_question':
									content = (
										<AggregatedElements.PollQuestion
											element={element}
											isPageActivityLoaded={isPageActivityLoaded}
											aggregatedElement={overallClassElementsActivity.elements?.[element.family_id]}
											totalStudentsCount={overallClassElementsActivity.totalStudentsCount}
											page={page}
											uiActions={uiActions}
										/>
									);
									break;

								case 'mc_question_pool':
									content = (
										<AggregatedElements.MCQuestionPool
											element={element}
											isPageActivityLoaded={isPageActivityLoaded}
											aggregatedElement={overallClassElementsActivity.elements?.[element.family_id]}
											totalStudentsCount={overallClassElementsActivity.totalStudentsCount}
										/>
									);
									break;

								case 'interactive_template': // aka Builder, aka WritingTemplate
									content = (
										<AggregatedElements.InteractiveTemplate
											element={element}
											isPageActivityLoaded={isPageActivityLoaded}
											aggregatedElement={overallClassElementsActivity.elements?.[element.family_id]}
											totalStudentsCount={overallClassElementsActivity.totalStudentsCount}
											toc={toc}
											page={page}
										/>
									);
									break;

								case 'self_assessment': // aka PopupQuiz
									content = (
										<AggregatedElements.SelfAssessment
											element={element}
											isPageActivityLoaded={isPageActivityLoaded}
											aggregatedElement={overallClassElementsActivity.elements?.[element.family_id]}
											totalStudentsCount={overallClassElementsActivity.totalStudentsCount}
										/>
									);
									break;

								case 'external_tool':
									content =
										element?.score_config?.scoreOptions === 'noPoints' ? null : (
											<AggregatedElements.ExternalTool
												element={element}
												isPageActivityLoaded={isPageActivityLoaded}
												aggregatedElement={
													overallClassElementsActivity.elements?.[element.family_id]
												}
												totalStudentsCount={overallClassElementsActivity.totalStudentsCount}
												page={page}
												uiActions={uiActions}
											/>
										);
									break;

								case 'voice_recording':
									content = (
										<AggregatedElements.VoiceRecording
											element={element}
											isPageActivityLoaded={isPageActivityLoaded}
											aggregatedElement={overallClassElementsActivity.elements?.[element.family_id]}
											totalStudentsCount={overallClassElementsActivity.totalStudentsCount}
										/>
									);
									break;

								default:
									break;
							}

							return (
								!isEmpty(content) && (
									<li key={element.id} className={styles.ListItem}>
										{content}
									</li>
								)
							);
						})}
					</WebtextContentView>
				)}
			</div>
		</div>
	);
};

const filterInteractiveElements = (elements) => {
	return elements.filter((el) => {
		if (el.type === 'external_tool') return el?.score_config?.scoreOptions !== 'noPoints';
		return interactiveElementsTypes.includes(el.type);
	});
};

const mapStateToProps = (state) => {
	// NOTE: `scope` may not actually be a page id in certain cases when using the browser's back button;
	// as a result, `page` might be undefined, so we need to handle that possibility. See T-163613
	const { scope: possiblyPageId } = state.ui.currentViewParams;

	const toc = state.entities.toc;
	const course = state.entities.course;
	const gridViewScope = state.ui.currentViewParams.gridViewScope;
	const page = state.entities.pages[possiblyPageId];
	const _elements = page?.id ? page.element_ids.map((id) => state.entities.elements[id]) : [];
	const elements = filterInteractiveElements(_elements);

	const overallClassElementsActivity =
		state.entities.overallClassPageActivity[possiblyPageId] || {};

	return {
		toc,
		course,
		gridViewScope,
		page,
		elements,
		overallClassElementsActivity
	};
};

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uiActions: bindActionCreators(uiActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AggregatedPageView);
