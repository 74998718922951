import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function ModalDialog({ open, onClose, children, actions, title, dataTest, className }) {
	return (
		<Dialog open={open} onClose={onClose} data-test={dataTest} className={className}>
			{title && (
				<DialogTitle id="simple-dialog-title" color="rgb(0,0,0)">
					{title}
				</DialogTitle>
			)}
			<DialogContent>
				<div style={{ color: 'rgba(0,0,0,0.6)', fontSize: '16px' }}>{children}</div>
				<div style={{ textAlign: 'right' }}>{actions}</div>
			</DialogContent>
		</Dialog>
	);
}

ModalDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	children: PropTypes.node,
	actions: PropTypes.array,
	title: PropTypes.string,
	dataTest: PropTypes.string,
	className: PropTypes.string
};

export default ModalDialog;
