import React from 'react';
import { object } from 'prop-types';
import ReadOnlySpreadsheet from '@soomo/lib/components/ReadOnlySpreadsheet';
import Outline from '@soomo/lib/components/Outline';
import { OutlineVariant } from '@soomo/lib/components/Outline/types';
import Charts from '@soomo/lib/components/WritingTemplate/Charts';
import cn from 'classnames';
import styles from '../../containers/Views/Elements/InteractiveTemplate.scss';
import imageStyles from '../WebtextBuilder/UploadedImages.scss';
import InitSelections from '../../containers/Views/Elements/InitSelections';
import { getContextImageUrl } from '../WebtextBuilder/api';
import { WebtextBuilder } from './models';
import { OutputContext } from './transforms';

const WebtextBuilderReview = (props) => {
	const {
		api,
		course,
		page,
		toc,
		interactiveTemplate,
		interactiveTemplateData: data,
		user,
		jwtToken
	} = props;
	if (!data) return null;

	const renderTablePrompt = (prompt, item, output) => {
		const numberOfColumns = Math.max.apply(
			null,
			item.rows.map((row) => row.length)
		);
		const columnWidths = item['column-widths'] || [];
		const unconfiguredWidths = numberOfColumns - columnWidths.length;

		if (unconfiguredWidths) {
			const configuredWidth = columnWidths
				.map((w) => parseInt(w.replace('%', '')))
				.reduce((sum, w) => sum + w, 0);
			const defaultWidth = (100 - configuredWidth) / unconfiguredWidths;
			for (let i = 0; i < unconfiguredWidths; i++) {
				columnWidths.push(defaultWidth + '%');
			}
		}

		const cols = columnWidths.map((w, index) => <col key={index} width={w} />);
		const rows = item.rows.map((row, rowIndex) => {
			const cells = row.map((cell, cellIndex) => {
				let value, blank, drafted;
				switch (cell.kind) {
					case 'text':
						value = cell.value;
						break;
					case 'fill-in':
						value = output.selections[cell.dest];
						blank = !value;
						drafted = output.drafted[cell.dest];
						if (drafted) value = '&nbsp;';
						break;
					case 'value-of':
						value = output.selections[cell.source];
						break;
					case 'selector':
						value = output.selections[cell.dest];
						break;
					default:
				}

				const style = {};
				if (cell.alignment != null) style.textAlign = cell.alignment;
				if (cell['vertical-alignment'] != null) style.verticalAlign = cell['vertical-alignment'];

				return (
					<td
						key={cellIndex}
						className={cn('BlockPromptItem', {
							[styles.UserInputPromptItem]: cell.kind !== 'text',
							[styles.BlankUserInputPromptItem]: cell.kind === 'fill-in' && blank,
							[styles.DraftedUserInputPromptItem]: cell.kind === 'fill-in' && drafted
						})}
						style={style}
						colSpan={cell.colspan}
						rowSpan={cell.rowspan}
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{ __html: value }}
					/>
				);
			});
			return <tr key={rowIndex}>{cells}</tr>;
		});

		return (
			<table width="100%">
				<colgroup>{cols}</colgroup>
				<tbody>{rows}</tbody>
			</table>
		);
	};

	const renderPrompt = (user, prompt, item, nextItem, output) => {
		if (item.kind === 'table') {
			return renderTablePrompt(prompt, item, output);
		}

		let inline = true;
		let value = null;
		let blank = false;
		let notRendered = false;
		let drafted = output.drafted[item.dest];
		let userInput = true;

		let gap = null;
		let list = null;
		let spreadsheet = null;
		let chart = null;
		let outline = null;

		switch (item.kind) {
			case 'text':
				value = item.value;
				if (nextItem?.kind === 'text') {
					value = value + '<br>';
				}
				userInput = false;
				break;
			case 'gap':
				gap = <div style={{ margin: `${item.units}em 0` }} />;
				break;
			case 'image': {
				const url = getContextImageUrl({
					outputContext: output,
					image: item,
					jwtToken
				});
				if (!url) {
					break;
				}

				const filename = output.selections[item.dest];
				if (filename?.endsWith('.pdf')) {
					const downloadUrl = getContextImageUrl({
						outputContext: output,
						image: item,
						jwtToken,
						scaled: false
					});
					list = (
						<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
							<img className={imageStyles.UploadedFile} src={url} alt="" />
						</a>
					);
				} else {
					list = <img className={imageStyles.UploadedFile} src={url} alt="" />;
				}
				userInput = false;
				break;
			}
			case 'value-of':
				value = output.valueOf(item);
				userInput = false;
				if (prompt.kind === 'item') inline = false; // outline item
				break;
			case 'fill-in':
			case 'fill-in-with-citations':
				value = output.fillInValue(item);
				if (prompt.presentation !== 'inline') inline = false;
				break;
			case 'paste-in':
				value = output.selections[item.dest];
				if (prompt.presentation !== 'inline') inline = false;
				break;
			case 'selector':
				value = output.selections[item.dest];
				break;
			case 'map':
				notRendered = true;
				break;
			case 'item': // outline item
				list = item.elements.map((listItem, idx, listItems) => {
					return (
						<div key={idx}>{renderPrompt(user, item, listItem, listItems[idx + 1], output)}</div>
					);
				});
				break;
			case 'list': // outline list
				list = (
					<ol>
						{item.items.map((listItem, idx, listItems) => (
							<li key={idx}>{renderPrompt(user, item, listItem, listItems[idx + 1], output)}</li>
						))}
					</ol>
				);
				break;
			case 'para': // "text" output, paragraph
				list = (
					<p>
						{item.items.map((paraItem, idx, paraItems) => (
							<span key={idx}>
								{renderPrompt(user, item, paraItem, paraItems[idx + 1], output)}
							</span>
						))}
					</p>
				);
				break;
			case 'spreadsheet': {
				const response = !!output?.selections[item?.dest];
				if (response) {
					spreadsheet = <ReadOnlySpreadsheet inputs={output?.selections} item={item} />;
				} else {
					inline = false;
				}
				break;
			}
			case 'chart': {
				const response = output?.selections[item?.dest];
				if (response) {
					chart = <Charts value={response} isOutput={true} />;
				} else {
					inline = false;
				}
				break;
			}
			case 'outline': {
				const dest = item?.dest;
				const response = output?.selections[dest];
				if (response) {
					const { hierarchy } = response;
					outline = (
						<Outline
							/**
							 * Having the variable `value` prop is not enough to cause a re-render
							 * Its changes are ignored for performance optimization when the Outline is displayed in the student view
							 * Therefore, we need to force a re-render by adding a varible `key` prop
							 */
							key={`${interactiveTemplate.family_id}_${user.id}`}
							builderFamilyId={interactiveTemplate.family_id}
							dest={dest}
							variant={OutlineVariant.REVISE}
							config={{ hierarchy }}
							value={response}
						/>
					);
				} else {
					inline = false;
				}
				break;
			}
			default:
				value = JSON.stringify(item);
				userInput = false;
		}

		if (notRendered) {
			return null;
		}

		if (gap) return gap;
		if (list) return list;

		if (!drafted) {
			if (spreadsheet) return spreadsheet;
			if (chart) return chart;
			if (outline) return outline;
		}

		if (!value) {
			value = '&nbsp;';
			blank = true;
		}

		if (drafted) {
			value = '&nbsp;';
		}

		if (inline) {
			return (
				<span
					data-test="wt-inline-prompt-item"
					className={cn(styles.InlinePromptItem, {
						[styles.UserInputPromptItem]: userInput,
						[styles.BlankUserInputPromptItem]: blank,
						[styles.DraftedUserInputPromptItem]: drafted
					})}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: value }}
				/>
			);
		} else {
			return (
				<div
					data-test="wt-block-prompt-item"
					className={cn(styles.BlockPromptItem, {
						[styles.UserInputPromptItem]: userInput,
						[styles.BlankUserInputPromptItem]: blank,
						[styles.DraftedUserInputPromptItem]: drafted
					})}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: value }}
				/>
			);
		}
	};

	const config = getBuilderConfig(interactiveTemplate, user.decisions);
	const builder = new WebtextBuilder({
		config,
		courseData: toc.config.course_data || {},
		courseDecisions: toc.config.course_decisions || {},
		userDecisions: user.decisions,
		userID: user.id,
		dependentBuilderPrompts: data.dependent_builder_prompts,
		elementContext: {
			host: api.host,
			courseID: course.id,
			chapterID: page.chapter_id,
			pageID: page.id,
			pageVersion: page.version,
			builderID: interactiveTemplate.id
		}
	});

	let selections = {};
	if (data.dests) selections = data.dests._draft || data.dests;
	if (data.sources) {
		Object.keys(data.sources).forEach((key) => {
			if (key[0] !== '_') selections[key] = data.sources[key];
		});
	}
	InitSelections(builder, selections);

	const output = new OutputContext(builder, selections);
	output.drafted = {};
	if (data.dests && data.dests._draft) {
		Object.keys(data.dests._draft).forEach((dest) => {
			output.drafted[dest] = true;
		});
	}

	if (builder.config.prompts.length) {
		return builder.config.prompts.map((prompt, index) => {
			return (
				<div key={index} data-test="wt-prompt">
					{prompt.items.map((item, index, items) => {
						return (
							<span key={index}>{renderPrompt(user, prompt, item, items[index + 1], output)}</span>
						);
					})}
				</div>
			);
		});
	} else if (builder.config.output.outline) {
		return builder.config.output.outline.map((item, index, items) => {
			return (
				<div key={index} data-test="wt-outline">
					{renderPrompt(user, item, item, items[index + 1], output)}
				</div>
			);
		});
	} else {
		throw new Error(`Cannot find prompts or outline output.`);
	}
};

WebtextBuilderReview.propTypes = {
	api: object.isRequired,
	course: object.isRequired,
	toc: object.isRequired,
	page: object.isRequired,
	user: object.isRequired,
	interactiveTemplate: object.isRequired,
	interactiveTemplateData: object
};

export const getBuilderConfig = (interactiveTemplate, userDecisions) => {
	if (!Array.isArray(interactiveTemplate.depends_on) || !interactiveTemplate.depends_on.length) {
		return interactiveTemplate.config;
	}

	const decisionName = interactiveTemplate.depends_on[0];
	const userDecision = userDecisions[decisionName];
	return userDecision ? JSON.parse(interactiveTemplate.dictionary.config[userDecision]) : {};
};

export default WebtextBuilderReview;
