import React from 'react';

import Chatbox, {
	DEFAULT_AI_CHATBOX_TITLE
} from '@soomo/lib/components/pageElements/AiChatbox/Chatbox';
import ShadowDOMRoot from '@soomo/lib/components/ShadowDOMRoot';
import { Spacer } from '@soomo/lib/components/pageElements';

import NoopHostedGPTProvider from './NoopHostedGPTProvider';

import type { AiChatboxElement } from '@soomo/lib/types/WebtextManifest';

import styles from './AiChatbox.scss';

interface Props {
	element: AiChatboxElement;
}

const AiChatbox: React.VFC<Props> = ({ element }) => {
	return (
		<NoopHostedGPTProvider element={element}>
			<div className={styles.Root}>
				<ShadowDOMRoot mode="open" className={styles.ChatboxShadowRoot}>
					<Chatbox
						familyId={element.family_id}
						title={element.title}
						aria-hidden="true"
						// @ts-expect-error inert attribute not included in TS 4.4 typings, but usable
						inert="true"
					/>
				</ShadowDOMRoot>
				<div className={styles.Overlay}>
					<div className={styles.OverlayContent}>
						<span className={styles.AiChatboxTitle}>
							{element.title || DEFAULT_AI_CHATBOX_TITLE}
						</span>
						<p className={styles.Notice}>
							To interact with the chatbox, go to the corresponding page in your webtext.
						</p>
					</div>
				</div>
			</div>
			<Spacer />
		</NoopHostedGPTProvider>
	);
};

export default AiChatbox;
