import { createConsumer } from '@rails/actioncable';
import store from '../store';
import { handlePushNotification, setJWT } from 'Actions/dataActions';
import { jobReady } from 'Requests/index';
import { deleteSessionStorage } from 'Services/index';

export default {
	connect: () => {
		const initializationInterval = setInterval(() => {
			const state = store.getState();
			const {
				data: {
					api: { host, jwt },
					course: { id: courseId }
				}
			} = state;
			if (!host) return;

			const consumer = createConsumer(`https://${host}/cable?jwt=${jwt}`);
			consumer.subscriptions.create(
				{
					channel: 'PushNotificationsChannel',
					rooms: [`course:${courseId}`, `course:${courseId}:analytics`]
				},
				{
					received: (data) => {
						if (data.type === 'background_job_complete') {
							store.dispatch(jobReady(data.job_id));
						} else if (data.type === 'user_signed_out' && !data.resent) {
							deleteSessionStorage('course_analytics.config');
							store.dispatch(setJWT(null));
						} else {
							store.dispatch(handlePushNotification(data));
						}
					}
				}
			);

			clearInterval(initializationInterval);
		}, 500);
	}
};
