import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NotScorableIcon from './NotScorableIcon';
import { formatScore } from 'Utils/format';
import TippyRollover from '../TippyRollover';
import styles from '../GridView.scss';

class CourseScoreCell extends React.PureComponent {
	static propTypes = {
		user: PropTypes.object,
		scope: PropTypes.object,
		scopeType: PropTypes.string,
		course: PropTypes.object.isRequired,
		courseAverageScores: PropTypes.object.isRequired,
		isScrolling: PropTypes.bool
	};

	render() {
		const { course, courseAverageScores, user, scope, scopeType, isScrolling } = this.props;

		let score, scoreText, explanation;

		if (user) {
			score = user.course_score;
			explanation =
				'Average score for this student on all pages with at least one scoreable question attempted.';
		} else {
			if (scope) {
				score = courseAverageScores[scope.id];

				let scopeText = 'on this page';
				if (scopeType === 'chapter') scopeText = 'in this chapter';
				if (scopeType === 'pageGroup') scopeText = 'in this group of pages';

				if (scope.question_counts.mc > 0 || scope.question_counts.mcqp > 0) {
					explanation = `Average score of students in this class who attempted at least one scoreable question ${scopeText}.`;
				} else {
					explanation = `There are no scoreable questions ${scopeText}.`;
					scoreText = <NotScorableIcon />;
				}
			} else {
				score = course.average_score;
				explanation = 'Average of all student scores in this class.';
			}
		}

		if (!scoreText) {
			scoreText = score != null ? formatScore({ score }) : <span>&mdash;</span>;
		}

		return (
			<TippyRollover
				content={
					isScrolling ? null : <span className={styles.CellHoverExplanation}>{explanation}</span>
				}>
				<span>{scoreText}</span>
			</TippyRollover>
		);
	}
}

const mapStateToProps = (state) => ({
	course: state.entities.course,
	courseAverageScores: state.entities.courseAverageScores
});

export default connect(mapStateToProps)(CourseScoreCell);
