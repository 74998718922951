import React from 'react';
import indefinite from 'indefinite';

import styles from './DecisionChoiceBlock.scss';

interface DependencyOption {
	key: string;
	name: string;
}

interface Props {
	courseDecision: string;
	selectedDecisionChoice: string;
	options: Array<DependencyOption>;
	onChange: (_) => void;
}

const DecisionChoiceBlock: React.FC<Props> = (props) => {
	const { courseDecision, selectedDecisionChoice, options, onChange } = props;

	const courseDecisionText = courseDecision.replace('_', ' ').toLowerCase();

	const onSelectChange = (event) => {
		onChange(event.target.value);
	};

	return (
		<div className={styles.DecisionChoiceBlock}>
			<label htmlFor="page-dependencies-select" className={styles.Label}>
				Select {indefinite(courseDecisionText)}
			</label>
			<select
				id="page-dependencies-select"
				className={styles.Select}
				value={selectedDecisionChoice}
				onChange={onSelectChange}>
				<option value="" disabled hidden={selectedDecisionChoice !== ''}></option>
				{options.map(({ key, name }) => (
					<option key={key} value={key}>
						{name}
					</option>
				))}
			</select>
			<div className={styles.Description}>
				The contents of this page depend on students’ {courseDecisionText}. You can view the class
				performance data for any {courseDecisionText} by selecting it from the drop-down menu above.
			</div>
		</div>
	);
};

export default DecisionChoiceBlock;
