import { mutateAsync, mergeRecords } from '.';

export default ({ courseId, userId, pageId, questionPoolFamilyId }) =>
	mutateAsync({
		path: '/api/courses/v1/answers/resets',
		params: {
			course_id: courseId,
			user_id: userId,
			page_id: pageId,
			mc_question_pool_id: questionPoolFamilyId
		},
		update: { answers: mergeRecords }
	});
