import { mutateAsync, mergeRecords } from './';

export default ({ courseId, userId, pageId, quizId }) =>
	mutateAsync({
		path: '/api/courses/v1/answers/resets',
		params: { course_id: courseId, user_id: userId, page_id: pageId, quiz_id: quizId },
		transform: (payload) => {
			const entities = { answers: {} };
			Object.keys(payload.answers).forEach(function (answerId) {
				entities.answers[answerId] = { reset_at: payload.answers[answerId].reset_at };
			});
			return entities;
		},
		update: { answers: mergeRecords }
	});
