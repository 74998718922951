import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ScoreCell from 'Components/GridCells/ScoreCell';
import RequiresActionCell from 'Components/GridCells/RequiresActionCell';
import { metricsQueryConfig } from 'Requests/MetricsQuery';
import { compose } from 'redux';
import { connectRequest } from 'redux-query-react';
import { pick } from 'lodash-es';

const PageScoreCell = ({ data, lowScoreCutoff, loaded }) => {
	const score = data?.score ?? null;
	const lowScore =
		score != null && lowScoreCutoff != null
			? Math.floor(score * 100.0) <= Math.floor(lowScoreCutoff * 100)
			: false;

	if (data?.requires_action) return <RequiresActionCell />;

	return <ScoreCell score={score} lowScore={lowScore} loaded={loaded} />;
};

PageScoreCell.propTypes = {
	user: PropTypes.object.isRequired,
	page: PropTypes.object.isRequired,
	chapter: PropTypes.object.isRequired,
	data: PropTypes.object,
	lowScoreCutoff: PropTypes.number,
	loaded: PropTypes.bool,
	metricsConfig: PropTypes.object
};

const lowScoreCutoffSelectors = {};

const createLowScoreCutoffSelector = (pageId) => {
	return createSelector(
		(state) => state.entities.course.policy_document,
		(policyDocument) => {
			let policy = policyDocument.policies[policyDocument.instances[pageId]];
			policy ||
				(policy = policyDocument.policies[policyDocument.defaults['NG::Traditional::Page']]);
			return policy.attempts_allowed > 0 ? 0.7 : 0.9;
		}
	);
};

const mapStateToProps = (state, props) => {
	const lowScoreCutoffSelector =
		lowScoreCutoffSelectors[props.page.id] ||
		(lowScoreCutoffSelectors[props.page.id] = createLowScoreCutoffSelector(props.page.id));
	const userIds = props.fetchRowGroupUserIds({ rowGroup: props.rowGroup });
	const metricsConfig = metricsQueryConfig({
		scopeId: props.chapter.id,
		groupBy: 'page',
		courseId: state.data.course.id,
		userIds: userIds,
		host: `https://${state.data.api.host}`,
		jwt: state.data.api.jwt
	});
	const bodyAndUrl = pick(metricsConfig, 'body', 'url');
	metricsConfig.queryKey = JSON.stringify({ body: bodyAndUrl.body, url: bodyAndUrl.url });

	return {
		metricsConfig,
		loaded: state.queries[metricsConfig.queryKey]?.isFinished ?? true,
		data: state.entities.metrics[props.user.id + ':' + props.page.id],
		lowScoreCutoff: lowScoreCutoffSelector(state)
	};
};

const mapPropsToConfig = (props) => {
	return props.metricsConfig;
};

export default compose(connect(mapStateToProps), connectRequest(mapPropsToConfig))(PageScoreCell);
