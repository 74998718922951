import { requestAsync, replaceData } from './';

export default ({ course }) =>
	requestAsync({
		path: '/api/courses/v1/webtext/manifest',
		params: { course_id: course.id },
		transform: (manifest) => {
			const entities = { ...manifest, scopeTypes: {} };
			const toc = entities.toc;
			const tocConfig = entities.toc.config;

			const getPageNavLabel = (page) => {
				let label = (tocConfig.pages.types[page.page_type] || {}).nav_bar_label;
				if (!label && page.page_type === 'investigation') label = tocConfig.assignments.human_name;
				return label;
			};

			const getPageQuestionCount = (page) =>
				Object.values(page.question_counts).reduce((sum, c) => sum + c);

			toc.total_question_count = 0;

			toc.chapter_ids.forEach(function (chapterId) {
				const chapter = entities.chapters[chapterId];
				chapter.word_count = 0;
				chapter.total_question_count = 0;

				if (chapter.page_group_ids.length > 0) {
					chapter.page_group_ids.forEach(function (pageGroupId) {
						const pageGroup = entities.page_groups[pageGroupId];
						pageGroup.word_count = 0;
						pageGroup.total_question_count = 0;

						pageGroup.page_ids.forEach(function (pageId) {
							const page = entities.pages[pageId];
							page.page_nav_label = getPageNavLabel(page);
							page.total_question_count = getPageQuestionCount(page);

							pageGroup.word_count += page.word_count;
							pageGroup.total_question_count += page.total_question_count;
							chapter.word_count += page.word_count;
							chapter.total_question_count += page.total_question_count;
							entities.scopeTypes[pageId] = 'page';
						});
						entities.scopeTypes[pageGroupId] = 'pageGroup';
					});
				} else {
					chapter.page_ids.forEach(function (pageId) {
						const page = entities.pages[pageId];
						page.page_nav_label = getPageNavLabel(page);
						page.total_question_count = getPageQuestionCount(page);
						chapter.word_count += page.word_count;
						chapter.total_question_count += page.total_question_count;
						entities.scopeTypes[pageId] = 'page';
					});
				}

				toc.word_count += chapter.word_count;
				toc.total_question_count += chapter.total_question_count;
				entities.scopeTypes[chapterId] = 'chapter';
			});

			entities.scopeTypes[toc.id] = 'toc';

			return entities;
		},
		update: {
			toc: replaceData,
			chapters: replaceData,
			page_groups: replaceData,
			pages: replaceData,
			elements: replaceData,
			scopeTypes: replaceData
		}
	});
