import React, { ComponentType, ReactElement } from 'react';

import themes from '@soomo/lib/styles/themes';
import ThemeProvider from '@soomo/lib/styles/themes/ThemeProvider';
import { useAppSelector } from 'Store/index';

function withLibsThemeProvider<P>(WrappedComponent: ComponentType<P>) {
	const ComponentWithTheme = (props: P): ReactElement => {
		const theme = useAppSelector((state) => state.entities.toc.theme);

		return (
			<ThemeProvider theme={themes[theme]}>
				{/*
					Temporary typing fix for `@emotion/react@11.13.5` regression.
					See - https://github.com/emotion-js/emotion/issues/3245
					@ts-expect-error */}
				<WrappedComponent {...props} />
			</ThemeProvider>
		);
	};
	return ComponentWithTheme;
}

export default withLibsThemeProvider;
