import { requestAsync } from './';

export default ({ courseId, force = false }) =>
	requestAsync({
		path: '/api/courses/v1/page_completions/summary',
		params: { course_id: courseId },
		transform: (payload) => {
			const entities = {
				courseAverageCompletions: {},
				courseStdDevCompletions: {},
				courseCountVisited: {},
				courseAverageScores: {},
				courseStdDevScores: {},
				courseLowScoreCutoffs: {}
			};

			Object.keys(payload.completion).forEach(function (pageId) {
				entities.courseAverageCompletions[pageId] = payload.completion[pageId][0];
				entities.courseStdDevCompletions[pageId] = payload.completion[pageId][1];
			});

			Object.keys(payload.visited).forEach(function (pageId) {
				entities.courseCountVisited[pageId] = payload.visited[pageId];
			});

			Object.keys(payload.score).forEach(function (pageId) {
				const avg = payload.score[pageId][0];
				const stddev = payload.score[pageId][1];

				entities.courseAverageScores[pageId] = avg;
				entities.courseStdDevScores[pageId] = stddev;
				entities.courseLowScoreCutoffs[pageId] = stddev ? Math.max(0, avg - 2 * stddev) : null;
			});

			entities.courseUserCount = payload.user_count;

			return entities;
		},
		update: {
			courseAverageCompletions: (prev, current) => current || {},
			courseStdDevCompletions: (prev, current) => current || {},
			courseCountVisited: (prev, current) => current || {},
			courseAverageScores: (prev, current) => current || {},
			courseStdDevScores: (prev, current) => current || {},
			courseLowScoreCutoffs: (prev, current) => current || {},
			courseUserCount: (prev, current) => current || 0
		},
		force
	});
