import React, { VFC } from 'react';
import cn from 'classnames';

import styles from './RequiresActionCell.scss';

interface Props {
	className?: string;
	onClick?: () => void;
}

const RequiresActionCell: VFC<Props> = ({ className, onClick }) => {
	const icon = <div className={cn('material-icons', styles.Inner)}>priority_high</div>;

	const cellProps = {
		className: cn(className, styles.Outer),
		children: icon
	};
	return onClick ? (
		<button {...cellProps} aria-label="Requires Action" onClick={onClick} />
	) : (
		<div {...cellProps} />
	);
};

export default RequiresActionCell;
