import styles from './StudentSelector.scss';
import selectorPanelStyles from '../SelectorPanel/SelectorPanel.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setStudentsFilters } from 'Actions/studentsActions';
import { studentsFilterOptions } from '../StudentList/StudentsFilters';
import Filter from './Filter';

class Filters extends React.Component {
	static propTypes = {
		studentsFilters: PropTypes.array.isRequired,
		dispatch: PropTypes.func.isRequired
	};

	componentWillUnmount() {
		this.props.dispatch(setStudentsFilters({ filters: [] }));
	}

	onFilterChange = (event, checked) => {
		const { studentsFilters, dispatch } = this.props;
		const nextStudentsFilters = studentsFilters.concat();
		const filter = event.target.value;
		const filterIdx = studentsFilters.indexOf(filter);

		if (checked) {
			if (filterIdx === -1) {
				nextStudentsFilters.push(filter);
			}
		} else {
			if (filterIdx !== -1) {
				nextStudentsFilters.splice(filterIdx, 1);
			}
		}

		dispatch(setStudentsFilters({ filters: nextStudentsFilters }));
	};

	onSelectAll = (event, checked) => {
		const { dispatch } = this.props;
		const nextStudentsFilters = checked ? studentsFilterOptions.concat() : [];

		dispatch(setStudentsFilters({ filters: nextStudentsFilters }));
	};

	render() {
		const { studentsFilters } = this.props;

		const label =
			studentsFilters.length > 0 ? 'You are now filtering to see:' : 'You can now filter to see:';

		return (
			<div className={selectorPanelStyles.FilterBox}>
				<h3 className={selectorPanelStyles.FilterDescriptionLabel}>{label}</h3>
				<div className={selectorPanelStyles.FilterDescription}>
					<div>
						<strong>Students</strong> who have either:
						<ul className={styles.FilterList}>
							{studentsFilterOptions.map((filter) => (
								<li key={filter} className={styles.FilterListItem}>
									<Filter
										filter={filter}
										studentsFilters={studentsFilters}
										onFilterChange={this.onFilterChange}
									/>
								</li>
							))}
							<li key="all" className={styles.FilterListItem}>
								<Filter
									filter="all"
									studentsFilters={studentsFilters}
									onFilterChange={this.onSelectAll}
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	studentsFilters: state.students.filters
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
