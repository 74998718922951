import { DateTime } from 'luxon';

import {
	formatDate,
	formatTime,
	formatTimeLong,
	formatTimeShort,
	formatTimeFromNow
} from '@soomo/lib/utils/formatting';

export { formatDate, formatTime, formatTimeLong, formatTimeShort, formatTimeFromNow };

export function formatDuration({ duration, onlyMinutes, noSeconds, minuteLabel }) {
	minuteLabel || (minuteLabel = 'm');
	if (duration != null) {
		let h, m, timeSpentInWords;

		if (noSeconds && duration < 60) {
			return `<1${minuteLabel}`;
		}

		if (onlyMinutes) {
			h = 0;
			m = Math.ceil(duration / 60).toFixed(0);
		} else {
			let h_int = Math.floor(duration / 3600);
			let m_int = Math.ceil(duration / 60) % 60;

			// `Math.ceil` rounds up to the nearest minute, which
			// may result in rounding up to the next hour. In that
			// scenario, the number of hours should be incremented.
			const rounded_up = duration % 60 !== 0;
			if (rounded_up && m_int === 0) {
				h_int = h_int + 1;
			}

			h = h_int.toFixed(0);
			m = m_int.toFixed(0);
		}

		if (h > 0) {
			timeSpentInWords = `${h}h ${m}${minuteLabel}`;
		} else {
			timeSpentInWords = `${m}${minuteLabel}`;
		}

		return timeSpentInWords;
	} else {
		return null;
	}
}

export function formatPercentage({ num, den }) {
	return num != null && den ? Math.floor((num / den) * 100).toFixed(0) + '%' : null;
}

export function formatCompletion({ completion }) {
	return Math.floor(completion * 100).toFixed(0) + '%';
}

export function formatScore({ score }) {
	return Math.floor(score * 100).toFixed(0) + '%';
}

export const formatReportTime = ({ time, timeZone }) => {
	const formatString = 'h:mm a ZZZZ';
	return DateTime.fromISO(time)
		.setZone(timeZone)
		.toFormat(formatString)
		.replace(/ AM( |$)/, ' a.m.$1')
		.replace(/ PM( |$)/, ' p.m.$1');
};
