import React from 'react';
import PropTypes from 'prop-types';

class LabeledLine extends React.PureComponent {
	static propTypes = {
		label: PropTypes.string,
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
	};

	render() {
		const { label, children } = this.props;

		return (
			<p>
				<span className="label">{label}:</span>
				<span>{children}</span>
			</p>
		);
	}
}

export default LabeledLine;
