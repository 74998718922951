import { mutateAsync, mergeData } from './';

export default ({ courseId, answerId, userId }) =>
	mutateAsync({
		method: 'DELETE',
		path: '/api/courses/v1/answers/postings',
		params: { course_id: courseId, answer_id: answerId },
		transform: (answer) => ({ answerPostings: { [answer.id]: answer.posted_at } }),
		update: { answerPostings: mergeData },
		optimisticUpdate: {
			answerPostings: (prev) => ({ ...prev, [answerId]: null }),
			answers: (prev) => ({
				...prev,
				[answerId]: {
					...prev[answerId],
					...(prev[answerId] &&
						prev[answerId].history && {
							history: [
								{
									created_at: new Date().toISOString(),
									body: '',
									event: 'unposted',
									source: userId
								},
								...prev[answerId]['history']
							]
						})
				}
			})
		}
	});
