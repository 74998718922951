import React, { FC } from 'react';
import { createBrowserHistory } from 'history';
import { Redirect, Route, Switch } from 'react-router-dom';
import JwtAuthGateway from 'Containers/JwtAuthGateway';
import NotebookApp from 'Containers/NotebookApp';
import ApplicationRoute from './ApplicationRoute';
import SessionAuthGateway from 'Containers/SessionAuthGateway';

const ROUTE_POSTFIX = '| Soomo';

const Routing: FC = () => (
	<Switch>
		<ApplicationRoute
			path="/courses/:courseId"
			title={`Looking for the course ${ROUTE_POSTFIX}`}
			component={JwtAuthGateway}
		/>
		<ApplicationRoute
			path="/sessions"
			title={`Looking for the course ${ROUTE_POSTFIX}`}
			component={SessionAuthGateway}
		/>
		<ApplicationRoute
			exact
			path="/"
			title={`Webtext Analytics ${ROUTE_POSTFIX}`}
			component={NotebookApp}
		/>
		<Route path="/*">
			<Redirect to="/" />
		</Route>
	</Switch>
);

export const history = createBrowserHistory();

export default Routing;
