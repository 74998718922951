import React from 'react';

import { getElementLabel } from '@soomo/lib/components/WritingTemplate/WritingTemplate/utils';
import { WritingTemplateElement, Manifest } from '@soomo/lib/types/WebtextManifest';

import { Page } from 'Types/index';
import BaseTable from './BaseTable';

import pageViewStyles from '../PageView.scss';

interface InteractiveElementAggregated {
	completed: number;
	drafted: number;
	isDirectSubmit: boolean;
}

interface Props {
	element: WritingTemplateElement;
	isPageActivityLoaded: boolean;
	aggregatedElement?: InteractiveElementAggregated;
	totalStudentsCount?: number;
	toc: Manifest['toc'];
	page: Page;
}

const InteractiveTemplate: React.FC<Props> = (props) => {
	const { element, isPageActivityLoaded, aggregatedElement, totalStudentsCount, toc, page } = props;
	const { completed = 0, drafted = 0, isDirectSubmit } = aggregatedElement || {};

	const headerRow = ['Status', `Students (out of ${totalStudentsCount})`];
	const dataRows = [
		[isDirectSubmit ? 'Submitted' : 'Posted', completed],
		['Drafted', drafted],
		['Not started', totalStudentsCount - completed - drafted]
	];

	const questionTypeLabel =
		getElementLabel(toc.config, page.page_type, element.element_definition_key) ||
		'Writing Template';

	return (
		<div>
			<div className={pageViewStyles.ActivitySummary}>
				<span className={pageViewStyles.QuestionType}>{questionTypeLabel}</span>
			</div>
			<h2
				className={pageViewStyles.QuestionBody}
				dangerouslySetInnerHTML={{ __html: element.config.title }}
			/>

			<BaseTable header={headerRow} rows={dataRows} isDataLoaded={isPageActivityLoaded} />
		</div>
	);
};

export default InteractiveTemplate;
