import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GridView, PageView, AggregatedPageView, ElementView, MessageView } from '../Views';
import { viewTemplateTypes } from '../../constants';

const viewTemplates = {
	[viewTemplateTypes.MESSAGE]: MessageView,
	[viewTemplateTypes.GRID]: GridView,
	[viewTemplateTypes.PAGE]: PageView,
	[viewTemplateTypes.AGGREGATED_PAGE]: AggregatedPageView,
	[viewTemplateTypes.ELEMENT]: ElementView
};

class ViewFrame extends React.Component {
	static propTypes = {
		viewTemplate: PropTypes.string.isRequired
	};

	render() {
		const ViewTemplate = viewTemplates[this.props.viewTemplate];
		return <ViewTemplate />;
	}
}

export default connect((state) => ({ viewTemplate: state.ui.viewTemplate }))(ViewFrame);
