import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export function withCourseScopedQuery(query, mapPropsToQueryParams) {
	return function (WrappedComponent) {
		class DataFetcher extends React.Component {
			static propTypes = {
				courseId: PropTypes.string.isRequired,
				dispatch: PropTypes.func.isRequired
			};

			componentDidMount() {
				this.fetchData();
			}

			componentDidUpdate() {
				this.fetchData();
			}

			fetchData = () => {
				const { dispatch, courseId } = this.props;
				dispatch(query({ courseId, ...mapPropsToQueryParams(this.props) }));
			};

			render() {
				const passthroughProps = { ...this.props };
				const ownPropKeys = ['dispatch', 'courseId'];
				ownPropKeys.forEach((key) => delete passthroughProps[key]);
				return <WrappedComponent {...passthroughProps} />;
			}
		}

		const mapStateToProps = (state) => ({ courseId: state.data.course.id });
		const mapDispatchToProps = (dispatch) => ({ dispatch });
		return connect(mapStateToProps, mapDispatchToProps)(DataFetcher);
	};
}
