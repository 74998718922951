import * as types from '../actions/actionTypes.js';
import initialState from './initialState';

export default function (state = initialState.jobs, action) {
	switch (action.type) {
		case types.JOB_IN_PROGRESS:
			return {
				...state,
				[action.payload.jobId]: {
					...action.payload,
					status: 'wip'
				}
			};

		case types.JOB_READY: {
			const job = state[action.payload.jobId];
			if (job) {
				return {
					...state,
					[action.payload.jobId]: {
						...job,
						status: 'ready'
					}
				};
			}
			break;
		}

		case types.JOB_COMPLETE: {
			const job = state[action.payload.jobId];
			if (job) {
				return {
					...state,
					[action.payload.jobId]: {
						...job,
						status: 'complete'
					}
				};
			}
			break;
		}
	}

	return state;
}
