/* eslint-disable react/no-danger */
import popoverStyles from '../SelectorPanel/PopoverSelector.scss';
import selectorPanelStyles from '../SelectorPanel/SelectorPanel.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import NudgeArrow from 'Components/NudgeArrow';
import { updateCurrentViewParams } from 'Actions/uiActions';

const getElementListItems = ({ element, toc, onSelect }) => {
	const items = [];

	if (element.type === 'response_board' || element.type === 'sa_question') {
		if (element.depends_on && element.depends_on.length > 0 && element.dictionary.body) {
			const cyoOptions = toc.config.course_decisions[element.depends_on[0]].options;
			const cyoOptionsIdx = {};
			cyoOptions.forEach(function (option) {
				cyoOptionsIdx[option.key] = option;
			});

			Object.keys(element.dictionary.body).forEach(function (cyoKey) {
				const elementBody = element.dictionary.body[cyoKey];
				const cyoOption = cyoOptionsIdx[cyoKey];

				const label = (
					<div>
						<strong style={{ fontWeight: 'bold' }}>{cyoOption.name}</strong>: &nbsp;
						<div
							// eslint-ignore-next-line react/no-danger
							dangerouslySetInnerHTML={{ __html: elementBody }}
							style={{ display: 'inline' }}
						/>
					</div>
				);

				items.push(
					<ListItem
						key={`${element.id}:${cyoKey}`}
						classes={{ root: selectorPanelStyles.MenuItem }}
						onClick={(event) => {
							onSelect(event, `${element.id}:${cyoKey}`);
						}}>
						{label}
					</ListItem>
				);
			});
		}
	}

	return items;
};

class ElementSelector extends React.Component {
	static propTypes = {
		selectedElementId: PropTypes.string.isRequired,
		toc: PropTypes.object,
		elements: PropTypes.object,
		style: PropTypes.object,
		dispatch: PropTypes.func.isRequired
	};

	state = {
		isPopoverOpen: false
	};

	handleSelectorClick = (event) => {
		event.preventDefault();

		if (event.currentTarget.classList.contains('popoverSelector')) {
			this.setState({ isPopoverOpen: true, anchorEl: event.currentTarget });
		}
	};

	setPopoverClosed = () => this.setState({ isPopoverOpen: false });

	onElementSelect = (event, value) => {
		this.props.dispatch(updateCurrentViewParams({ element: value }));
		this.setPopoverClosed();
	};

	onPrevElementClick = () => {
		const { selectedElementId, elements } = this.props;
		let nextElementId = null;

		const elementId = selectedElementId ? selectedElementId.split(':', 2)[0] : null;
		const element = elements[elementId];

		let elementIds = [];
		if (element.type === 'response_board' || element.type === 'sa_question') {
			if (element.depends_on && element.depends_on.length > 0 && element.dictionary.body) {
				elementIds = Object.keys(element.dictionary.body).map(
					(cyoKey) => `${element.id}:${cyoKey}`
				);
			} else {
				elementIds = [element.id];
			}
		}

		if (selectedElementId) {
			const currentIndex = elementIds.indexOf(selectedElementId);
			if (currentIndex > 0) {
				nextElementId = elementIds[currentIndex - 1];
			}
		}

		this.props.dispatch(updateCurrentViewParams({ element: nextElementId }));
	};

	onNextElementClick = () => {
		const { selectedElementId, elements } = this.props;
		let nextElementId = null;

		const elementId = selectedElementId ? selectedElementId.split(':', 2)[0] : null;
		const element = elements[elementId];

		let elementIds = [];
		if (element.type === 'response_board' || element.type === 'sa_question') {
			if (element.depends_on && element.depends_on.length > 0 && element.dictionary.body) {
				elementIds = Object.keys(element.dictionary.body).map(
					(cyoKey) => `${element.id}:${cyoKey}`
				);
			} else {
				elementIds = [element.id];
			}
		}

		if (selectedElementId) {
			nextElementId = elementIds[elementIds.indexOf(selectedElementId) + 1];
		}

		this.props.dispatch(updateCurrentViewParams({ element: nextElementId }));
	};

	createLabelInnerHTML(labelString) {
		return { __html: labelString };
	}

	render() {
		const { selectedElementId, toc, elements } = this.props;

		let selectedItemLabel;

		const [elementId, cyoKey] = selectedElementId ? selectedElementId.split(':', 2) : [];
		const element = elements[elementId];
		const cyoOptions =
			element && cyoKey ? toc.config.course_decisions[element.depends_on[0]].options : [];

		if (element) {
			if (cyoKey && element.dictionary && element.dictionary.body) {
				const elementBody = element.dictionary.body[cyoKey];
				let cyoOption;
				cyoOptions.forEach(function (option) {
					if (option.key === cyoKey) cyoOption = option;
				});

				const overflowing = cyoOption.name.length + elementBody.length > 75;
				const limit = 75 - cyoOption.name.length;

				selectedItemLabel = `<strong style="font-weight: bold">${cyoOption.name}</strong>: ${
					overflowing ? elementBody.slice(0, limit) + '...' : elementBody
				}`;
			} else {
				selectedItemLabel = element.body;
			}
		} else {
			selectedItemLabel = '-----';
		}

		const backArrowDisabled = cyoKey === cyoOptions[0];
		const forwardArrowDisabled = cyoKey === cyoOptions[cyoOptions.length - 1];

		const menuItems = element
			? getElementListItems({ element, toc, onSelect: this.onElementSelect })
			: [];

		return (
			<div className={popoverStyles.Base}>
				<Button
					className="popoverSelector"
					onClick={this.handleSelectorClick}
					classes={{
						root: popoverStyles.SelectorLabelButton
					}}>
					<div className={popoverStyles.SelectorLabelBox}>
						<span className={popoverStyles.SelectorLabel}>Topic Choice</span>
						<div className={popoverStyles.SelectorLabelIndicators}>
							<div className={popoverStyles.PopoverSelectorLabelArrowBox}>
								<Icon className={cn('ss-navigateright', popoverStyles.PopoverSelectorLabelArrow)} />
							</div>
							<div
								data-active={this.state.isPopoverOpen}
								className={popoverStyles.SelectorLabelIndicator}>
								&nbsp;
							</div>
							{/* Need &nbsp; to give height in Safari */}
						</div>
					</div>
				</Button>

				<Popover
					open={this.state.isPopoverOpen}
					anchorEl={this.state.anchorEl}
					anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
					onClose={this.setPopoverClosed}
					PaperProps={{ square: true }}>
					<List classes={{ root: popoverStyles.PagesPopoverMenu }}>{menuItems}</List>
				</Popover>

				<div className={popoverStyles.PopoverSelectionLabelBox}>
					<span
						className={popoverStyles.PopoverSelectionLabelText}
						dangerouslySetInnerHTML={{ __html: selectedItemLabel }}
					/>
					<div className={popoverStyles.NudgeArrowsBox}>
						<NudgeArrow
							direction="back"
							onClick={this.onPrevElementClick}
							disabled={backArrowDisabled}
						/>
						<NudgeArrow
							direction="forward"
							onClick={this.onNextElementClick}
							disabled={forwardArrowDisabled}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	selectedElementId: state.ui.currentViewParams.element,
	toc: state.entities.toc,
	elements: state.entities.elements
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ElementSelector);
