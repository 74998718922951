import initialState from '../reducers/initialState';
import { selectDefinedProperties } from 'Utils/objects';

const {
	ui: { currentViewParams: initialViewParams }
} = initialState;

export const mapLocationToState = (state, location) => {
	/*
	 * Not override state values with undefined values.
	 * Empty values from state should impact on the location, not vice versa
	 *
	 * Exclude apiHost to not allow setting it out of sync with the state.data.api
	 */
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const { apiHost, ...definedViewParams } = selectDefinedProperties(
		location.query.ui?.currentViewParams
	);
	return {
		...state,
		ui: {
			...state.ui,
			currentViewParams: {
				...state.ui.currentViewParams,
				...definedViewParams
			}
		}
	};
};

export const paramsSetup = {
	// We should process ui updates only on the root path,
	// because the path /courses/:courseId is used for the redirection and
	// should not be controlled with the 'redux-location-state'
	'/': {
		apiHost: { stateKey: 'ui.currentViewParams.apiHost' },
		courseId: { stateKey: 'ui.currentViewParams.courseId', type: 'number' },
		scope: { stateKey: 'ui.currentViewParams.scope', options: { shouldPush: true } },
		student: {
			stateKey: 'ui.currentViewParams.student',
			type: 'number',
			options: { shouldPush: true }
		},
		element: { stateKey: 'ui.currentViewParams.element', options: { shouldPush: true } },
		searchQuery: {
			stateKey: 'ui.currentViewParams.searchQuery',
			initialState: initialViewParams.searchQuery
		},
		assignmentTypeFilter: {
			stateKey: 'ui.currentViewParams.assignmentTypeFilter',
			initialState: initialViewParams.assignmentTypeFilter
		},
		gridViewColumnScopeType: {
			stateKey: 'ui.currentViewParams.gridViewColumnScopeType',
			initialState: initialViewParams.gridViewColumnScopeType,
			options: { shouldPush: true }
		},
		gridViewAspect: {
			stateKey: 'ui.currentViewParams.gridViewAspect',
			initialState: initialViewParams.gridViewAspect,
			options: { shouldPush: true }
		},
		gridViewSortBy: {
			stateKey: 'ui.currentViewParams.gridViewSortBy',
			initialState: 'user.last_name ASC',
			options: { shouldPush: true }
		},
		gridViewScrollTop: {
			stateKey: 'ui.currentViewParams.gridViewScrollTop',
			initialState: initialViewParams.gridViewScrollTop,
			type: 'number',
			options: { shouldPush: true }
		},
		gridViewScope: {
			stateKey: 'ui.currentViewParams.gridViewScope',
			options: { shouldPush: true }
		},
		pageViewStudent: {
			stateKey: 'ui.currentViewParams.pageViewStudent',
			type: 'number',
			options: { shouldPush: true }
		},
		showInContext: {
			stateKey: 'ui.currentViewParams.showInContext',
			initialState: initialViewParams.showInContext,
			type: 'bool',
			options: { shouldPush: true }
		},
		scrollTo: { stateKey: 'ui.currentViewParams.scrollTo', options: { shouldPush: true } },
		welcomed: {
			stateKey: 'ui.currentViewParams.welcomed',
			initialState: initialViewParams.welcomed,
			type: 'bool'
		}
	}
};
