import React, { useMemo } from 'react';

import {
	HostedGPTContext,
	IHostedGPTContext
} from '@soomo/lib/components/pageElements/AiChatbox/HostedGPTContext';
import type { AiChatboxElement } from '@soomo/lib/types/WebtextManifest';

interface Props {
	element: AiChatboxElement;
}

type AiChatboxConfig = {
	assistants?: Array<{
		model: string; // human-readable name, e.g. "Claude 3 Opus"
		name: string; // model string ID, e.g. "claude-3-opus-20240229"
	}>;
} | null;

const NoopHostedGPTProvider: React.FC<Props> = ({ element, children }) => {
	const { config } = element;
	const parsedConfig: AiChatboxConfig = useMemo(() => {
		if (!config) {
			return null;
		}
		try {
			return JSON.parse(config);
		} catch (e) {
			return null;
		}
	}, [config]);

	const memoizedValue: IHostedGPTContext = useMemo(
		() => ({
			status: 'ready',
			assistantId: 1,
			availableAssistants: parsedConfig?.assistants
				? parsedConfig.assistants.map((a, i) => ({
						id: i + 1,
						language_model_name: a.model,
						name: a.name
				  }))
				: [
						{
							id: 1,
							language_model_name: '',
							name: ''
						}
				  ],
			messages: [],
			onSend: () => undefined,
			onStop: () => undefined,
			onChangeAssistant: () => undefined,
			onClear: () => undefined
		}),
		[parsedConfig]
	);

	return <HostedGPTContext.Provider value={memoizedValue}>{children}</HostedGPTContext.Provider>;
};

export default NoopHostedGPTProvider;
