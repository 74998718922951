import * as types from './actionTypes.js';

export const setInitialView = (value) => ({
	type: types.SET_INITIAL_VIEW,
	payload: value
});

export const updateCurrentViewParams = (items) => ({
	type: types.UPDATE_CURRENT_VIEW_PARAMS,
	payload: items
});

export const gridViewScrollToColumn = (payload) => ({
	type: types.GRID_VIEW_SCROLL_TO_COLUMN,
	payload: payload
});

export const setHighlightTiming = (value) => ({
	type: types.SET_HIGHLIGHT_TIMING,
	payload: value
});

export const setColumnScopeTypeSelectable = (value) => ({
	type: types.SET_COLUMN_SCOPE_TYPE_SELECTABLE,
	payload: value
});

export const setAllSectionsRowEnabled = (value) => ({
	type: types.SET_ALL_SECTIONS_ROW_ENABLED,
	payload: value
});
