import React from 'react';
import { string, number } from 'prop-types';
import { colors } from 'Theme';
import styles from './styles.scss';

const TextLoadingIndicator = ({ color = 'appAccentColor', size = 10 }) => {
	const bounceStyle = { background: colors[color], width: size, height: size };

	return (
		<span className={styles.spinner}>
			<span className={styles.bounce1} style={bounceStyle} />
			<span className={styles.bounce2} style={bounceStyle} />
			<span className={styles.bounce3} style={bounceStyle} />
		</span>
	);
};

TextLoadingIndicator.propTypes = {
	color: string,
	size: number
};

export default TextLoadingIndicator;
