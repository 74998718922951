import React, { FC, useEffect } from 'react';

import { GoReactElement } from '@soomo/lib/components/pageElements/GoReact/types';
import GoReactFromLibs from '@soomo/lib/components/pageElements/GoReact';

import { useAppDispatch, useAppSelector } from 'Store/index';
import externalToolConfigQuery from 'Requests/ExternalToolConfigQuery';

interface Props {
	element: GoReactElement;
}

const GoReactInContextView: FC<Props> = (props) => {
	const dispatch = useAppDispatch();

	const { element } = props;
	const { family_id: familyId } = element;

	const courseId = useAppSelector((state) => state.entities.course.id);
	const instructorId = useAppSelector((state) => state.entities.currentUser?.id);

	const toolConfig = useAppSelector((state) => state.entities.externalToolsConfigs[familyId]);
	useEffect(() => {
		if (!toolConfig) {
			dispatch(externalToolConfigQuery({ courseId, studentId: null, instructorId, familyId }));
		}
	}, [dispatch, courseId, familyId, instructorId, toolConfig]);

	return toolConfig ? (
		<GoReactFromLibs
			externalTool={element}
			toolConfig={toolConfig}
			showInstructorView
			showInContextView
		/>
	) : null;
};

export default GoReactInContextView;
