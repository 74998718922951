import React from 'react';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import styles from './styles.scss';

const theme = createTheme({
	typography: {
		useNextVariants: true
	},
	palette: {
		primary: {
			main: '#5a80ff'
		}
	}
});

function RaisedButton({ label, onClick, disabled, classes, styleName }) {
	let styleProps = {
		classes,
		color: 'primary',
		style: {}
	};
	if (styleName) styleProps.styleName = styleName;
	if (disabled) styleProps.style.backgroundColor = '#9ac0ff';

	return (
		<ThemeProvider theme={theme}>
			<Button
				variant="contained"
				disabled={disabled}
				onClick={onClick}
				{...styleProps}
				classes={{ root: styles.RaisedButton }}>
				<span className={styles.RaisedButtonLabel}>{label}</span>
			</Button>
		</ThemeProvider>
	);
}

export default RaisedButton;
