import React, { FC, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import ScreenLoading from 'Components/ScreenLoading';
import ModalDialog from 'Components/ModalDialog';
import { Redirect } from 'react-router-dom';
import { setCourseId, setJWT } from 'Actions/dataActions';
import { setInitialView, updateCurrentViewParams } from 'Actions/uiActions';
import { PERSISTED_CONFIG_KEY, useAppDispatch, useAppSelector } from '../../store';
import { setLocalStorage } from '../../services';
import './SessionAuthGateway.scss';

interface SessionPayload {
	courseId: number;
	initialView: Record<string, null | string | number | boolean>;
	jwt: string;
}

const SessionAuthGateway: FC = () => {
	const dispatch = useAppDispatch();

	// Config which is being embedded by the server when the users using session-based authentication
	const embeddedConfig = window.__course_analytics_config__;

	const token = useAppSelector((state) => state.data.api.jwt);

	const [isConfigApplied, setConfigApplied] = useState(false);
	const [error] = useState<boolean | Error>(!embeddedConfig);

	useEffect(() => {
		const { courseId, jwt, initialView: initialView } = embeddedConfig as SessionPayload;

		batch(() => {
			dispatch(setCourseId(courseId));
			dispatch(setJWT(jwt));

			if (Object.keys(initialView).length) {
				dispatch(setInitialView(initialView));
			}
			dispatch(updateCurrentViewParams({ courseId }));
		});

		setLocalStorage({
			[PERSISTED_CONFIG_KEY]: { jwt }
		});

		setConfigApplied(true);
	}, [dispatch, embeddedConfig]);

	useEffect(() => {
		// The config should be used only once and then disposed
		if (isConfigApplied && token) {
			window.__course_analytics_config__ = null;
		}
	}, [isConfigApplied, token]);

	return (
		<div styleName="SessionAuthGateway">
			{error ? (
				<ModalDialog open={true}>
					Your session is missing. Please go back to your webtext and re-click the Analytics link.
				</ModalDialog>
			) : (
				<>
					<ScreenLoading />
					{isConfigApplied && token && <Redirect to="/" />}
				</>
			)}
		</div>
	);
};

export default SessionAuthGateway;
