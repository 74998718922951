import { combineReducers } from 'redux';
import data from './dataReducer';
import jobs from './jobsReducer';
import ui from './uiReducer';
import toc from './tocReducer';
import students from './studentsReducer';
import { entitiesReducer, errorsReducer, queriesReducer } from 'redux-query';

export const getQueries = (state) => state.queries;
export const getEntities = (state) => state.entities;

const rootReducer = combineReducers({
	entities: entitiesReducer,
	queries: queriesReducer,
	errors: errorsReducer,
	data,
	jobs,
	ui,
	toc,
	students
});

export default rootReducer;
