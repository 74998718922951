import React, { FC } from 'react';
import { useAppSelector } from 'Store/index';
import { selectGradingRequiredPages } from 'Store/selectors';
import RequiresActionCell from 'Components/GridCells/RequiresActionCell';

import styles from '../GridView.scss';

const GradeGridLegend: FC = () => {
	const gradingRequiredPages = useAppSelector(selectGradingRequiredPages);

	return gradingRequiredPages.length !== 0 ? (
		<div className={styles.Legend}>
			<div className={styles.LegendItem}>
				<RequiresActionCell />
				<div className={styles.LegendItemLabel}>Requires Action</div>
			</div>
		</div>
	) : null;
};

export default GradeGridLegend;
