import React, { Dispatch, useEffect } from 'react';
import { connect } from 'react-redux';

import LibsPollsDeck, {
	PollsDeckProps as LibsPollsDeckProps
} from '@soomo/lib/components/pageElements/PollsDeck';
import { PollProps } from '@soomo/lib/components/pageElements/PollQuestion';
import {
	PollResultsAliasProps,
	isPollResultsAliasProps
} from '@soomo/lib/components/pageElements/PollQuestion/types';

import withLibsThemeProvider from 'Hocs/withLibsThemeProvider';
import elementActivityQuery from 'Requests/ElementActivityQuery';

import DeckedPollResultsAlias from './DeckedPollResultsAlias';
import DeckedPollQuestion from './DeckedPollQuestion';
import { createPollQuestionState } from './PollQuestion';

interface Props extends Omit<LibsPollsDeckProps, 'pollElementsProps'> {
	familyId: string;
	pollElementsProps: Array<
		(PollProps | PollResultsAliasProps) & { type: string; pollQuestionFamilyId: string }
	>;
	pollElementsWithAnswer: Array<PollProps | PollResultsAliasProps>;
	dispatch: Dispatch<any>;
	courseId: string;
}

const PollsDeck: React.FC<Props> = (props) => {
	const { pollElementsWithAnswer, dispatch, courseId } = props;

	useEffect(() => {
		// load activity data of the original poll question for poll results alias
		pollElementsWithAnswer.filter(isPollResultsAliasProps).map((poll) => {
			dispatch(elementActivityQuery({ courseId, elementId: poll.questionFamilyId }));
		});
	}, [courseId, pollElementsWithAnswer, dispatch]);

	return (
		<LibsPollsDeck {...props} pollElementsProps={pollElementsWithAnswer} instructorView>
			{pollElementsWithAnswer.map((pollProps) =>
				isPollResultsAliasProps(pollProps) ? (
					<DeckedPollResultsAlias key={pollProps.questionFamilyId} pollResultsAlias={pollProps} />
				) : (
					<DeckedPollQuestion key={pollProps.questionFamilyId} pollQuestion={pollProps} />
				)
			)}
		</LibsPollsDeck>
	);
};

const mapStateToProps = (state, props) => {
	const { pollElementsProps } = props;
	const { student: userId } = state.ui.currentViewParams;

	const pollElementsWithAnswer = pollElementsProps.map((pollElement) => {
		if (pollElement.type === 'poll_results') {
			const originalQuestionFamilyId = pollElement.pollQuestionFamilyId;
			const originalQuestion = state.entities.elements[originalQuestionFamilyId];
			const originalQuestionPage = state.entities.pages[originalQuestion.page_id];

			const answerIds = state.entities.elementAnswerIds[`${userId}:${originalQuestionFamilyId}`];
			const answers = answerIds?.map((id) => state.entities.answers[id]);
			const originalPollActivity = { answers };

			const {
				answer: originalPollAnswer,
				tabsUi,
				course
			} = createPollQuestionState(state, originalQuestion, originalPollActivity);

			return {
				...originalQuestion,
				questionFamilyId: originalQuestion.family_id,
				answer: originalPollAnswer,
				tabsUi: tabsUi,
				courseNumber: course.number,
				totalNumberOfStudents: course.active_student_enrollment_count,
				originalPollPageFamilyId: originalQuestionPage.family_id,
				originalPollPageNumber: originalQuestionPage.page_number
			};
		} else {
			const answerIds = state.entities.elementAnswerIds[`${userId}:${pollElement.family_id}`];
			const answers = answerIds?.map((id) => state.entities.answers[id]);
			const pollActivity = { answers };

			const { answer, tabsUi, course } = createPollQuestionState(state, pollElement, pollActivity);

			return {
				...pollElement,
				questionFamilyId: pollElement.family_id,
				answer: answer,
				tabsUi: tabsUi,
				courseNumber: course.number,
				totalNumberOfStudents: course.active_student_enrollment_count
			};
		}
	});

	return { pollElementsWithAnswer, courseId: state.entities.course.id };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(withLibsThemeProvider(PollsDeck));
