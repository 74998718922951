import React from 'react';
import PropTypes from 'prop-types';

class LabeledSpan extends React.PureComponent {
	static propTypes = {
		idx: PropTypes.number.isRequired,
		label: PropTypes.string.isRequired,
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
	};

	render() {
		const { idx, label, children } = this.props;

		return (
			<span className="labelWrapper">
				<span key={idx * 2} className="label">
					{label}:
				</span>
				<span key={idx * 2 + 1}>{children}</span>
			</span>
		);
	}
}

export default LabeledSpan;
