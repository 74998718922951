import React, { FC, useEffect, useState } from 'react';

import PriorityHigh from '@mui/icons-material/PriorityHigh';
import CircularProgress from '@mui/material/CircularProgress';
import cn from 'classnames';

import { GoReactElement } from '@soomo/lib/components/pageElements/GoReact/types';
import GoReactIframe from '@soomo/lib/components/pageElements/GoReact/GoReactIframe';

import AnalyticsLink from 'Components/AnalyticsLink';
import ActivityHeader from 'Components/ActivityHeader';
import { AnswerActivity, ElementActivity } from 'Types/index';
import { useAppDispatch, useAppSelector } from 'Store/index';
import { updateCurrentViewParams } from 'Actions/uiActions';
import externalToolConfigQuery from 'Requests/ExternalToolConfigQuery';

import styles from './GoReact.scss';

interface Props {
	element: GoReactElement;
	activity: ElementActivity;
}

const GoReactQuestionView: FC<Props> = (props) => {
	const { element, activity } = props;
	const { family_id: familyId, score_config: scoreConfig } = element;

	const dispatch = useAppDispatch();

	const courseId = useAppSelector((state) => state.entities.course.id);
	const scope = useAppSelector((state) => state.ui.currentViewParams.scope);

	const studentId = useAppSelector((state) => state.ui.currentViewParams.student);
	const instructorId = useAppSelector((state) => state.entities.currentUser?.id);

	const toolConfig = useAppSelector(
		(state) => state.entities.externalToolsConfigs[`${studentId}:${familyId}`]
	);

	const timeZone = useAppSelector((state) => state.entities.course.time_zone);

	const currentAnswer = activity?.answers?.[0];
	const postedAt = currentAnswer?.posted_at;
	const grade = currentAnswer ? getAnswerGrade(currentAnswer) : null;
	const isGraded = getAnswerGraded(grade);

	const instructorGradingRequired = scoreConfig.scoreOptions === 'instructorGrade';
	const awaitingInstructorGrade = instructorGradingRequired && postedAt && !isGraded;

	const showCompletedMark = () => {
		switch (scoreConfig.scoreOptions) {
			case 'noPoints':
				return !!postedAt;
			case 'instructorGrade':
				return currentAnswer?.completed && isGraded;
			case 'pointsForCompletion':
				return currentAnswer?.completed;
			default:
				return false;
		}
	};

	const notPostedText = () => {
		switch (scoreConfig.scoreOptions) {
			case 'noPoints':
			case 'instructorGrade':
				return 'NOT YET POSTED';

			case 'pointsForCompletion':
				return scoreConfig.completionOptions?.recordingMaking?.required
					? 'NOT YET POSTED'
					: 'NOT YET COMPLETED';

			default:
				return false;
		}
	};

	const postedNotGradedText = () => {
		switch (scoreConfig.scoreOptions) {
			case 'noPoints':
			case 'instructorGrade':
				return 'RECORDING POSTED';

			case 'pointsForCompletion':
				return scoreConfig.completionOptions?.recordingMaking?.required
					? 'RECORDING POSTED'
					: 'REVIEW ASSIGNMENT COMPLETED';

			default:
				return false;
		}
	};

	const [isVideoRecorderOpened, setVideoRecorderOpened] = useState(false);

	const onViewAllRecordings = () => {
		dispatch(
			updateCurrentViewParams({
				scope,
				element: familyId,
				student: null,
				pageViewStudent: studentId
			})
		);
	};

	useEffect(() => {
		/**
		 * Closes recorder view when student is changed
		 * Needed to request new tool config for new student
		 */
		setVideoRecorderOpened(false);
	}, [studentId]);

	const toggleViewInVideoRecorder = () => {
		const nextOpened = !isVideoRecorderOpened;
		setVideoRecorderOpened(nextOpened);

		if (nextOpened && !toolConfig && instructorId) {
			dispatch(externalToolConfigQuery({ courseId, studentId, instructorId, familyId }));
		}
	};

	if (scoreConfig.scoreOptions === 'noPoints') return null;
	return (
		<div className={styles.ToolContainer}>
			{showCompletedMark() && <div className={cn(styles.Symbol, styles.IsCompleteSymbol)} />}
			<div className={styles.ActivitySummary}>
				<span className={styles.QuestionType}>Video Recorder</span>
				<span>&nbsp;&mdash;&nbsp;</span>
				<AnalyticsLink variant="text-uppercase" onClick={onViewAllRecordings}>
					view all students&apos; recordings
				</AnalyticsLink>
			</div>

			<div>
				{awaitingInstructorGrade && (
					<div className={styles.NeedsGradingIcon}>
						<PriorityHigh />
					</div>
				)}
				<h2
					className={styles.QuestionBody}
					dangerouslySetInnerHTML={{
						__html: `${awaitingInstructorGrade ? 'Needs Grading: ' : ''}${element.title}`
					}}
				/>
			</div>

			{!postedAt && <ActivityHeader>{notPostedText()}</ActivityHeader>}

			{postedAt && !isGraded && (
				<ActivityHeader timestamp={postedAt} timeZone={timeZone}>
					{postedNotGradedText()}
				</ActivityHeader>
			)}

			{postedAt && isGraded && (
				<ActivityHeader timestamp={postedAt} timeZone={timeZone}>
					GRADE POSTED
				</ActivityHeader>
			)}

			{isGraded && (
				<div className={styles.GradeBlock}>
					<strong>Grade:</strong> {grade}%
				</div>
			)}

			{postedAt && (
				<button className={styles.Button} onClick={toggleViewInVideoRecorder}>
					{isVideoRecorderOpened
						? 'Close Video Recorder'
						: `View ${awaitingInstructorGrade ? 'and Grade ' : ''}in Video Recorder`}
				</button>
			)}
			{isVideoRecorderOpened && (
				<>
					{toolConfig ? (
						<div className={styles.VideoRecorderContainer}>
							<GoReactIframe
								familyId={familyId}
								launchURL={toolConfig.launchURL}
								launchParams={toolConfig.launchParams}
								className={styles.VideoRecorder}
							/>
						</div>
					) : (
						<div className={styles.VideoRecorderLoader}>
							<CircularProgress />
						</div>
					)}
				</>
			)}
		</div>
	);
};

const getAnswerGrade = (answer: AnswerActivity): number | null => {
	const answerBody = JSON.parse(answer.body);
	if (!answerBody.instructorPoints) return null;

	const { points, possiblePoints } = answerBody.instructorPoints;
	return points && possiblePoints && Math.round((points / possiblePoints) * 100.0);
};

/**
 * - `points = null` — instructor hasn't graded yet
 * - `points = 0` — instructor set 0 points for the assignment
 */
export const getAnswerGraded = (activity: number | AnswerActivity | null): boolean => {
	if (activity === null) return false;

	const gradeValue = typeof activity === 'number' ? activity : getAnswerGrade(activity);
	return !!gradeValue || gradeValue === 0;
};

export default GoReactQuestionView;
