export class WebtextBuilder {
	constructor({
		config,
		courseData,
		courseDecisions,
		userDecisions,
		userID,
		dependentBuilderPrompts,
		elementContext
	}) {
		this._config = config;
		this.courseData = courseData;
		this.elementContext = elementContext;
		this.courseDecisions = courseDecisions;
		this.userDecisions = userDecisions;
		this.userID = userID;
		this.citationStyle = userDecisions.citation_style;

		this._promptDestinations = {};
		this._outlineDestinations = {};
		this._dependentBuilderPrompts = dependentBuilderPrompts;

		Object.keys(this._dependentBuilderPrompts).forEach((key) => {
			this._buildPromptItem(this._dependentBuilderPrompts[key], {
				presentation: 'block',
				fontSize: 1
			});
		});

		this.config = this._buildConfig(config);

		this.importedCourseDecisions = [];
		this.importedConstants = {};

		Object.keys(this.config.imports || {}).forEach((importName) => {
			const [importType, importRef] = this.config.imports[importName].split(':', 2);
			switch (importType) {
				case 'course_decisions':
					this.importedCourseDecisions.push(importRef);
					break;
				case 'constant':
					this.importedConstants[importName] = importRef;
					break;
				default:
				// do nothing. likely a builder import
			}
		});

		if (!this.citationStyle) {
			if (this.importedConstants.citation_style) {
				this.citationStyle = this.importedConstants.citation_style;
			} else if (courseDecisions.citation_style) {
				this.citationStyle = courseDecisions.citation_style.selected_citation_style;
			}
		}
	}

	getCitationMetaData(citationItem, sourceList) {
		const source = sourceList.filter((s) => s['options-value'] === citationItem.key)[0];
		return source['supported-styles'][this.citationStyle];
	}

	getSourceFromDestination(item) {
		return (
			this._promptDestinations[item.source] ||
			this._outlineDestinations[item.source] ||
			this._dependentBuilderPrompts[item.source]
		);
	}

	getReferencedValue(source) {
		const [refHead, refTail] = source.split('.', 2);

		if (this.userDecisions[refHead]) {
			// refHead is decision name, refTail is key in option object.
			return this.getDecisionOption(refHead)[refTail];
		} else if (this.courseData && this.courseData[refHead]) {
			return this.courseData[refHead];
		} else {
			return refHead;
		}
	}

	// Public:
	getDecisionOption(decisionName) {
		const userDecision = this.userDecisions[decisionName];
		const decisionOptions = this.courseDecisions[decisionName].options;
		const decisionOption = decisionOptions.filter((option) => option.key === userDecision)[0];
		return decisionOption;
	}

	citationItemAttributes(item, citationItem) {
		const source = item.citationSources.filter(
			(source) => source['options-value'] === citationItem.key
		)[0];
		const sourceStyles = source['supported-styles'][this.citationStyle].styles || [];

		return sourceStyles.map(function (style) {
			const key = Object.keys(style)[0];
			return { key, value: style[key] };
		});
	}

	showPageNumber(item, citationItem) {
		if (!citationItem.key) return false;

		if (this.citationStyle === 'turabian') {
			const turabianStyle = this.getCitationMetaData(citationItem, item.citationSources);
			return this._hasPagePlaceholder(turabianStyle['note-text']);
		} else if (this.citationStyle === 'apa') {
			if (!citationItem.attributes) return false;

			const apaStyleName = citationItem.attributes.style;
			if (apaStyleName.indexOf('-page') > 0) {
				const apaMeta = this.getCitationMetaData(citationItem, item.citationSources);
				const selectedStyle = apaMeta.styles.filter((style) => !!style[apaStyleName])[0];
				return this._hasPagePlaceholder(selectedStyle[apaStyleName]);
			}

			delete citationItem.attributes['page-number']; // ???
		}

		return false;
	}

	_hasPagePlaceholder(text) {
		return text.match(/x{2,}|_{2,}/g);
	}

	isParagraphAllowed(item) {
		// We are trusting stored values of dependent builders.
		if (item.source && item.source.indexOf('-') > -1) return true;

		const sourceItem = this.getSourceFromDestination(item);
		if (!sourceItem) return false;

		return sourceItem.redactorEnabled && sourceItem.options.editor['allow-paragraphs'];
	}

	findItemsForKind(kind, items) {
		let foundItems = [];

		items.forEach((item) => {
			switch (item.kind) {
				case 'list':
					foundItems = foundItems.concat(this.findItemsForKind(kind, item.items));
					break;
				case 'item':
					foundItems = foundItems.concat(this.findItemsForKind(kind, item.elements));
					break;
				case 'para':
					foundItems = foundItems.concat(this.findItemsForKind(kind, item.items));
					break;
				case kind:
					foundItems.push(item);
					break;
			}
		});

		return foundItems;
	}

	_buildConfig(rawConfig) {
		const config = JSON.parse(JSON.stringify(rawConfig)); // deep-cloned.

		config.headingText || (config.headingText = 'Writing Template');

		config.buttons || (config.buttons = {});
		config.buttons.saveDraft || (config.buttons.saveDraft = 'Save Progress');
		config.buttons.save || (config.buttons.save = 'Save');

		config.draftTitleMessage = `To complete this template, you must click the "${config.buttons.save}" button.`;
		config.draftBodyMessage =
			'If the button cannot be clicked, make sure you have responded to all prompts and completed all citations in the template.';

		config.prompts || (config.prompts = []);
		config.prompts.forEach((prompt, idx) => {
			if (Array.isArray(prompt)) {
				config.prompts[idx] = prompt = { items: prompt };
			}

			const presentation = prompt.presentation;
			const fontSize = prompt['font-size'] || 1;
			prompt.items.forEach((item) => {
				this._buildPromptItem(item, { presentation, fontSize });
			});
		});

		if (config.buttons.docx) {
			config.docx = config.buttons.docx;
			if (typeof config.buttons.docx.template === 'object') {
				config.docx.templateUrl = config.docx.template[this.citationStyle];
			} else {
				config.docx.templateUrl = config.docx.template;
			}
			delete config.docx.template;
			config.buttons.docx = config.docx.label;
		}

		if (config.output.outline) {
			this._mapOutlineDestinations(config.output.outline);
		}

		return config;
	}

	_buildPromptItem(item, props) {
		item.style || (item.style = {});

		if (props.presentation === 'inline') {
			item.style = { ...item.style, fontSize: props.fontSize * 100 + '%', margin: '1px' };
		} else if (item.validation) {
			const wordCount = item.validation['word-count'];
			let words = 0;
			if (wordCount.optimum) {
				words = wordCount.optimum;
			} else if (wordCount.minimum && wordCount.maximum) {
				words = (wordCount.minimum + wordCount.maximum) / 2;
			}
			item.style = { ...item.style, minHeight: words * 1.5 + 50 + 'px' };
		}

		if (item.kind === 'fill-in-with-citations') {
			item.citationsEnabled = true;
		} else if (item.kind === 'fill-in' && item.options) {
			if (item.options.citations) {
				item.citationsEnabled = item.options.citations.enabled;
				item['source-list-ref'] = item.options.citations['source-list-ref'];
			}
			if (item.options.editor) {
				item.redactorEnabled = item.options.editor.type === 'richtext';
			}
		}

		if (item.citationsEnabled) {
			item.citationSources = this.getReferencedValue(item['source-list-ref']);
		}

		if (item.dest) {
			this._promptDestinations[item.dest] = item;
		}
	}

	_mapOutlineDestinations(items) {
		items.forEach((item) => {
			if (item.items) {
				this._mapOutlineDestinations(item.items);
			} else if (item.elements) {
				this._mapOutlineDestinations(item.elements);
			} else if (item.dest) {
				this._outlineDestinations[item.dest] = item;
			}
		});
	}
}
