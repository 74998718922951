import { mutateAsync, mergeData } from './';

export default ({ courseId, recipientId, thingFamilyId, rawCommentBody, commentAuthorName }) =>
	mutateAsync({
		path: '/api/courses/v1/comments',
		params: {
			course_id: courseId,
			recipient_id: recipientId,
			thing_family_id: thingFamilyId,
			comment_body: rawCommentBody
		},
		transform: (comment) => ({
			webtextComments: {
				[`${recipientId}:${thingFamilyId}`]: {
					body: comment.comment_body,
					raw_body: comment.raw_comment_body,
					first_saved_at: comment.first_saved_at,
					last_saved_at: comment.last_saved_at,
					author_name: comment.author_name,
					seen_at: comment.comment_seen_at
				}
			}
		}),
		update: {
			webtextComments: mergeData
		},
		optimisticUpdate: {
			webtextComments: (prev) => {
				const key = `${recipientId}:${thingFamilyId}`;
				const lastSavedAt = new Date();
				const firstSavedAt = prev[key] ? prev[key].first_saved_at : lastSavedAt;
				return {
					...prev,
					[key]: {
						body: rawCommentBody,
						raw_body: rawCommentBody,
						first_saved_at: firstSavedAt,
						last_saved_at: lastSavedAt,
						author_name: commentAuthorName
					}
				};
			}
		}
	});
