import styles from './TocMenu.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { colors } from 'Theme';
import ViewSelectorFilter from 'Components/ViewSelectorFilter';
import { setTocFilters } from 'Actions/tocActions';
import { tocFilterOptions, tocFilterLabels } from './TocFilters';

const theme = createTheme({
	typography: {
		useNextVariants: true
	},
	palette: {
		primary: {
			main: colors.blue
		},
		secondary: {
			main: colors.lighterBlue
		}
	}
});

class TocFilterListItem extends React.Component {
	static propTypes = {
		tocFilters: PropTypes.array,
		setTocFilters: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.state = {
			popoverOpen: false
		};
	}

	onPopoverOpenChange = (open) => {
		this.setState({ popoverOpen: open });
	};

	onFilterChange = (event, checked) => {
		const { tocFilters, setTocFilters } = this.props;
		const nextTocFilters = tocFilters.concat();
		const filter = event.target.value;
		const filterIdx = tocFilters.indexOf(filter);

		if (checked) {
			if (filterIdx === -1) {
				nextTocFilters.push(filter);
			}
		} else {
			if (filterIdx !== -1) {
				nextTocFilters.splice(filterIdx, 1);
			}
		}

		setTocFilters({ filters: nextTocFilters });
		this.setState({ popoverOpen: false });
	};

	renderFilterFormControl = ({ filter }) => {
		const { tocFilters } = this.props;
		return (
			<ThemeProvider key={filter} theme={theme}>
				<FormControlLabel
					classes={{ root: styles.TocFilterFormControlLabel }}
					control={
						<Checkbox
							classes={{ root: styles.TocFilterCheckbox }}
							checked={tocFilters.indexOf(filter) !== -1}
							onChange={this.onFilterChange}
							value={filter}
						/>
					}
					label={tocFilterLabels[filter]}
				/>
			</ThemeProvider>
		);
	};

	clearTocFilters = () => {
		this.props.setTocFilters({ filters: [] });
		this.setState({ popoverOpen: false });
		return false;
	};

	render() {
		const { tocFilters } = this.props;
		const { popoverOpen } = this.state;

		let viewing = 'All';
		if (tocFilters.length) {
			viewing = tocFilterLabels[tocFilters[0]];
		}
		if (tocFilters.length > 1) {
			viewing += ', ...';
		}

		return (
			<ListItem key="TocFilterListItem" classes={{ root: styles.TocFilterListItem }}>
				<ViewSelectorFilter
					label={`Viewing: ${viewing}`}
					content={
						<>
							<a
								href="#"
								className={styles.TocFilterClearLink}
								onClick={this.clearTocFilters}
								disabled={!tocFilters.length}>
								See all pages
							</a>
							<h2 className={styles.TocFilterContentHeading}>See only pages with:</h2>
							{tocFilterOptions.map((option) =>
								this.renderFilterFormControl({ filter: option[0], label: option[1] })
							)}
						</>
					}
					open={popoverOpen}
					onOpenChange={this.onPopoverOpenChange}
				/>
			</ListItem>
		);
	}
}

const mapStateToProps = (state) => ({
	tocFilters: state.toc.filters
});

const mapDispatchToProps = (dispatch) => ({
	setTocFilters: ({ filters }) => {
		dispatch(setTocFilters({ filters }));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TocFilterListItem);
