import React, { Component, Fragment } from 'react';
import { object, array, string, number, func, bool } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import uniq from 'lodash.uniq';
import pageViewStyles from './PageView.scss';
import { GeoMapFigure, QuestionDeck, Spacer } from '@soomo/lib/components/pageElements';
import { joinWithOxfordComma } from '@soomo/lib/utils/formatting';
import { isReviewTemplate } from '@soomo/lib/components/WritingTemplate/WritingTemplate/utils';
import themes from '@soomo/lib/styles/themes';

import pageActivityQuery from 'Requests/PageActivityQuery';
import metricsQuery from 'Requests/MetricsQuery';
import elementActivityQuery from 'Requests/ElementActivityQuery';
import elementsActivityQuery from 'Requests/ElementsActivityQuery';
import resetMCAnswersMutation from 'Requests/ResetMCAnswersMutation';
import ConfirmDialog from 'Components/ConfirmDialog';
import RaisedButton from 'Components/RaisedButton';
import ButtonSelect from 'Components/ButtonSelect';
import TextLoadingIndicator from 'Components/TextLoadingIndicator';
import TimingCell from 'Components/GridCells/TimingCell';
import { NoMatchingStudentsSelector } from 'Containers/StudentList/StudentsSelector';
import * as uiActions from 'Actions/uiActions';
import { isEmpty } from 'Utils/objects';
import { formatTime, formatDuration } from 'Utils/format';

import PageViewNoMatchingStudents from './PageViewNoMatchingStudents';
import WebtextContentView from './WebtextContentView';
import * as Elements from './Elements';
import { buildAnswerForLibsComponent as buildMCAnswerForLibsComponent } from './Elements/MCQuestion';
import { getBuilderConfig } from '../../components/WebtextBuilderReview/WebtextBuilderReview';

class PageView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inContext: true,
			confirmResetDialogVisible: false
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	shouldComponentUpdate() {
		return !!this.props.page.id;
	}

	componentDidUpdate() {
		this.fetchData();
	}

	fetchData = () => {
		const { dispatch, course, user, page, elements } = this.props;
		if (!user) return;
		if (page.id === null) return;

		// Must ensure we have all comment data so that we can filter
		// the student selector list. This will later need to be updated
		// to include RB and SA questions, but only once we begin
		// filtering on them as well.
		elements.forEach((element) => {
			if (element.type === 'interactive_template') {
				dispatch(elementActivityQuery({ courseId: course.id, elementId: element.id }));
			}
		});

		dispatch(
			elementsActivityQuery({
				courseId: course.id,
				userId: user.id,
				pageId: page.id,
				elementIds: page.element_ids
			})
		);
		dispatch(pageActivityQuery({ courseId: course.id, userId: user.id, pageId: page.id }));
	};

	confirmResetMCAnswers = () => {
		this.setState({ confirmResetDialogVisible: true });
	};

	resetMCAnswers = () => {
		const { course, user, chapter, page, resetMCAnswers } = this.props;
		resetMCAnswers({ course, user, chapter, page });
	};

	backToGridView = () => {
		this.props.uiActions.updateCurrentViewParams({
			scope: this.props.gridViewScope,
			student: null
		});
	};

	showAnalyticsView = () => {
		this.props.uiActions.updateCurrentViewParams({ showInContext: false });
	};

	showContextView = () => {
		this.props.uiActions.updateCurrentViewParams({ showInContext: true });
	};

	render() {
		const {
			course,
			toc,
			user,
			page,
			pageActivity,
			lowTimingCutoff,
			highTimingCutoff,
			policyDocument,
			elements,
			elementsActivity,
			timeZone,
			inContext,
			highlightTiming,
			noMatchingStudents,
			scrollTo,
			isLoading
		} = this.props;

		if (page.id === null) return null; // can happen when transitioning to grid view
		if (noMatchingStudents) return <PageViewNoMatchingStudents />;

		const loadingIndicator = <TextLoadingIndicator color="grey2" />;
		const webTimeSpent = pageActivity ? pageActivity.web_time_spent : 0;
		const mobileTimeSpent = pageActivity ? pageActivity.mobile_time_spent : 0;
		const timeSpent = webTimeSpent + mobileTimeSpent;

		const questionsCompleted = pageActivity ? pageActivity.questions_completed : 0;
		const questionsPossible = page.questions_possible;
		const questionsGraded = pageActivity ? pageActivity.questions_graded : 0;

		const questionsCorrect = pageActivity ? pageActivity.questions_correct : 0;
		const questionsScored = page.question_counts.mc + page.question_counts.mcqp;
		const questionsGradable = page.question_counts.instructor_grade_external_tool;

		const lastSavedText = user
			? pageActivity
				? pageActivity.last_saved_at
					? formatTime({ time: pageActivity.last_saved_at, timeZone })
					: questionsPossible > 0
					? 'Never'
					: 'N/A'
				: loadingIndicator
			: 'N/A';

		const activityText = user
			? pageActivity
				? formatDuration({ duration: timeSpent, minuteLabel: ' min' })
				: loadingIndicator
			: 'N/A';

		const completedText = user
			? pageActivity
				? `${questionsCompleted}/${questionsPossible}`
				: loadingIndicator
			: 'N/A';

		const scoreText = user
			? pageActivity
				? `${questionsCorrect}/${questionsScored}`
				: loadingIndicator
			: 'N/A';

		const gradedText = user
			? pageActivity
				? `${questionsGraded}/${questionsGradable}`
				: loadingIndicator
			: 'N/A';

		const headerButtons = (
			<div className={pageViewStyles.HeaderButtonsContainer}>
				<ButtonSelect
					options={['Questions', 'In Context']}
					value={inContext ? 'In Context' : 'Questions'}
					onSelect={(value) => {
						value === 'In Context' ? this.showContextView() : this.showAnalyticsView();
					}}
				/>
			</div>
		);

		const lowTime = lowTimingCutoff && timeSpent < lowTimingCutoff;
		const highTime = highTimingCutoff && timeSpent > highTimingCutoff;

		const showHighlightedTiming = highlightTiming && (lowTime || highTime) && timeSpent > 0;

		const header = (
			<div className={pageViewStyles.Header}>
				<div className={pageViewStyles.HeaderItem}>
					<span className={pageViewStyles.HeaderItemLabel}>Last Update:</span> {lastSavedText}
				</div>
				<div className={pageViewStyles.HeaderItem}>
					<span className={pageViewStyles.HeaderItemLabel}>Activity:</span>
					{showHighlightedTiming && (
						<span style={{ display: 'inline-block', marginTop: '-4px', marginLeft: '4px' }}>
							<TimingCell
								timeSpent={timeSpent}
								lowTime={lowTime}
								highTime={highTime}
								minuteLabel=" min"
								loaded={true}
							/>
						</span>
					)}
					{!showHighlightedTiming && <span> {activityText}</span>}
				</div>
				<div className={pageViewStyles.HeaderItem}>
					<span className={pageViewStyles.HeaderItemLabel}>Completed:</span> {completedText}
				</div>
				{questionsScored > 0 && (
					<div className={pageViewStyles.HeaderItem}>
						<span className={pageViewStyles.HeaderItemLabel}>Score:</span> {scoreText}
					</div>
				)}
				{questionsGradable > 0 && (
					<div className={pageViewStyles.HeaderItem}>
						<span className={pageViewStyles.HeaderItemLabel}>Graded:</span> {gradedText}
					</div>
				)}
				{headerButtons}
			</div>
		);

		let anyMCQuestions = false;
		elements.forEach(function (element) {
			if (element.type === 'mc_question' || element.type === 'mc_question_pool') {
				anyMCQuestions = true;
			}
		});

		const missingDependenciesList = getMissingDependenciesList({ page, user });
		const missingDependencies = missingDependenciesList.length > 0;

		const buttons = (
			<div className={pageViewStyles.Buttons}>
				<RaisedButton label="Back to Overview" onClick={this.backToGridView} />
				{anyMCQuestions && user && (
					<RaisedButton
						label="Reset All Multiple-Choice Questions on This Page"
						disabled={missingDependencies}
						onClick={this.confirmResetMCAnswers}
					/>
				)}
			</div>
		);

		let items;
		let decisionsPresent = false;

		const elementContainerStyle = {
			maxWidth: themes[toc.theme].layout.contentColumnWidth
		};

		if (missingDependencies) {
			items = [];
		} else if (!user) {
			// Possible situation when we click on the 'View all responses'.
			// It sets 'student' property to null (userId), therefore undefined user will be returned from the store
			items = [];
		} else {
			let context = [];
			let containers = [];
			let currentContainer;
			items = elements.map((element, idx) => {
				const activity = elementsActivity ? elementsActivity.elements[element.id] : null;
				let content;

				switch (element.type) {
					case 'sa_question':
						content = (
							<Elements.SAQuestion
								user={user}
								question={element}
								activity={activity}
								inContext={inContext}
							/>
						);
						break;
					case 'mc_question':
						if (currentContainer && currentContainer.type === 'question_deck') {
							context[context.length - 1].questions.push({
								...element,
								family_id: element.id,
								choices: element.choices.map((choice) => ({
									...choice,
									family_id: choice.id
								}))
							});

							context[context.length - 1].answers[element.id] = buildMCAnswerForLibsComponent({
								page,
								question: element,
								activity,
								policyDocument
							});
						} else {
							content = (
								<Elements.MCQuestion question={element} activity={activity} inContext={inContext} />
							);
						}
						break;
					case 'question_deck':
						if (inContext) {
							if (element.direction === 'in') {
								currentContainer = element;
								context.push({ questions: [], answers: {} });
								containers.push(currentContainer);
							} else if (currentContainer != null) {
								const { questions, answers } = context[context.length - 1];

								const questionDeckTypes = uniq(questions.map((q) => q.type));

								switch (true) {
									case questionDeckTypes.includes('mc_question'):
										content = (
											<QuestionDeck
												questions={questions}
												answers={answers}
												onSubmit={() => undefined}
												onChangeSelectedChoice={() => undefined}
												showInstructorView={false}
												readOnly={true}
												seed={null}
											/>
										);
										break;

									case questionDeckTypes.includes('mc_question_pool'):
										content = (
											<Elements.MCQuestionPoolQuestionDeck
												deckFamilyId={currentContainer.family_id}
												questions={questions}
											/>
										);
										break;

									case questionDeckTypes.includes('poll_question'):
									case questionDeckTypes.includes('poll_results'):
										content = (
											<Elements.PollsDeck
												familyId={element.family_id}
												pollElementsProps={questions}
												pollResultDisplayStyle={currentContainer.poll_alias_result_display_style}
											/>
										);
										break;
									default:
										(window.Rollbar ?? console).error(
											`Unrecognized element type in QuestionDeck: ${questionDeckTypes}`
										);
										break;
								}

								containers.pop();
								context.pop();
								currentContainer = containers[containers.length - 1];
							}
						}
						break;
					case 'poll_question':
						if (currentContainer && currentContainer.type === 'question_deck') {
							context[context.length - 1].questions.push(element);
						} else {
							content = (
								<Elements.PollQuestion
									question={element}
									activity={activity}
									inContext={inContext}
								/>
							);
						}
						break;
					case 'poll_results':
						if (currentContainer && currentContainer.type === 'question_deck') {
							context[context.length - 1].questions.push(element);
						} else {
							content = inContext ? <Elements.PollResultsAlias question={element} /> : null;
						}

						break;
					case 'response_board':
						content = (
							<Elements.ResponseBoard
								user={user}
								question={element}
								activity={activity}
								inContext={inContext}
							/>
						);
						break;
					case 'interactive_template':
						if (inContext) {
							content = (
								<Elements.WritingTemplate
									user={user}
									course={course}
									toc={toc}
									page={page}
									element={element}
								/>
							);
						} else {
							/**
							 * If there are no interactive items in the `Builder` they serve only the visual reminder purpose
							 * Their presence in the Question View is redundant as there's nothing to react to
							 */
							if (isReviewTemplate(getBuilderConfig(element, user.decisions))) {
								content = null;
							} else {
								content = (
									<Elements.InteractiveTemplate
										toc={toc}
										interactiveTemplate={element}
										activity={activity}
									/>
								);
							}
						}
						break;
					case 'self_assessment':
						content = (
							<Elements.SelfAssessment
								user={user}
								selfAssessment={element}
								activity={activity}
								inContext={inContext}
							/>
						);
						break;
					case 'text':
						content = inContext ? (
							<Elements.Text
								text={element}
								containerType={currentContainer?.container_classes ? 'nesting' : undefined}
								citationReferences={page.citation_references}
								activity={activity}
							/>
						) : null;
						break;
					case 'figure':
						content = inContext ? <Elements.Figure figure={element} activity={activity} /> : null;
						break;
					case 'chart':
						content = inContext ? (
							<>
								<Elements.Chart chart={element} activity={activity} />
								<Spacer key={`spacer-${element.family_id}`} />
							</>
						) : null;
						break;
					case 'artifact_link':
						content = inContext ? (
							<Elements.ArtifactLink artifact={element} activity={activity} />
						) : null;
						break;
					case 'external_tool':
						content =
							element?.score_config?.scoreOptions === 'noPoints' && !inContext ? null : (
								<Elements.ExternalTool
									external_tool={element}
									activity={activity}
									inContext={inContext}
								/>
							);
						break;
					case 'decision':
						decisionsPresent = true;
						content = <Elements.Decision decision={element} user={user} inContext={inContext} />;
						break;
					case 'nesting_instruction':
						if (element.direction === 'in') {
							currentContainer = element;
							context.push([]);
							containers.push(currentContainer);
						} else if (currentContainer != null) {
							content = inContext ? (
								<Elements.Nesting
									nestingInstructionClasses={currentContainer.container_classes}
									nestingInstructionElements={context[context.length - 1]}
								/>
							) : (
								context[context.length - 1]
							);
							containers.pop();
							context.pop();
							currentContainer = containers[containers.length - 1];
						}
						break;
					case 'voice_recording':
						content = (
							<Elements.VoiceRecording
								voiceRecording={element}
								user={user}
								activity={activity}
								inContext={inContext}
							/>
						);
						break;
					case 'timeline':
						content = inContext ? <Elements.Timeline element={element} /> : null;
						break;
					case 'geo_map':
						content = inContext ? (
							<div style={{ margin: '20px 0' }}>
								<GeoMapFigure figure={{ ...element, version: `${element.version}` }} />
							</div>
						) : null;
						break;
					case 'mc_question_pool':
						if (currentContainer && currentContainer.type === 'question_deck') {
							context[context.length - 1].questions.push(element);
						} else {
							content = (
								<Elements.MCQuestionPool
									element={element}
									activity={activity}
									inContext={inContext}
								/>
							);
						}
						break;
					case 'ai_chatbox':
						if (inContext) {
							content = <Elements.AiChatbox element={element} />;
						}
						break;
					default:
						(window.Rollbar ?? console).error(`Unrecognized element type: ${element.type}`);
						content = <Elements.UnknownElement element={element} />;
				}

				// We don't want to include the wrappered elements now, since they'll get
				// represented when we process the outdent
				if (
					currentContainer &&
					currentContainer.type === 'nesting_instruction' &&
					element.type !== 'nesting_instruction' &&
					content
				) {
					context[context.length - 1].push(<Fragment key={element.id}>{content}</Fragment>);
					return null;
				}

				const className = idx % 2 === 0 ? 'odd' : 'even';
				const style = {
					display: 'block',
					margin: 0,
					listStyle: 'none'
				};

				if (!inContext) {
					style.padding = '20px';
					style.borderBottom = '1px solid lightgrey';
				}

				if (inContext) {
					if (idx === 0) {
						style.paddingTop = '20px';
					}
					if (idx === elements.length - 1) {
						style.paddingBottom = '80px';
					}
				}

				return !isEmpty(content) ? (
					typeof content !== 'string' ? (
						<li
							key={element.id}
							className={className}
							style={style}
							data-test="page-view-list-item"
							data-question-type={element.type}>
							<div style={elementContainerStyle}>{content}</div>
						</li>
					) : (
						content
					) // to be replaced later
				) : null;
			});

			items = items.filter(Boolean);
		}

		const onDialogClose = () => {
			this.setState({ confirmResetDialogVisible: false });
		};

		const dialog = (
			<ConfirmDialog
				open={this.state.confirmResetDialogVisible}
				onConfirm={this.resetMCAnswers}
				onClose={onDialogClose}>
				<p>
					{`Resetting will clear the answers for the multiple-choice questions on this page and allow the student to try again. Students are notified of all question resets via email. (To reset short-answer or response-board questions, use the "Unpost" option beneath the question or from the "View all responses" screen.)`}
				</p>
				<p>Would you like to reset all multiple-choice questions on the page for this student?</p>
			</ConfirmDialog>
		);

		return (
			<div
				style={{ width: '100%', background: 'white', display: 'flex', flexFlow: 'column nowrap' }}>
				{dialog}
				{header}
				{buttons}
				{missingDependencies ? (
					<p className={pageViewStyles.MissingDependenciesMessage}>
						{inContext
							? `Content on this page can only be shown once the student has chosen their ${missingDependenciesList}.`
							: `Questions on this page can only be shown once the student has chosen their ${missingDependenciesList}. Visit this page in the webtext to learn more.`}
					</p>
				) : (
					<>
						{!inContext && !items.length && !decisionsPresent ? (
							<h2 className={pageViewStyles.NoInteractiveContent}>
								This page contains no interactive content.
							</h2>
						) : (
							<WebtextContentView
								theme={toc.theme}
								inContext={inContext}
								scrollTo={scrollTo}
								isLoading={isLoading}>
								{items}
							</WebtextContentView>
						)}
					</>
				)}
			</div>
		);
	}
}

PageView.propTypes = {
	api: object.isRequired,
	webtexts: object.isRequired,
	course: object.isRequired,
	toc: object.isRequired,
	chapter: object.isRequired,
	page: object.isRequired,
	elements: array.isRequired,
	user: object,
	pageActivity: object,
	elementsActivity: object,
	lowTimingCutoff: number,
	highTimingCutoff: number,
	policyDocument: object,
	featureFlags: object,
	uiActions: object.isRequired,
	resetMCAnswers: func.isRequired,
	timeZone: string.isRequired,
	inContext: bool.isRequired,
	scrollTo: string,
	gridViewScope: string,
	highlightTiming: bool,
	noMatchingStudents: bool,
	dispatch: func
};

export const getMissingDependenciesList = ({ page, user }) => {
	const pageDependencies = {};
	page.internal_dependencies.forEach((dep) => {
		pageDependencies[dep] = true;
	});
	page.external_dependencies.forEach((dep) => {
		pageDependencies[dep] = true;
	});
	Object.keys(user ? user.decisions : {}).forEach((dep) => {
		if (user.decisions[dep] && user.decisions[dep].length) {
			delete pageDependencies[dep];
		}
	});
	const missingDependenciesList = joinWithOxfordComma(
		Object.keys(pageDependencies).map((dep) => dep.replace('_', ' ').toLowerCase())
	);

	return missingDependenciesList;
};

const mapStateToProps = (state) => {
	const { student: userId, scope: pageId } = state.ui.currentViewParams;

	const api = state.data.api;
	const webtexts = state.data.webtexts;
	const course = state.entities.course;
	const toc = state.entities.toc;
	const user = state.entities.users[userId];
	const page = state.entities.pages[pageId] || { id: null };
	const chapter = page.id ? state.entities.chapters[page.chapter_id] : { id: null };
	const pageActivity = state.entities.pageActivities[userId + ':' + pageId];
	const lowTimingCutoff = state.entities.courseLowTimingCutoffs[pageId];
	const highTimingCutoff = state.entities.courseHighTimingCutoffs[pageId];
	const policyDocument = state.entities.course.policy_document;
	const featureFlags = state.entities.featureFlags;
	const _elements = page.id ? page.element_ids.map((id) => state.entities.elements[id]) : [];
	const timeZone = course.time_zone;
	const inContext = state.ui.currentViewParams.showInContext;
	const scrollTo = state.ui.currentViewParams.scrollTo;
	const gridViewScope = state.ui.currentViewParams.gridViewScope;
	const highlightTiming = state.ui.highlightTiming;
	const noMatchingStudents = NoMatchingStudentsSelector(state);

	const elementsActivity = { elements: {} };

	let elements = _elements;
	if (userId) {
		elements = [];

		_elements.forEach(function (element) {
			const answerIds = state.entities.elementAnswerIds[userId + ':' + element.id];
			const answers = answerIds ? answerIds.map((id) => state.entities.answers[id]) : null;
			elementsActivity.elements[element.id] = { answers };

			if (Array.isArray(element.depends_on) && element.depends_on.length) {
				const updatedElement = { ...element };
				const decisionName = element.depends_on[0];
				const userDecision = user.decisions[decisionName];
				if (userDecision) {
					Object.keys(element.dictionary).forEach((key) => {
						updatedElement[key] = element.dictionary[key][userDecision];
					});
				}
				if (element.choices) {
					element.choices.forEach(function (choice, choiceIdx) {
						if (Array.isArray(choice.depends_on) && choice.depends_on.length) {
							const updatedChoice = { ...choice };
							const decisionName = choice.depends_on[0];
							const userDecision = user.decisions[decisionName];
							if (userDecision) {
								Object.keys(choice.dictionary).forEach((key) => {
									updatedChoice[key] = choice.dictionary[key][userDecision];
								});
							}
							updatedElement.choices[choiceIdx] = updatedChoice;
						}
					});
				}
				elements.push(updatedElement);
			} else {
				elements.push(element);
			}
		});
	}

	/**
	 * This can be determined by looking at src/containers/Views/Elements and seeing which elements `return null`
	 * if their data is not present
	 */
	const elementsThatNeedAdditionalData = elements.filter(
		(element) =>
			element.type === 'mc_question_pool' ||
			(element.type === 'interactive_template' &&
				user?.decisions &&
				!isReviewTemplate(getBuilderConfig(element, user.decisions)))
	);
	const dataWeNeed = elementsThatNeedAdditionalData.map((e) => {
		switch (e.type) {
			case 'interactive_template':
				return state.entities.webtextBuilders[`${user.id}:${e.id}`];
			case 'mc_question_pool':
				return elementsActivity.elements[e.id].answers;
		}
	});
	const isLoading = dataWeNeed.some((e) => !e);

	return {
		api,
		webtexts,
		course,
		toc,
		user,
		chapter,
		page,
		pageActivity,
		lowTimingCutoff,
		highTimingCutoff,
		policyDocument,
		featureFlags,
		elements,
		elementsActivity,
		timeZone,
		inContext,
		scrollTo,
		gridViewScope,
		highlightTiming,
		noMatchingStudents,
		isLoading
	};
};

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uiActions: bindActionCreators(uiActions, dispatch),
	resetMCAnswers: ({ course, user, chapter, page }) =>
		dispatch(
			resetMCAnswersMutation({ courseId: course.id, userId: user.id, pageId: page.id })
		).then(() => {
			dispatch(
				pageActivityQuery({ courseId: course.id, userId: user.id, pageId: page.id, force: true })
			);
			dispatch(
				metricsQuery({
					courseId: course.id,
					userIds: [user.id],
					scopeId: chapter.id,
					groupby: 'page',
					force: true
				})
			);
		})
});

export default connect(mapStateToProps, mapDispatchToProps)(PageView);
