import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withCourseScopedQuery } from 'Requests';
import userActivityQuery from 'Requests/UserActivityQuery';
import LabeledLine from './LabeledLine';
import TextLoadingIndicator from 'Components/TextLoadingIndicator';
import { formatDate, formatTime, formatDuration } from 'Utils/format';

class StudentNameCellHover extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		activity: PropTypes.object,
		timeZone: PropTypes.string
	};

	render() {
		const { user, activity, timeZone } = this.props;

		const timeSpent = activity ? activity.web_time_spent + activity.mobile_time_spent : null;

		const loadingIndicator = <TextLoadingIndicator color="darkWhite" size={5} />;

		const enrolledText = activity
			? formatDate({ date: activity.enrolled_at, timeZone })
			: loadingIndicator;

		const lastActiveText = activity
			? formatTime({ time: activity.last_activity_at, timeZone }) || <span>&mdash;</span>
			: loadingIndicator;

		const activityText = activity ? formatDuration({ duration: timeSpent }) : loadingIndicator;

		const visitedPagesText = activity
			? (activity.visited_pages_pct * 100).toFixed(0) + '%'
			: loadingIndicator;

		return (
			<div>
				<div className="header">
					<h1>
						{user.first_name} {user.last_name}
					</h1>
				</div>
				<div className="content">
					<LabeledLine label="Enrolled">{enrolledText}</LabeledLine>
					<LabeledLine label="Last Active">{lastActiveText}</LabeledLine>
					<LabeledLine label="Activity">
						<i className="ss-clock" /> {activityText}
					</LabeledLine>
					<LabeledLine label="Visited Pages">{visitedPagesText}</LabeledLine>
				</div>
				<div className="footer">
					<p className="note">
						<i className="ss-clock" /> Total time spent viewing webtext pages. Does not include time
						spent visiting third-party links.
					</p>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	user: state.entities.users[props.userId],
	activity: state.entities.userActivitiesByUserId[props.userId],
	timeZone: state.entities.course.time_zone
});

const mapPropsToQueryParams = (props) => ({ userId: props.user.id });

export default connect(mapStateToProps)(
	withCourseScopedQuery(userActivityQuery, mapPropsToQueryParams)(StudentNameCellHover)
);
