import './SearchWidget.scss';

import React from 'react';
import PropTypes from 'prop-types';

class SearchWidget extends React.PureComponent {
	static propTypes = {
		value: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		placeholder: PropTypes.string,
		disabled: PropTypes.bool
	};

	onChange = (event) => {
		this.props.onChange(event.target.value);
	};

	onClear = () => {
		this.props.onChange('');
	};

	render() {
		const { value, placeholder, disabled } = this.props;

		return (
			<div styleName="SearchWidget">
				<input
					type="search"
					value={value}
					onChange={this.onChange}
					styleName="SearchInput"
					placeholder={placeholder}
					disabled={disabled}
				/>
				{!value && <span className="ss-search" styleName="SearchIcon" />}
				{value && <span className="ss-delete" onClick={this.onClear} styleName="ClearIcon" />}
			</div>
		);
	}
}

export default SearchWidget;
