import './ErrorBoundary.scss';

import React from 'react';
import ErrorMessage from 'Components/ErrorBoundary/ErrorMessage';

export interface State {
	error: Error | null;
	errorUUID?: string;
}

class ErrorBoundary extends React.Component<{}, State> {
	constructor(props) {
		super(props);
		this.state = { error: null };
	}

	componentDidCatch(error, info) {
		const { Rollbar } = window;
		let errorUUID = 'No error UUID provided';
		if (Rollbar) {
			errorUUID = Rollbar.error(error).uuid;
		}
		this.setState({
			error,
			errorUUID
		});
	}

	render() {
		if (this.state.error) {
			return (
				<div className="error-boundary" styleName="ErrorBoundary">
					<SoomoLogo />
					<h2>Something went wrong</h2>
					<hr />
					<p>Error ID: {this.state.errorUUID}</p>
					<ErrorMessage />
				</div>
			);
		}
		return this.props.children;
	}
}

const SoomoLogo = () => (
	<svg
		styleName="SoomoLogo"
		id="soomo_logo"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 165.27 32.17">
		<title>soomo_logo</title>
		<path
			className="cls-1"
			d="M15.39,25.07c-1.18,0-4.88.09-5.33,0S9.14,24.9,9,24.9s0-.16,0-.16-.2-.12-.17-.62.06-.79.06-.79,0-3.34,0-5.43L1.43,18l-.2,0A.48.48,0,0,1,1,18L.25,18a.29.29,0,0,0-.2.12,1.12,1.12,0,0,0,0,.31s.05,5.12.05,5.12a.86.86,0,0,0,0,.19c0,.11,0,7.8,0,7.94a1.86,1.86,0,0,1,0,.39l.69,0,2,0a8.27,8.27,0,0,0,1.34.09c.51,0,1.09,0,1.59,0H8c.64,0,4.22,0,4.63,0s3.25,0,3.47,0,4.68,0,4.95,0,1.34.1,1.48.08.76-.05,1-.09a.5.5,0,0,1,.36.06.43.43,0,0,0,.19,0,1.67,1.67,0,0,0,.24-.06c.13,0,1.94,0,1.94,0a5,5,0,0,0,.08-.75,12,12,0,0,0,0-1.22,9.53,9.53,0,0,1,.05-1.43c0-.16-.08-1.05-.08-1.21a4.47,4.47,0,0,1,.05-.67,1,1,0,0,0,0-.41c0-.1,0-.83,0-1.36H25.17C24.59,25.1,16.57,25,15.39,25.07Z"
		/>
		<path
			className="cls-1"
			d="M31.52,22.78c-.06-.38,0-2.66,0-2.66s.06-1.39.06-1.57-.09-.3-.09-.45,0-.71,0-.8,0-7.14,0-7.37.05-2.71.07-4.77c-.5,0-1.06,0-1.68-.1a37.14,37.14,0,0,0-5.58.05c-2.07.09-12.64,0-12.64,0H11.3c-.14,0-1.38,0-1.54,0s-.06-.14-.06-.14a1.87,1.87,0,0,0-.34,0l-.31.11-.28,0c-.08,0,0,.16,0,.36s0,3.84,0,4,0,6.47,0,6.84c0,.11,0,.76.05,1.63l3.27,0c.34,0,5.81,0,6.56,0s4.47.05,4.68.07L24,18a3.94,3.94,0,0,0,.47,0l.28,0,1.15,0c.08,0,.09.13.13.2a4.17,4.17,0,0,1,.12,2.22,1.6,1.6,0,0,0,0,.22c0,.13.05.21.05.27s0,3.69,0,3.69,0,.24,0,.54c1.49,0,3.92,0,4.37-.07.14,0,.45,0,.85,0C31.54,23.86,31.55,22.93,31.52,22.78Z"
		/>
		<path
			className="cls-2"
			d="M26.26,20.83c0-.06,0-.14-.05-.27a1.6,1.6,0,0,1,0-.22,4.17,4.17,0,0,0-.12-2.22c0-.07-.05-.2-.13-.2l-1.15,0-.28,0A3.94,3.94,0,0,1,24,18l-.71,0c-.21,0-3.93-.07-4.68-.07s-6.22,0-6.56,0l-3.27,0c0,2.09,0,5.43,0,5.43s0,.28-.06.79.17.62.17.62-.11.16,0,.16.62.09,1.06.17,4.15,0,5.33,0,9.2,0,9.78,0H26.3c0-.3,0-.54,0-.54S26.26,20.89,26.26,20.83Z"
		/>
		<path
			className="cls-1"
			d="M44.13,28H42.81a7.49,7.49,0,0,1-1.43-.11.89.89,0,0,1-.33-.84c.07-.37-.08-3.39,0-3.61s-.07-4.74-.11-5,0-3.35.07-3.79a2.34,2.34,0,0,0,0-1.06.54.54,0,0,1,0-.48C41,13,41,10.35,41,10.35s.15-.19.26-.22A4.6,4.6,0,0,1,42,10h.63c.18,0,1.76,0,1.95,0a1.93,1.93,0,0,0,.59,0c.31,0,1.16,0,2.06,0,0-.88,0-1.54,0-1.54s0-1.36.09-1.92,0-4,0-4.42.12-2-.06-2-5,0-5.39,0L34.58.06c-.21,0-.27,0-.39,0l-1.57,0c-.21,0-.27-.06-.41-.06a1,1,0,0,0-.39.06c-.18.06-.18,0-.18,0s0,2.19-.06,2.49c0,.11,0,1.24,0,2.58a21.55,21.55,0,0,0,2.33,0c.45,0,2.5,0,2.64,0s0,2.44,0,2.8.16,4.77.11,5.16-.06,5.83-.09,6.48,0,4,0,4.15-.11,1.09-.08,1.17.17,0-.11.06a1.92,1.92,0,0,1-.59,0,6.44,6.44,0,0,0-.65,0s-2.32,0-3.66,0c0,1.77-.08,4-.08,4s-.06.92-.06,1.21.19,1.93.19,1.93l.89,0s1.23.06,2.5,0S45.63,32,45.63,32A3.14,3.14,0,0,1,46,32a1.14,1.14,0,0,1,.51.09.91.91,0,0,0,.68-.12.25.25,0,0,0,.12-.29s0-2,0-3.62C46.46,28,44.13,28,44.13,28Z"
		/>
		<path
			className="cls-2"
			d="M35.18,24.93a6.44,6.44,0,0,1,.65,0,1.92,1.92,0,0,0,.59,0c.28,0,.14,0,.11-.06s.11-1,.08-1.17,0-3.51,0-4.15,0-6.09.09-6.48-.09-4.79-.11-5.16,0-2.8,0-2.8-2.19,0-2.64,0a21.55,21.55,0,0,1-2.33,0c0,2.06,0,4.62-.07,4.77s0,7.28,0,7.37,0,.66,0,.8.09.27.09.45-.06,1.57-.06,1.57-.06,2.28,0,2.66c0,.15,0,1.08,0,2.17C32.86,24.92,35.18,24.93,35.18,24.93Z"
		/>
		<path
			className="cls-1"
			d="M56.05,23.58l-.15-7.42s-.15-5.63,0-5.85a1.82,1.82,0,0,1-.44-.11,5.52,5.52,0,0,0-.59-.15L54,10a14.15,14.15,0,0,1-2,.07,5.48,5.48,0,0,0-.88-.07H47.19c0,1.71,0,4.28.07,4.63.09.53.08,4.77.06,5.12s0,5.37,0,5.57,0,.24,0,.45,0,1.11,0,2.27h.21a9,9,0,0,0,1.18,0,1.34,1.34,0,0,1,.4,0c.11,0,3.12.07,3.24,0a2.79,2.79,0,0,1,.44,0s1.17-.11,1.39-.08.52.11.78.15a6.37,6.37,0,0,0,1-.11c.19,0,.11-.48.11-.48l0-3.2Z"
		/>
		<path
			className="cls-2"
			d="M47.29,25.75c0-.21-.06-.24,0-.45s0-5.21,0-5.57,0-4.59-.06-5.12c-.06-.35-.07-2.92-.07-4.63-.9,0-1.75,0-2.06,0a1.93,1.93,0,0,1-.59,0c-.19,0-1.77,0-1.95,0H42a4.6,4.6,0,0,0-.73.11c-.11,0-.26.22-.26.22s0,2.61-.07,2.83a.54.54,0,0,0,0,.48,2.34,2.34,0,0,1,0,1.06c-.07.44-.11,3.57-.07,3.79s.15,4.74.11,5,.11,3.24,0,3.61a.89.89,0,0,0,.33.84,7.49,7.49,0,0,0,1.43.11h1.32s2.33,0,3.14,0C47.27,26.86,47.27,25.83,47.29,25.75Z"
		/>
		<path
			className="cls-3"
			d="M72.28,21.34c-4.66,0-7.38-1.37-8.36-4.15a2.6,2.6,0,0,1-.12-.63L68,15.31a2.71,2.71,0,0,0,.12.57A3.92,3.92,0,0,0,72,18.09c1.86,0,2.84-.45,2.84-1.65s-.48-1.61-2.51-2c-1.07-.21-1.76-.3-2.78-.45-2.89-.47-5.31-1.46-5.31-4.33,0-2.63,2.9-4.75,7.85-4.75,4,0,6.16,1.35,7.35,3.32.09.12.21.33.3.48l-4.27,1.4c0-.15-.12-.27-.15-.39A3.45,3.45,0,0,0,72,8.14c-1.76,0-2.54.54-2.54,1.49s.69,1.29,3.35,1.68c.92.12,2.15.35,3,.5,2.86.54,4.42,1.86,4.42,4.51C80.29,19.91,76.7,21.34,72.28,21.34Z"
		/>
		<path
			className="cls-3"
			d="M90.17,21.31c-5.79,0-8.69-2.69-8.69-8.18s2.93-8.19,8.72-8.19,8.7,2.66,8.7,8.19S95.76,21.31,90.17,21.31Zm0-13.11c-2.18,0-3.67,1.37-3.67,4.93S88.08,18,90.17,18c2.27,0,3.71-1.37,3.71-4.89S92.32,8.2,90.2,8.2Z"
		/>
		<path
			className="cls-3"
			d="M109.2,21.31c-5.8,0-8.69-2.69-8.69-8.18s2.92-8.19,8.72-8.19,8.69,2.66,8.69,8.19S114.78,21.31,109.2,21.31Zm0-13.11c-2.18,0-3.68,1.37-3.68,4.93S107.11,18,109.2,18c2.27,0,3.7-1.37,3.7-4.89S111.35,8.2,109.23,8.2Z"
		/>
		<path
			className="cls-3"
			d="M141.25,21.16V11.72c0-1.85-.66-3.13-2.69-3.13a3.07,3.07,0,0,0-3.23,3v9.59h-4.72V11.69c0-1.85-.86-3.1-2.74-3.1a3.13,3.13,0,0,0-3.26,2.81v9.76h-5V5.09h5v2A6.82,6.82,0,0,1,129.87,5a5.18,5.18,0,0,1,4.69,2.33C135.78,5.93,137.42,5,140.44,5c3.79,0,5.8,2,5.8,6.31v9.88Z"
		/>
		<path
			className="cls-3"
			d="M156.55,21.31c-5.8,0-8.7-2.69-8.7-8.18s2.93-8.19,8.73-8.19,8.69,2.66,8.69,8.19S162.13,21.31,156.55,21.31Zm0-13.11c-2.18,0-3.68,1.37-3.68,4.93S154.46,18,156.55,18c2.27,0,3.7-1.37,3.7-4.89S158.7,8.2,156.58,8.2Z"
		/>
		<path className="cls-3" d="M64.57,31.83V25.38h.84v5.77h3.87l.28.68Z" />
		<path
			className="cls-3"
			d="M77.69,31.83V25.38H82.8l-.24.67h-4V28h3.41v.66H78.52v2.51h4l.27.68Z"
		/>
		<path
			className="cls-3"
			d="M96.27,31.83l-.6-1.48H92.41l-.59,1.48h-.89l2.64-6.45h1l2.63,6.45Zm-1.83-4.47c0-.09-.39-1.05-.4-1.18,0,.13-.4,1.09-.41,1.18l-1,2.36h2.76Z"
		/>
		<path
			className="cls-3"
			d="M110.14,31.83l-1.74-3.21h-2v3.21h-.84V25.38H109c1.41,0,2.1.62,2.1,1.64s-.56,1.48-1.75,1.56l1.77,3.25ZM108.8,26h-2.43V28h2.54c.94,0,1.29-.39,1.29-1S109.79,26,108.8,26Z"
		/>
		<path
			className="cls-3"
			d="M124.09,31.83l-3.23-4.61c0-.11-.44-.73-.44-.79v5.4h-.82V25.38h1L123.9,30c0,.06.35.66.37.73s0-.74,0-.79V25.38h.83v6.45Z"
		/>
		<path className="cls-3" d="M134.23,31.83V25.38h.85v6.45Z" />
		<path
			className="cls-3"
			d="M148.75,31.83l-3.23-4.61c0-.11-.44-.73-.45-.79v5.4h-.82V25.38h1L148.56,30c0,.06.35.66.37.73s0-.74,0-.79V25.38h.83v6.45Z"
		/>
		<path
			className="cls-3"
			d="M161,31.89c-1.93,0-3.12-1.07-3.12-3.34a2.9,2.9,0,0,1,3-3.23,2.69,2.69,0,0,1,2.6,1.52l-.86.25A2,2,0,0,0,160.94,26c-1.21,0-2.19.85-2.19,2.59s.88,2.66,2.26,2.66a3,3,0,0,0,1.88-.67V28.65h-2.48l.13-.62h3.17v2.85A3.9,3.9,0,0,1,161,31.89Z"
		/>
	</svg>
);

export default ErrorBoundary;
