import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TimingCell from 'Components/GridCells/TimingCell';
import { metricsQueryConfig } from 'Requests/MetricsQuery';
import { compose } from 'redux';
import { connectRequest } from 'redux-query-react';
import { pick } from 'lodash-es';

const PageTimingCell = ({ data, lowTimingCutoff, highTimingCutoff, highlightTiming, loaded }) => {
	const timeSpent = data?.time_spent;
	const lowTime = timeSpent && lowTimingCutoff ? timeSpent < lowTimingCutoff : false;
	const highTime = highTimingCutoff && timeSpent > highTimingCutoff;
	return (
		<TimingCell
			timeSpent={timeSpent}
			lowTime={highlightTiming ? lowTime : false}
			highTime={highlightTiming ? highTime : false}
			loaded={loaded}
		/>
	);
};

PageTimingCell.propTypes = {
	user: PropTypes.object.isRequired,
	page: PropTypes.object.isRequired,
	chapter: PropTypes.object.isRequired,
	data: PropTypes.object,
	lowTimingCutoff: PropTypes.number,
	highTimingCutoff: PropTypes.number,
	highlightTiming: PropTypes.bool,
	loaded: PropTypes.bool
};

const mapStateToProps = (state, props) => {
	const studentsVisited = state.entities.courseTimingCounts[props.page.id] || 0;
	const highlightThreshold = Math.ceil(state.entities.studentIds.length * 0.1);
	const highlightTiming = state.ui.highlightTiming && studentsVisited > highlightThreshold;
	const userIds = props.fetchRowGroupUserIds({ rowGroup: props.rowGroup });
	const metricsConfig = metricsQueryConfig({
		scopeId: props.chapter.id,
		groupBy: 'page',
		courseId: state.data.course.id,
		userIds: userIds,
		host: `https://${state.data.api.host}`,
		jwt: state.data.api.jwt
	});
	const bodyAndUrl = pick(metricsConfig, 'body', 'url');
	metricsConfig.queryKey = JSON.stringify({ body: bodyAndUrl.body, url: bodyAndUrl.url });

	return {
		metricsConfig,
		loaded: state.queries[metricsConfig.queryKey]?.isFinished ?? true,
		data: state.entities.metrics[props.user.id + ':' + props.page.id],
		lowTimingCutoff: state.entities.courseLowTimingCutoffs[props.page.id],
		highTimingCutoff: state.entities.courseHighTimingCutoffs[props.page.id],
		highlightTiming,
		onlyMinutes: true
	};
};

const mapPropsToConfig = (props) => {
	return props.metricsConfig;
};

export default compose(connect(mapStateToProps), connectRequest(mapPropsToConfig))(PageTimingCell);
