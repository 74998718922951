import styles from './ButtonSelect.scss';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

class ButtonSelect extends PureComponent {
	static propTypes = {
		options: PropTypes.array.isRequired,
		value: PropTypes.string.isRequired,
		onSelect: PropTypes.func.isRequired
	};

	render() {
		const { options, value, onSelect } = this.props;

		const buttons = options.map(function (option, idx) {
			if (typeof option === 'string') {
				option = [option, option];
			}

			const selected = option[0] === value;

			const classes = {
				root: selected ? styles.SelectedButton : styles.Button
			};

			return (
				<Button
					key={idx}
					onClick={() => {
						onSelect(option[0]);
					}}
					classes={classes}>
					<span className={selected ? styles.SelectedButtonLabel : styles.ButtonLabel}>
						{option[1]}
					</span>
				</Button>
			);
		});

		return <div styleName="Buttons">{buttons}</div>;
	}
}

export default ButtonSelect;
