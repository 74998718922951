import './AnswerActions.scss';

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ResizingTextarea from 'react-textarea-autosize';

class AnswerCommentForm extends Component {
	static propTypes = {
		savedCommentBody: PropTypes.string,
		onSaveComment: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
		setRef: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			draftCommentBody: props.savedCommentBody
		};
	}

	onCommentChange = (event) => {
		this.setState({ draftCommentBody: event.target.value });
	};

	saveCommentClickHandler = () => {
		this.props.onSaveComment(this.state.draftCommentBody);
	};

	hideCommentFormClickHandler = (event) => {
		event.preventDefault();
		this.props.onCancel();
	};

	render() {
		const { savedCommentBody } = this.props;
		const { draftCommentBody } = this.state;
		const isUpdate = !!savedCommentBody;
		const isDelete = isUpdate && !draftCommentBody;
		const saveDisabled = !savedCommentBody && !draftCommentBody;

		return (
			<Fragment>
				<div styleName="CommentContainer" data-test="comment-container">
					<ResizingTextarea
						ref={(ref) => {
							this.props.setRef(ref);
						}}
						value={draftCommentBody || ''}
						onChange={this.onCommentChange}
						placeholder="Type comment here ..."
					/>
				</div>
				<div styleName="Buttons">
					<button styleName="Button" onClick={this.saveCommentClickHandler} disabled={saveDisabled}>
						{isDelete ? 'Remove Comment' : isUpdate ? 'Update Comment' : 'Save Comment'}
					</button>
					<a href="#" onClick={this.hideCommentFormClickHandler}>
						cancel
					</a>
				</div>
			</Fragment>
		);
	}
}

export default AnswerCommentForm;
