import { requestAsync, mergeData } from './';

export default ({ courseId, userId }) =>
	requestAsync({
		path: '/api/courses/v1/analytics/user_activity',
		params: { course_id: courseId, user_id: userId },
		transform: (payload) => {
			return {
				userActivitiesByUserId: {
					[userId]: { ...payload }
				}
			};
		},
		update: { userActivitiesByUserId: mergeData }
	});
