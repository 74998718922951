import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatCompletion } from 'Utils/format';
import TippyRollover from '../TippyRollover';
import styles from '../GridView.scss';

class CourseCompletionCell extends React.PureComponent {
	static propTypes = {
		user: PropTypes.object,
		userIds: PropTypes.array,
		scope: PropTypes.object,
		scopeType: PropTypes.string,
		course: PropTypes.object.isRequired,
		courseAverageCompletions: PropTypes.object.isRequired,
		courseCountVisited: PropTypes.object.isRequired,
		toc: PropTypes.object.isRequired,
		isScrolling: PropTypes.bool
	};

	render() {
		const {
			course,
			toc,
			courseAverageCompletions,
			courseCountVisited,
			userIds,
			user,
			scope,
			scopeType,
			isScrolling
		} = this.props;

		let completion, explanation;

		if (user) {
			completion = user.course_completion;

			if (toc.total_question_count === 0) {
				explanation = 'Percentage of all pages in the webtext which the student has opened.';
			} else {
				explanation = 'Percentage of all questions in the webtext which the student has answered.';
			}
		} else {
			if (scope) {
				if (scope.total_question_count === 0) {
					const visited = courseCountVisited[scope.id];
					completion = visited ? visited / userIds.length : 0;
					const type = scopeType === 'pageGroup' ? 'page group' : scopeType;
					explanation = `Percentage of students in this class who have opened this ${type}.`;
				} else {
					completion = courseAverageCompletions[scope.id] || 0;
					explanation = 'Average percentage of questions answered by students in this class.';
				}
			} else {
				completion = course.average_completion || 0;

				if (toc.total_question_count === 0) {
					explanation = 'Average percentage of pages opened by students in this class.';
				} else {
					explanation = 'Average percentage of questions answered by students in this class.';
				}
			}
		}

		const completionText =
			completion != null ? formatCompletion({ completion }) : <span>&mdash;</span>;

		return (
			<TippyRollover
				content={
					isScrolling ? null : <span className={styles.CellHoverExplanation}>{explanation}</span>
				}>
				<span>{completionText}</span>
			</TippyRollover>
		);
	}
}

const mapStateToProps = (state) => ({
	course: state.entities.course,
	courseAverageCompletions: state.entities.courseAverageCompletions,
	courseCountVisited: state.entities.courseCountVisited,
	toc: state.entities.toc,
	userIds: state.entities.studentIds
});

export default connect(mapStateToProps)(CourseCompletionCell);
