import { User } from 'Containers/ActivityHistory/types';

/**
 * SearchQuery support provides property-level queries
 * By default, the query will just search for a match in the full name of the user
 * But also we can specify specific property for which we're looking, e.g. `id: 1`.
 * Moreover, we can combine properties with `;`, e.g. `id: 1; first_name: Alex`
 */

export type UserId = User['id'];
export interface UsersIndex {
	[userId: UserId]: User;
}

/**
 * Property should correspond to the properties of the User for the advanced queries
 * But we also can search for a match in the first_name + last_name string if it's a plain query without a property
 */
interface SearchRule {
	property: string;
	value: string;
}

const createSearchRule = (searchSubquery: string): SearchRule | null => {
	const trimmedSubquery = searchSubquery.trim().toLowerCase();
	if (!trimmedSubquery.length) return null;

	// For the plain query we will for the match in the first_name + last_name combination -> fullname
	const isPropertySubquery = trimmedSubquery.includes(':');
	if (!isPropertySubquery) {
		return {
			property: 'fullname',
			value: trimmedSubquery
		};
	}

	const [property, value] = trimmedSubquery.split(':').map((queryElement) => queryElement.trim());
	if (!value) return null;

	return {
		property,
		value
	};
};

const createSearchRules = (searchQuery: string): SearchRule[] => {
	if (!searchQuery.length) return [];

	const searchSubqueries = searchQuery.split(';');
	return searchSubqueries.map(createSearchRule).filter(Boolean);
};

const filterUser = (searchRules: SearchRule[], user?: User) => {
	if (!user) return false;

	return searchRules.reduce((isPassing, rule) => {
		const { id, first_name, last_name } = user;
		const { property, value } = rule;

		if (property === 'fullname') {
			const userFullname = `${first_name} ${last_name}`.toLowerCase();
			return isPassing && userFullname.includes(value);
		}

		// If property is not defined for the user, we shouldn't filter by it
		if (!(property in user)) {
			return isPassing;
		}
		if (property === 'id') {
			return isPassing && id.toString() === value;
		}

		const userValue = String(user[property]).toLowerCase();
		return isPassing && userValue.includes(value);
	}, true);
};

const filterUserIds = (usersIds: UserId[], usersById: UsersIndex, searchQuery: string) => {
	const searchRules = createSearchRules(searchQuery);
	return usersIds.filter((userId) => filterUser(searchRules, usersById[userId]));
};

export default filterUserIds;
