import React, { useEffect } from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';

import PollResultsAliasFromLibs from '@soomo/lib/components/pageElements/PollResultsAlias';

import elementActivityQuery from 'Requests/ElementActivityQuery';
import withLibsThemeProvider from 'Hocs/withLibsThemeProvider';

import { createPollQuestionState } from './PollQuestion';

const PollResults = (props) => {
	const {
		originalPollQuestion,
		originalPollQuestionPage,
		originalPollAnswer,
		course,
		tabsUi,
		pollResults,
		dispatch
	} = props;

	useEffect(() => {
		dispatch(elementActivityQuery({ courseId: course.id, elementId: originalPollQuestion.id }));
	}, [course.id, originalPollQuestion.id, dispatch]);

	let targetPollResults = {
		...originalPollQuestion,
		tabsUi: tabsUi,
		online: true
	};
	if (originalPollAnswer) {
		targetPollResults.answer = {
			graphData: Object.keys(pollResults).map((k) => ({ data: pollResults[k], label: k }))
		};
	}
	return (
		<PollResultsAliasFromLibs
			questionFamilyId={originalPollQuestion.family_id}
			answer={originalPollAnswer}
			online
			readOnly
			courseNumber={course.number}
			tabsUi={tabsUi}
			totalNumberOfStudents={course.active_student_enrollment_count}
			originalPollPageFamilyId={originalPollQuestionPage.family_id}
			originalPollPageNumber={originalPollQuestionPage.page_number}
			targetPollResults={targetPollResults}
			instructorView={false}
			navigateToTargetPoll={() => null}
			{...originalPollQuestion}
		/>
	);
};

PollResults.propTypes = {
	course: object.isRequired,
	originalPollQuestion: object.isRequired,
	originalPollQuestionPage: object.isRequired,
	originalPollAnswer: object,
	tabsUi: bool.isRequired,
	dispatch: func.isRequired
};

const mapStateToProps = (state, props) => {
	const { question, inContext } = props;
	const { student: userId } = state.ui.currentViewParams;

	const originalQuestionFamilyId = question.pollQuestionFamilyId;
	const originalQuestion = state.entities.elements[originalQuestionFamilyId];
	const originalQuestionPage = state.entities.pages[originalQuestion.page_id];

	const answerIds = state.entities.elementAnswerIds[`${userId}:${originalQuestionFamilyId}`];
	const answers = answerIds?.map((id) => state.entities.answers[id]);
	const originalPollActivity = { answers };

	const { answer: originalPollAnswer, ...originalQuestionState } = createPollQuestionState(
		state,
		originalQuestion,
		originalPollActivity
	);

	return {
		...originalQuestionState,
		originalPollQuestion: originalQuestion,
		originalPollQuestionPage: originalQuestionPage,
		originalPollAnswer,
		inContext
	};
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(withLibsThemeProvider(PollResults));
