import { requestAsync } from './';
import { sortUserByLastName } from 'Sorts/SortUserIdsCompletions';

export default ({ courseId, force = false }) =>
	requestAsync({
		path: '/api/courses/v1/enrollments',
		params: { course_id: courseId },
		transform: (payload) => {
			let completion_sum = 0;
			let score_sum = 0.0;
			let timing_sum = 0;
			let total = 0;
			let total_scored = 0;
			let total_timing = 0;

			const entities = {
				enrollments: { _loaded: true },
				users: { _loaded: true },
				userIds: [],
				studentIds: []
			};

			const visibleSubscriptionStatuses = ['trial', 'trial_expired', 'active', 'expired'];

			payload.enrollments.forEach(function (e) {
				entities.enrollments[e.user.id] = { roles: e.roles, user_id: e.user.id };
				entities.users[e.user.id] = { ...e.user };
				entities.userIds.push(e.user.id);

				if (e.roles.indexOf('student') > -1) {
					if (visibleSubscriptionStatuses.indexOf(e.subscription_status) > -1) {
						entities.studentIds.push(e.user.id);

						completion_sum += e.user.course_completion;
						score_sum += e.user.course_score || 0.0;
						timing_sum += e.user.total_time_spent;
						total += 1;
						if (e.user.course_score && e.user.course_score > 0) total_scored += 1;
						if (e.user.total_time_spent && e.user.total_time_spent > 0) total_timing += 1;
					}
				}
			});

			entities.userIds = sortUserByLastName(entities.userIds, entities.users);
			entities.studentIds = sortUserByLastName(entities.studentIds, entities.users);

			// just use total for now, but in the future we can
			// only include students who have received a score
			// or spent some time in the webtext.
			total_scored = total;
			total_timing = total;

			entities.course = {
				average_completion: total ? completion_sum / total : 0.0,
				average_score: total_scored ? score_sum / total_scored : null,
				average_total_time_spent: total_timing ? timing_sum / total_timing : 0
			};

			return entities;
		},
		update: {
			course: (prev, current) => (current ? { ...prev, ...current } : prev),
			enrollments: (prev, current) => current || {},
			users: (prev, current) => current || {},
			userIds: (prev, current) => current || [],
			studentIds: (prev, current) => current || []
		},
		force
	});
