import * as types from '../actions/actionTypes.js';
import initialState from './initialState';
import { studentsFilterOptions } from 'Containers/StudentList/StudentsFilters';

export default function (state = initialState.students, action) {
	switch (action.type) {
		case types.SET_STUDENTS_FILTERS: {
			// extra logic to ensure we order filters same as they appear in the list.
			const filters = studentsFilterOptions.filter((f) => action.payload.filters.indexOf(f) !== -1);
			return { ...state, filters };
		}

		case types.SET_STUDENTS_FILTERING_ENABLED: {
			return {
				...state,
				filteringEnabled: action.payload.enabled
			};
		}

		default:
			return state;
	}
}
