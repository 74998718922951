import { getRequestAsync, requestAsync, mergeData } from './';

export const metricsQueryConfig = ({
	host,
	courseId,
	userIds,
	scopeId,
	groupBy,
	jwt,
	force = false
}) => ({
	path: '/api/courses/v1/analytics/metrics',
	url: `${host}/api/courses/v1/analytics/metrics`,
	body: {
		course_id: courseId,
		groupby: groupBy,
		jwt,
		scope_family_id: scopeId,
		user_ids: userIds.join()
	},
	transform: (payload) => {
		if (!payload?.metrics) return { metrics: {} };
		const metrics = {};
		payload.metrics.forEach((metric) => {
			const { user_id, scope_family_id, ...data } = metric;
			data.fullyCompleted = data.completion === 1.0;
			if (!data.opened || data.completion === 0.0) data.score = null;
			metrics[`${user_id}:${scope_family_id}`] = data;
		});
		return { metrics };
	},
	update: { metrics: mergeData },
	force
});

export const getMetricsQuery = ({ courseId, userIds, scopeId, groupBy, force }) =>
	getRequestAsync(
		metricsQueryConfig({
			courseId,
			userIds,
			scopeId,
			groupBy,
			force
		})
	);

export default ({ courseId, userIds, scopeId, groupBy, force }) =>
	requestAsync(
		metricsQueryConfig({
			courseId,
			userIds,
			scopeId,
			groupBy,
			force
		})
	);
