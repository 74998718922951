import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Close from '@mui/icons-material/Close';

import TextLoadingIndicator from '../../components/TextLoadingIndicator';
import * as uiActions from '../../actions/uiActions';
import initialState from '../../reducers/initialState';
import { viewTemplateTypes } from '../../constants';

import GenerateReportModal from './GenerateReportModal';

import styles from './Header.scss';

interface Props {
	data: any;
	course?: any;
	uiActions?: any;
	generateReportsEnabled: boolean;
	viewTemplate: string;
}

const Header: React.FC<Props> = (props) => {
	const { data, course, generateReportsEnabled, viewTemplate } = props;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedReportType, setSelectedReportType] = useState(null);
	const [toastOpen, setToastOpen] = useState(false);

	const [isToastInFocus, setIsToastInFocus] = useState(false);

	const onHeaderClick = () => {
		const resetViewParams = { ...initialState.ui.currentViewParams };
		delete resetViewParams.apiHost;
		delete resetViewParams.courseId;
		delete resetViewParams.welcomed;
		props.uiActions.updateCurrentViewParams(resetViewParams);
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	return (
		<header styleName="Header">
			<Toolbar variant="dense" styleName="Toolbar">
				<div styleName="LabelContainer">
					<i className="ss-icon ss-grid" styleName="Icon" onClick={onHeaderClick} />
					<span styleName="Label" onClick={onHeaderClick}>
						Analytics
					</span>
					<span styleName="Slash">/</span>
					<span styleName="CourseName">
						{course.institutional_course_name || course.name || <TextLoadingIndicator />}
					</span>
					{course.number && <span styleName="CourseNumber">({course.number})</span>}
					{__DEV__ && (
						<span styleName="DevInfo">
							{data.api.host} - {course.id}
						</span>
					)}
				</div>

				{viewTemplate !== viewTemplateTypes.MESSAGE && generateReportsEnabled && (
					<>
						<Button
							styleName="GenerateReportButton"
							onClick={handleClick}
							data-active={Boolean(anchorEl)}>
							Generate Report
							<ArrowDropDownIcon />
						</Button>
						<Popover
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={handlePopoverClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							marginThreshold={null}>
							{course.grading_profile_id != null && (
								<MenuItem
									key="gradebook"
									onClick={() => {
										setSelectedReportType('gradebook');
										setModalOpen(true);
									}}
									styleName="ReportMenuItem"
									tabIndex={0}>
									Gradebook Points
								</MenuItem>
							)}
							<MenuItem
								key="mc_performance"
								onClick={() => {
									setSelectedReportType('mc_performance');
									setModalOpen(true);
								}}
								styleName="ReportMenuItem"
								tabIndex={0}>
								MC Question Response Summary
							</MenuItem>
							<MenuItem
								key="analytics"
								onClick={() => {
									setSelectedReportType('analytics');
									setModalOpen(true);
								}}
								styleName="ReportMenuItem"
								tabIndex={0}>
								Student Activity Detail
							</MenuItem>
						</Popover>

						{modalOpen && (
							<GenerateReportModal
								open={modalOpen}
								reportType={selectedReportType}
								showToast={() => setToastOpen(true)}
								onClose={() => {
									setModalOpen(false);
									handlePopoverClose();
								}}
							/>
						)}

						{toastOpen && (
							<Snackbar
								role="status"
								tabIndex={0}
								anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
								open={toastOpen}
								autoHideDuration={21000}
								onClose={(_, reason) => {
									if (reason === 'timeout' && isToastInFocus) return;
									setToastOpen(false);
								}}
								message={
									<>
										<CheckCircleIcon />
										<span>Your report is being generated.</span>
									</>
								}
								action={
									<IconButton
										aria-label="close status message"
										className={styles.CloseToastButton}
										onClick={() => setToastOpen(false)}>
										<Close />
									</IconButton>
								}
								classes={{ root: styles.ToastContainer }}
								ContentProps={{
									classes: { root: styles.Toast, message: styles.ToastMessage },
									role: 'status'
								}}
								onKeyDown={(event) => {
									if (event.key === 'Escape') {
										setToastOpen(false);
									}
								}}
								onFocus={() => setIsToastInFocus(true)}
								onBlur={() => setIsToastInFocus(false)}
							/>
						)}
					</>
				)}
			</Toolbar>
		</header>
	);
};

const mapStateToProps = (state) => ({
	data: state.data,
	course: state.entities.course,
	generateReportsEnabled: state.entities.featureFlags.generateReports,
	viewTemplate: state.ui.viewTemplate
});

const mapDispatchToProps = (dispatch) => ({
	uiActions: bindActionCreators(uiActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
