export const getImageUrl = ({
	host,
	builderId,
	name,
	userId,
	courseId,
	jwtToken,
	scaled = true
}) => {
	const secureHost = `https://${host}`;
	const path = `/api/courses/v1/webtext/builders/${builderId}/get_image`;
	const imageUrl = new URL(path, secureHost);
	const imageUrlParams = new URLSearchParams({
		user_id: userId,
		course_id: courseId,
		name,
		jwt: jwtToken,
		scaled: String(scaled)
	});
	imageUrl.search = imageUrlParams.toString();

	return imageUrl.toString();
};

export const getContextImageUrl = ({ outputContext, image, jwtToken, scaled = false }) => {
	const {
		builder: {
			userID,
			elementContext: { host, courseID }
		},
		selections
	} = outputContext;

	const filename = selections[image.dest];
	if (!filename) {
		return null;
	}

	const [builderId, name] = filename.split('/');

	return getImageUrl({
		host,
		builderId,
		name,
		userId: userID,
		courseId: courseID,
		jwtToken,
		scaled
	});
};
