import elementActivityQuery from 'Requests/ElementActivityQuery';
import elementsActivityQuery from 'Requests/ElementsActivityQuery';
import pageActivityQuery from 'Requests/PageActivityQuery';
import userActivityQuery from 'Requests/UserActivityQuery';
import metricsQuery from 'Requests/MetricsQuery';
import getEnrollments from 'Requests/EnrollmentsQuery';
import getCoursePageTimingsSummaryData from 'Requests/CoursePageTimingsSummaryQuery';
import getCoursePageCompletionSummaryData from 'Requests/CoursePageCompletionSummaryQuery';

/**
 * Interaction update for some respondables should lead to an immediate update of the state
 * to prevent the UI from showing stale data
 */
export const handleWebtextInteractionUpdate = (updateMessage) => (dispatch, getState) => {
	const { toc, pages, elements } = getState().entities;
	const { course_id: courseId, user_id: userId, respondable_family_id } = updateMessage;

	const element = elements[respondable_family_id];

	/**
	 * `GoReact` elements that received instructor's grade should immediately update the state (FOR NOW)
	 * TODO Implement optimized live updates for all the other respondables. See https://github.com/soomo/notebook/pull/344/files#r1205614713 for ideas
	 */
	if (element?.score_config?.scoreOptions !== 'instructorGrade') return;

	let respondablePageId;
	if (element) {
		respondablePageId = element.page_id;
		dispatch(elementActivityQuery({ courseId, elementId: element.family_id, force: true }));
	} else {
		respondablePageId = respondable_family_id; // MCQ are stored under page respondable
		const page = pages[respondablePageId];
		dispatch(
			elementsActivityQuery({
				courseId,
				userId,
				pageId: page.id,
				elementIds: page.element_ids,
				force: true
			})
		);
	}

	const { family_id: pageId, chapter_id: pageChapterId } = pages[respondablePageId];
	dispatch(pageActivityQuery({ courseId, userId, pageId, force: true }));

	dispatch(getEnrollments({ courseId, force: true }));
	dispatch(getCoursePageTimingsSummaryData({ courseId, force: true }));
	dispatch(getCoursePageCompletionSummaryData({ courseId, force: true }));

	dispatch(
		metricsQuery({
			courseId,
			userIds: [userId],
			scopeId: pageChapterId,
			groupBy: 'page',
			force: true
		})
	);

	dispatch(
		metricsQuery({
			courseId,
			userIds: [userId],
			scopeId: toc.family_id,
			groupBy: 'chapter',
			force: true
		})
	);

	dispatch(userActivityQuery({ courseId, userId }));
};
