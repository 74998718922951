import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import configureStore from './configureStore';
import initialState from '../reducers/initialState';
import { getLocalStorage } from '../services';
import { history } from '../navigation/Routing';
import { unrecoverableError } from 'Actions/dataActions';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CRITICAL_ERRORS_AMOUNT = window.Playwright ? 10 : 5;
export const PERSISTED_CONFIG_KEY = 'course_analytics.config';

const getHydratedState = () => {
	// Config which is being embedded by the server when the users using session-based authentication
	const embeddedConfig = window.__course_analytics_config__ || {};
	const persistedConfig = getLocalStorage(PERSISTED_CONFIG_KEY);

	/*
	 * jwt and apiHost are excluded from query search params to prevent injection of data
	 * bypassing the AuthGateway or the .env file
	 */
	const initialSearch = window.location.search;
	const { jwt, apiHost, ...initialQueryParams } = queryString.parse(initialSearch);

	// Receive the .env config to support multiple courses data fetching from the same api
	const envApiHost = process.env.API_HOST;

	const data = {
		...initialState.data,
		api: {
			...initialState.data.api,
			host: embeddedConfig.apiHost || envApiHost,
			jwt: embeddedConfig.jwt || persistedConfig.jwt
		},
		course: {
			...initialState.data.course,
			id: embeddedConfig.courseId || parseInt(initialQueryParams?.courseId?.toString()) || undefined
		}
	};

	const ui = {
		...initialState.ui,
		currentViewParams: {
			...initialState.ui.currentViewParams,
			...initialQueryParams
		},
		initialView: embeddedConfig.initialView || initialQueryParams?.initialView || {}
	};
	if (data.course.id) {
		ui.currentViewParams.courseId = data.course.id;
	}
	if (data.api.host) {
		ui.currentViewParams.apiHost = data.api.host;
	}

	return { ...initialState, data, ui };
};

const store = configureStore(getHydratedState(), history);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

store.subscribe(() => {
	const state = store.getState();
	const requestErrorCount = Object.keys(state.errors).length;
	const errorReported = state.data.unrecoverableError;

	if (errorReported || requestErrorCount <= CRITICAL_ERRORS_AMOUNT) return;

	store.dispatch(unrecoverableError());
});

export default store;
