import { mutateAsync } from './';

export default ({ courseId, answerId, currentUser }) =>
	mutateAsync({
		path: '/api/courses/v1/answers/pinnings',
		params: { course_id: courseId, answer_id: answerId },
		transform: (answer) => ({
			answers: {
				[answer.id]: { pinned_at: answer.pinned_at, pinned_by_name: answer.pinned_by_name }
			}
		}),
		update: {
			answers: (prev, current) => {
				const entities = { ...prev };
				Object.keys(current).forEach((answerId) => {
					entities[answerId] = { ...entities[answerId], ...current[answerId] };
				});
				return entities;
			}
		},
		optimisticUpdate: {
			answers: (prev) => {
				return {
					...prev,
					[answerId]: { ...prev[answerId], pinned_at: new Date(), pinned_by_name: currentUser.name }
				};
			}
		}
	});
