import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import BaseGrid from './BaseGrid';
import PageCompletionCell from './PageCompletionCell';
import PageTimingCell from './PageTimingCell';
import PageScoreCell from './PageScoreCell';
import PageHeaderCellHover from './PageHeaderCellHover';
import PageCellHover from './PageCellHover';
import { updateCurrentViewParams } from 'Actions/uiActions';
import { colors } from 'Theme';

const pageIdsSelector = createSelector(
	(state) => state.entities.toc,
	(state) => state.entities.chapters,
	(toc, chapters) => {
		if (toc && toc.chapter_ids) {
			return toc.chapter_ids
				.map((id) => chapters[id])
				.reduce(function (pageIds, chapter) {
					return pageIds.concat(chapter.page_ids);
				}, []);
		} else {
			return [];
		}
	}
);

const pageSelector = createSelector(
	(state) => state.entities.chapters,
	(state) => state.entities.page_groups,
	(state) => state.entities.pages,
	(state) => state.ui.currentViewParams.scope,
	(chapters, pageGroups, pages, scopeId) => {
		let pageId;
		if (pages[scopeId]) {
			pageId = scopeId;
		} else if (pageGroups[scopeId]) {
			pageId = pageGroups[scopeId].page_ids[0];
		} else if (chapters[scopeId]) {
			pageId = chapters[scopeId].page_ids[0];
		}
		return pageId ? pages[pageId] : null;
	}
);

class PagesGrid extends React.Component {
	getColumnCount = () => this.props.pageIds.length;

	getScope = ({ columnIndex }) => {
		const { pageIds, pagesById } = this.props;
		const pageId = pageIds[columnIndex];
		return pagesById[pageId];
	};

	renderColumnGroupCell = ({ columnIndex }, extraStyle) => {
		const page = this.getScope({ columnIndex });
		const pageGroup = this.props.pageGroupsById[page.page_group_id];

		if (pageGroup && pageGroup.page_ids[0] === page.id) {
			const cellWidth = 56; // see BaseGrid#getColumnWidth
			const fullWidth = cellWidth * pageGroup.page_ids.length;
			const lineWidth = (cellWidth - 2) * pageGroup.page_ids.length;

			extraStyle.display = 'flex';
			extraStyle.flexDirection = 'row';
			extraStyle.alignItems = 'center';

			const style = {
				width: fullWidth,
				margin: '0 auto',
				textAlign: 'center',
				color: colors.appAccentColor,
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				fontSize: '0.7em',
				lineHeight: '1.3em'
			};

			const lineStyle = {
				width: lineWidth,
				height: '8px',
				margin: '5px auto 0',
				border: `1px solid ${colors.appAccentColor}`,
				borderBottomWidth: '0'
			};

			return (
				<div>
					<div style={style}>
						{pageGroup.page_group_number} {pageGroup.name}
					</div>
					<div style={lineStyle} />
				</div>
			);
		} else {
			return null;
		}
	};

	getScrollToColumn = () => {
		const { page, pageIds } = this.props;
		return page ? pageIds.indexOf(page.id) : null;
	};

	getMaximumColumnGroupSize = () => {
		const { pageGroupsById } = this.props;
		const pageGroupLengths = Object.values(pageGroupsById).map((pg) => pg.page_ids.length);
		return pageGroupLengths.length ? Math.max.apply(null, pageGroupLengths) : 0;
	};

	renderHeaderCell = ({ columnIndex }, extraStyle) => {
		const { pageIds, pagesById } = this.props;
		const pageId = pageIds[columnIndex];
		const page = pagesById[pageId];

		let spanContent;
		if (page.page_number && page.page_number.trim().length > 0) {
			if (columnIndex !== 0 && page.page_number.split('.').pop() === '1') {
				extraStyle.borderLeft = `1px solid ${colors.gridViewVerticalLineColor}`;
			}
			spanContent = page.page_number;
		} else {
			spanContent = page.page_name[0];
		}

		return <span>{spanContent}</span>;
	};

	renderCompletionCell = ({ columnIndex, ...passthroughProps }) => {
		const { pageIds, pagesById, chaptersById } = this.props;
		const pageId = pageIds[columnIndex];
		const page = pagesById[pageId];
		const chapter = chaptersById[page.chapter_id];

		return <PageCompletionCell page={page} chapter={chapter} {...passthroughProps} />;
	};

	renderTimingCell = ({ columnIndex, ...passthroughProps }) => {
		const { pageIds, pagesById, chaptersById } = this.props;
		const pageId = pageIds[columnIndex];
		const page = pagesById[pageId];
		const chapter = chaptersById[page.chapter_id];

		return <PageTimingCell page={page} chapter={chapter} {...passthroughProps} />;
	};

	renderScoreCell = ({ columnIndex, ...passthroughProps }) => {
		const { pageIds, pagesById, chaptersById } = this.props;
		const pageId = pageIds[columnIndex];
		const page = pagesById[pageId];
		const chapter = chaptersById[page.chapter_id];

		return <PageScoreCell page={page} chapter={chapter} {...passthroughProps} />;
	};

	headerCellHoverContent = ({ columnIndex }) => {
		const { pageIds } = this.props;
		const pageId = pageIds[columnIndex];

		return <PageHeaderCellHover pageId={pageId} />;
	};

	dataCellHoverContent = ({ user, columnIndex, rowIndex }) => {
		const { pageIds } = this.props;
		const pageId = pageIds[columnIndex];

		return <PageCellHover userId={user.id} pageId={pageId} rowIndex={rowIndex} />;
	};

	onDataCellClick = ({ userId, columnIndex }) => {
		const pageId = this.props.pageIds[columnIndex];

		return () => {
			this.props.updateCurrentViewParams({ student: userId, scope: pageId });
		};
	};

	onCourseAggregateCellClick = ({ columnIndex }) => {
		// redirect to overall class performance view
		const pageId = this.props.pageIds[columnIndex];
		return () => this.props.updateCurrentViewParams({ student: null, scope: pageId });
	};

	render() {
		return (
			<BaseGrid
				columnCount={this.getColumnCount()}
				getScope={this.getScope}
				scrollToColumn={this.getScrollToColumn()}
				renderColumnGroupCell={this.renderColumnGroupCell}
				maximumColumnGroupSize={this.getMaximumColumnGroupSize()}
				renderHeaderCell={this.renderHeaderCell}
				renderCompletionCell={this.renderCompletionCell}
				renderTimingCell={this.renderTimingCell}
				renderScoreCell={this.renderScoreCell}
				headerCellHoverContent={this.headerCellHoverContent}
				dataCellHoverContent={this.dataCellHoverContent}
				onDataCellClick={this.onDataCellClick}
				onCourseAggregateCellClick={this.onCourseAggregateCellClick}
				{...this.props}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	page: pageSelector(state),
	pageIds: pageIdsSelector(state),
	pagesById: state.entities.pages,
	pageGroupsById: state.entities.page_groups,
	chaptersById: state.entities.chapters
});

const mapDispatchToProps = (dispatch) => ({
	updateCurrentViewParams: (params) => {
		dispatch(updateCurrentViewParams(params));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PagesGrid);
