import React from 'react';
import { object, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import Button from '@mui/material/Button';
import isMatch from 'lodash.ismatch';

import { selectRequiresGradingActionPagesSummary, selectSortedPagesIds } from 'Store/selectors';
import ShowNeedsGradingButton from './ShowNeedsGradingButton';
import * as uiActions from '../../actions/uiActions';
import selectorPanelStyles from '../SelectorPanel/SelectorPanel.scss';
import styles from './QuickActionsSelector.scss';

// Default state defined in the `initialState` of `uiReducer.js`
const defaultViewParams = {
	gridViewSortBy: 'user.last_name ASC',
	gridViewAspect: 'progress',
	assignmentTypeFilter: '',
	scope: null
};

const links = [
	{
		name: 'needsGrading',
		viewType: 'grid',
		viewParams: {
			gridViewAspect: 'progress',
			gridViewColumnScopeType: 'page',
			assignmentTypeFilter: 'needsGrading'
		},
		ui: {
			label: 'Show Needs Grading',
			description:
				'Some assignments in this course must be graded by your instructor. The number of assignments that need grading is displayed next to the Show Needs Grading filter title. If no assignments need to be graded, this number will be “0.”',
			component: ShowNeedsGradingButton
		}
	},
	{
		name: 'lowCompletion',
		viewType: 'grid',
		viewParams: {
			gridViewSortBy: 'user.course_completion ASC',
			gridViewAspect: 'progress'
		},
		ui: {
			label: 'Show Low Progress',
			description:
				'Students who do not complete course work are at high risk of failure. Contact students who are falling behind, and let them know that doing the assigned work is essential for learning and success in the course.'
		}
	},
	{
		name: 'lowScore',
		viewType: 'grid',
		viewParams: {
			gridViewSortBy: 'user.course_score ASC',
			gridViewAspect: 'score'
		},
		ui: {
			label: 'Show Low Score',
			description:
				'Low scores indicate areas where students are struggling to understand the concepts presented. Follow up with students who have low scores to offer support or encourage them to take more time to understand the content.'
		}
	},
	{
		name: 'lowTime',
		viewType: 'grid',
		viewParams: {
			gridViewSortBy: 'user.total_time_spent ASC',
			gridViewAspect: 'timeSpent'
		},
		ui: {
			label: 'Show Low Time Spent',
			description:
				'Low active time usually means that a student is not putting in the effort necessary for meaningful learning. Students earning high scores but showing very low active time either already know the material or have discovered a shortcut. Contact them to find out what’s going on.'
		}
	},
	{
		name: 'gradebookPoints',
		viewType: 'grid',
		viewParams: {
			gridViewSortBy: 'user.last_name ASC',
			gridViewAspect: 'gradebookPoints'
		},
		ui: {
			label: 'Show Gradebook Points',
			description:
				'This table shows the points your students have earned based on your course’s grading profile. These points match what’s in your school’s LMS gradebook (if you have an LMS integration).'
		}
	}
];

class QuickActionsSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedLinkName: '',
			isDisabled: false
		};
	}

	UNSAFE_componentWillMount() {
		this.updateSelectedState(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.updateSelectedState(nextProps);
	}

	updateSelectedState(nextProps) {
		/**
		 * Link is active when its `viewParams` match the currently defined ones
		 * @see https://lodash.com/docs/4.17.15#isMatch
		 */
		const activeLink = links.find((link) => isMatch(nextProps.currentViewParams, link.viewParams));
		this.setState({ selectedLinkName: activeLink?.name || '' });
	}

	handleClick = (linkName) => {
		const clickedLink = links.find((link) => link.name === linkName);
		const isActiveLinkUnselected = this.state.selectedLinkName === clickedLink.name;

		let updateViewParamsPayload = {
			...defaultViewParams, // Fills viewParams with default values
			searchQuery: '' // Clear student search on any Quick Action click
		};
		const gridViewScrollPayload = { columnIndex: 0 };

		if (!isActiveLinkUnselected) {
			updateViewParamsPayload = { ...updateViewParamsPayload, ...clickedLink.viewParams };

			switch (clickedLink.name) {
				case 'needsGrading':
					updateViewParamsPayload.scope = this.props.firstRequiresActionPageFamilyId;
					gridViewScrollPayload.columnIndex = this.props.firstRequiresActionPageIndex;
			}
		}

		this.setState({ selectedLinkName: clickedLink.name });
		this.props.uiActions.updateCurrentViewParams(updateViewParamsPayload);
		this.props.uiActions.gridViewScrollToColumn(gridViewScrollPayload);
	};

	render() {
		const { course, viewTemplate } = this.props;
		const viewTemplateLinks = links.filter((link) => {
			if (link.viewType === viewTemplate) {
				return link.name !== 'gradebookPoints' || course.grading_profile_id != null;
			}
			return false;
		});

		if (viewTemplateLinks.length === 0) return null;
		return (
			<div className={styles.Base}>
				<div className={styles.SelectorLabelButton}>
					<div className={styles.SelectorLabelBox}>
						<p className={styles.SelectorLabel}>Quick Actions</p>
					</div>
				</div>

				{viewTemplateLinks.map(({ name, ui: { label, component: CustomButtonComponent } }) => {
					const ButtonComponent = CustomButtonComponent || Button;
					return (
						<ButtonComponent
							key={name}
							onClick={() => this.handleClick(name)}
							aria-current={name === this.state.selectedLinkName ? 'page' : null}
							data-active={name === this.state.selectedLinkName}
							classes={{
								root: styles.SelectionButton,
								disabled: styles.SelectionDisabled
							}}>
							<span className={styles.SelectionLabel}>{label}</span>
						</ButtonComponent>
					);
				})}

				{viewTemplateLinks.map(({ name, ui: { label, description } }) => {
					if (name === this.state.selectedLinkName) {
						return (
							<div key={name} className={cn(selectorPanelStyles.FilterBox, styles.HelpMessage)}>
								<h3 className={selectorPanelStyles.FilterDescriptionLabel}>{label}</h3>
								<p className={selectorPanelStyles.FilterDescription}>{description}</p>
							</div>
						);
					}
				})}
			</div>
		);
	}
}

QuickActionsSelector.propTypes = {
	course: object.isRequired,
	uiActions: object.isRequired,
	viewTemplate: string,
	currentViewParams: object.isRequired
};

const mapStateToProps = (state) => {
	const requiresActionPages = selectRequiresGradingActionPagesSummary(state);
	const pageIds = selectSortedPagesIds(state);
	const firstRequiresActionPageFamilyId = requiresActionPages[0]?.family_id;

	return {
		course: state.entities.course,
		viewTemplate: state.ui.viewTemplate,
		currentViewParams: state.ui.currentViewParams,
		firstRequiresActionPageFamilyId,
		firstRequiresActionPageIndex: pageIds.indexOf(firstRequiresActionPageFamilyId)
	};
};

const mapDispatchToProps = (dispatch) => ({
	uiActions: bindActionCreators(uiActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickActionsSelector);
