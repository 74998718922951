export default function (builder, filteredSelections) {
	const apaReferenceList = {};

	Object.keys(filteredSelections).forEach(function (key) {
		const selection = filteredSelections[key];
		if (typeof selection === 'object') {
			const citations = selection.citations || [];
			const citationSources = builder.getReferencedValue(selection['source-list-ref']);
			citations.forEach(function (citation) {
				const data = builder.getCitationMetaData(citation, citationSources);
				if (data['reference-text']) {
					const sortOrder = data['sort-order'];
					apaReferenceList[sortOrder] = data['reference-text'];
				}
			});
		}
	});

	return {
		apaReferenceList
	};
}
