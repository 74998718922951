import React, { useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';
import { followCursor as followCursorPlugin } from 'tippy.js';
import { bool, node, oneOfType, oneOf, string } from 'prop-types';

import './TippyRollover.scss';

// Lazy rendering is inspired with: https://gist.github.com/atomiks/520f4b0c7b537202a23a3059d4eec908
const TippyRollover = ({
	variant = 'tooltip',
	followCursor = true,
	offset = [-20, 20],
	className,
	children,
	...tippyProps
}) => {
	const [mounted, setMounted] = useState(false);

	const lazyPlugin = {
		fn: () => ({
			onMount: () => setMounted(true),
			onHidden: () => setMounted(false)
		})
	};

	const computedProps = { ...tippyProps };
	computedProps.plugins = [lazyPlugin, followCursorPlugin, ...(tippyProps.plugins || [])];

	const maxWidth = useMemo(() => {
		switch (variant) {
			case 'tooltip':
				return 230;
			case 'pane':
				return 330;
			default:
				return 'none';
		}
	}, [variant]);

	if (tippyProps.render) {
		computedProps.render = (...args) => (mounted ? tippyProps.render(...args) : '');
	} else {
		computedProps.content = mounted ? tippyProps.content : '';
	}

	return (
		<Tippy
			offset={offset}
			placement="right-start"
			arrow={false}
			maxWidth={maxWidth}
			followCursor={followCursor}
			delay={[256, null]}
			{...computedProps}
			theme="tippy-rollover"
			className={className}>
			<div tabIndex="0">{children}</div>
		</Tippy>
	);
};

TippyRollover.propTypes = {
	variant: oneOf(['pane', 'tooltip', 'none']),
	followCursor: bool,
	children: oneOfType([string, node]),
	className: string
};

export default TippyRollover;
