import React, { FC } from 'react';

import { GoReactElement } from '@soomo/lib/components/pageElements/GoReact/types';

import { ElementActivity } from 'Types/index';
import GoReactInContextView from 'Containers/Views/Elements/ExternalTool/GoReact/InContextView';
import GoReactQuestionView from 'Containers/Views/Elements/ExternalTool/GoReact/QuestionView';

interface Props {
	element: GoReactElement;
	activity: ElementActivity;
	inContext: boolean;
}

const GoReact: FC<Props> = (props) => {
	const { element, activity, inContext } = props;
	return inContext ? (
		<GoReactInContextView element={element} />
	) : (
		<GoReactQuestionView element={element} activity={activity} />
	);
};

export default GoReact;
