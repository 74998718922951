import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScoreCell from 'Components/GridCells/ScoreCell';

import { metricsQueryConfig } from 'Requests/MetricsQuery';
import { compose } from 'redux';
import { connectRequest } from 'redux-query-react';
import { pick } from 'lodash-es';

const PageGroupScoreCell = ({ data, loaded }) => {
	const score = data?.score ?? null;
	return <ScoreCell score={score} loaded={loaded} />;
};

PageGroupScoreCell.propTypes = {
	data: PropTypes.object,
	loaded: PropTypes.bool
};

const mapStateToProps = (state, props) => {
	const userIds = props.fetchRowGroupUserIds({ rowGroup: props.rowGroup });

	const metricsConfig = metricsQueryConfig({
		scopeId: state.entities.toc.id,
		groupBy: 'page_group',
		courseId: state.data.course.id,
		userIds: userIds,
		host: `https://${state.data.api.host}`,
		jwt: state.data.api.jwt
	});
	const bodyAndUrl = pick(metricsConfig, 'body', 'url');
	metricsConfig.queryKey = JSON.stringify({ body: bodyAndUrl.body, url: bodyAndUrl.url });
	return {
		metricsConfig,
		loaded: state.queries[metricsConfig.queryKey]?.isFinished ?? true,
		data: state.entities.metrics[`${props.user.id}:${props.pageGroup.id}`]
	};
};

const mapPropsToConfig = (props) => {
	return props.metricsConfig;
};

export default compose(
	connect(mapStateToProps),
	connectRequest(mapPropsToConfig)
)(PageGroupScoreCell);
