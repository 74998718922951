import React, { AnchorHTMLAttributes } from 'react';
import { cx } from 'emotion';

import styles from './AnalyticsLink.scss';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
	variant?: 'text' | 'text-uppercase';
}

const AnalyticsLink: React.FC<Props> = (props) => {
	const { variant, ...rest } = props;
	if (variant) {
		return (
			<a
				className={cx(styles.TextVariant, variant === 'text-uppercase' && styles.TextUppercase)}
				{...rest}
			/>
		);
	}

	return <a {...rest} />;
};

export default AnalyticsLink;
