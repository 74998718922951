import { requestAsync, mergeData } from './';

export default ({ courseId, elementId }) =>
	requestAsync({
		path: '/api/courses/v1/webtext/element',
		params: { course_id: courseId, element_id: elementId },
		transform: (element) => ({ elements: { [element.id]: element } }),
		update: { elements: mergeData },
		force: true
	});
