import styles from './ConfirmDialog.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ModalDialog from '../ModalDialog';

function ConfirmDialog({ open, onConfirm, onClose, title, dataTest, children }) {
	const classes = {
		root: styles.Button
	};

	const actions = [
		<Button key={1} onClick={onClose} classes={classes}>
			<span className={styles.Label}>Cancel</span>
		</Button>,
		<Button
			key={2}
			onClick={() => {
				onConfirm();
				onClose();
			}}
			classes={classes}>
			<span className={styles.Label}>OK</span>
		</Button>
	];

	return (
		<ModalDialog open={open} onClose={onClose} actions={actions} title={title} dataTest={dataTest}>
			{children}
		</ModalDialog>
	);
}

ConfirmDialog.propTypes = {
	open: PropTypes.bool,
	onConfirm: PropTypes.func,
	onClose: PropTypes.func,
	title: PropTypes.string,
	children: PropTypes.node,
	dataTest: PropTypes.string
};

export default ConfirmDialog;
