import * as types from './actionTypes.js';

export const setTocChapterIds = ({ chapterIds }) => ({
	type: types.SET_TOC_CHAPTER_IDS,
	payload: { chapterIds }
});

export const toggleTocChapterOpen = ({ chapterId }) => ({
	type: types.TOGGLE_TOC_CHAPTER_OPEN,
	payload: { chapterId }
});

export const setTocChapterOpen = ({ chapterId, open }) => ({
	type: types.SET_TOC_CHAPTER_OPEN,
	payload: { chapterId, open }
});

export const setAllTocChaptersOpen = () => ({
	type: types.SET_ALL_TOC_CHAPTERS_OPEN,
	payload: {}
});

export const setTocFilters = ({ filters }) => ({
	type: types.SET_TOC_FILTERS,
	payload: { filters }
});

export const setTocFilteringEnabled = (enabled) => ({
	type: types.SET_TOC_FILTERING_ENABLED,
	payload: { enabled }
});
