import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import completionCellStyles from './CompletionPercentageCell.scss';
import loaderStyles from '../../containers/Views/AggregatedElements/LoadingShimmer.scss';

class CompletionPercentageCell extends React.PureComponent {
	static propTypes = {
		completion: PropTypes.number,
		opened: PropTypes.bool,
		onClick: PropTypes.func,
		loaded: PropTypes.bool
	};

	render() {
		const { completion, opened, onClick, loaded } = this.props;

		return (
			<div
				onClick={onClick}
				className={cx(completionCellStyles.Wrapper, !loaded && loaderStyles.LoadingShimmer)}>
				{opened && completion !== undefined && <div>{Math.floor(completion * 100)}%</div>}
			</div>
		);
	}
}

CompletionPercentageCell.propTypes = {
	completion: PropTypes.number,
	opened: PropTypes.bool,
	onClick: PropTypes.func
};

export default CompletionPercentageCell;
