import Utils from './Utils';

export default function (builder, filteredSelections) {
	const turabianOutput = {};
	const turabianBibliography = {};
	const turabianFootNotes = [];

	const footNotesMap = {};

	Object.keys(filteredSelections).forEach(function (key) {
		const selection = filteredSelections[key];

		if (typeof selection === 'object') {
			turabianOutput[key] = {};
			turabianOutput[key].citations = [];
			const citations = selection.citations || [];
			const sourceList = builder.getReferencedValue(selection['source-list-ref']);

			citations.forEach(function (citation, citationIndex) {
				const sourceKey = citation.key;
				footNotesMap[sourceKey] || (footNotesMap[sourceKey] = []);

				const attributes = { index: citationIndex };

				let citationPageNumber;
				if (citation.attributes) {
					citationPageNumber = citation.attributes['page-number'];
					attributes['page-number'] = citationPageNumber;
				}

				footNotesMap[sourceKey].push(attributes);
				turabianOutput[key].citations.push(citationIndex + 1);

				const turabianData = builder.getCitationMetaData(citation, sourceList);
				const footNotesText = turabianData['note-text'];

				// This is an array
				const citationRefs = footNotesMap[sourceKey];
				if (footNotesText) {
					if (citationRefs.length === 1) {
						turabianFootNotes.push(addPageNumberToFootNote(footNotesText, citationPageNumber));
					} else {
						// Use short format or Turabian
						const shortFootNotesText = turabianData['short-note-text'];
						turabianFootNotes.push(addPageNumberToFootNote(shortFootNotesText, citationPageNumber));
					}
				}

				if (turabianData['bib-text']) {
					const sortOrder = turabianData['sort-order'];
					turabianBibliography[sortOrder] = turabianData['bib-text'];
				}
			});
		}
	});

	return {
		turabianOutput,
		turabianFootNotes,
		turabianBibliography
	};
}

function addPageNumberToFootNote(footNote, pageNumber) {
	if (pageNumber) {
		return Utils.escapeContent(Utils.replacePagePlaceholder(footNote, pageNumber));
	} else {
		return Utils.escapeContent(footNote);
	}
}
