// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './ResponseBoard.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import pageViewStyles from '../PageView.scss';

import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';

import { ResponseBoardQuestion } from '@soomo/lib/components/pageElements';
import { VersionHistoryItem } from '@soomo/lib/notebook/components';

import elementActivityQuery from 'Requests/ElementActivityQuery';
import pageActivityQuery from 'Requests/PageActivityQuery';
import metricsQuery from 'Requests/MetricsQuery';
import * as uiActions from 'Actions/uiActions';
import AnalyticsLink from 'Components/AnalyticsLink';
import ActivityHeader from 'Components/ActivityHeader';
import ActivityHistory from 'Containers/ActivityHistory';
import withLibsThemeProvider from 'Hocs/withLibsThemeProvider';

import AnswerActions from './AnswerActions';

class ResponseBoard extends Component {
	state = {
		showResponsesInContext: false,
		isEditingComment: false
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate() {
		this.fetchData();
	}

	fetchData = () => {
		const { dispatch, course, question } = this.props;
		dispatch(elementActivityQuery({ courseId: course.id, elementId: question.id }));
	};

	onUnpostAnswer = () => {
		const { dispatch, course, chapter, page, current_answer: answer } = this.props;
		dispatch(
			pageActivityQuery({
				courseId: course.id,
				userId: answer.user_id,
				pageId: page.id,
				force: true
			})
		);
		dispatch(
			metricsQuery({
				courseId: course.id,
				userIds: [answer.user_id],
				scopeId: chapter.id,
				groupby: 'page',
				force: true
			})
		);
	};

	onViewAllResponses = () => {
		const { uiActions, page, question, user } = this.props;

		uiActions.updateCurrentViewParams({
			scope: page.id,
			element: question.id,
			student: null,
			pageViewStudent: user.id
		});

		return false;
	};

	renderInContext = () => {
		const { user, question, activity, postedAnswers, users } = this.props;

		const answer = activity && activity.answers && activity.answers[activity.answers.length - 1];

		const pinnedAnswers = [];
		const unpinnedAnswers = [];

		postedAnswers.forEach((answer) => {
			answer.pinned_at ? pinnedAnswers.push(answer) : unpinnedAnswers.push(answer);
		});

		// pinned answers sorted by pinned_at, most recent first.
		pinnedAnswers.sort(function (a, b) {
			if (a.pinned_at < b.pinned_at) return 1;
			if (a.pinned_at > b.pinned_at) return -1;
			return a.id < b.id ? -1 : 1;
		});

		// unpinned answers sorted by posted_at, most recent first.
		unpinnedAnswers.sort(function (a, b) {
			if (a.posted_at < b.posted_at) return 1;
			if (a.posted_at > b.posted_at) return -1;
			return a.id < b.id ? -1 : 1;
		});

		const answers = pinnedAnswers.concat(unpinnedAnswers);

		const answerToUserPost = function (answer, user) {
			return {
				body: answer.posted_at ? answer.body : '',
				postedAt: answer.posted_at,
				lastSavedAt: answer.saved_at,
				author: {
					name: user ? `${user.first_name} ${user.last_name}` : ''
				},
				isPinned: !!answer.pinned_at,
				pinnedBy: null
			};
		};

		const emptyUserPost = {
			body: '',
			author: { name: '' }
		};

		const userPost = answer ? answerToUserPost(answer, user) : emptyUserPost;
		const coursePosts = answers.map((answer) => answerToUserPost(answer, users[answer.user_id]));

		return (
			<ResponseBoardQuestion
				userId={user.id}
				questionId={question.id}
				body={question.body}
				userPost={userPost}
				coursePosts={coursePosts}
				online={true}
				sharingAcknowledged={true}
				showResponses={true}
				setShowResponses={() => null}
				validationMessage=""
				editable={false}
				readOnly
			/>
		);
	};

	renderAnswerState = () => {
		const { current_answer: answer, posted_at, timeZone } = this.props;
		if (posted_at) return null;

		const isDrafted = answer && answer.body && !posted_at;
		if (isDrafted) {
			return (
				<ActivityHeader timestamp={answer.saved_at} timeZone={timeZone}>
					LAST SAVE <span style={{ fontStyle: 'italic' }}>(Not yet posted)</span>
				</ActivityHeader>
			);
		}
		return <ActivityHeader>NOT YET POSTED</ActivityHeader>;
	};

	render() {
		if (this.props.inContext) return this.renderInContext();

		const {
			question,
			current_answer: answer,
			commentKey,
			comment,
			posted_at,
			timeZone,
			user
		} = this.props;

		const isComplete = answer && posted_at;

		const postedEventVersions = answer?.history?.filter((v) => v.event === 'posted');
		const postedEventCount = postedEventVersions?.length || 0;

		return (
			<div styleName="styles.ResponseBoard">
				{isComplete && (
					<div styleName={cn('pageViewStyles.Symbol', 'pageViewStyles.IsCompleteSymbol')} />
				)}
				<div styleName="styles.ActivitySummary">
					<span styleName="pageViewStyles.QuestionType">Response Board</span>
					<span>&nbsp;&mdash;&nbsp;</span>
					<AnalyticsLink variant="text-uppercase" onClick={this.onViewAllResponses}>
						view all responses
					</AnalyticsLink>
				</div>

				<h2
					styleName="styles.QuestionBody"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: question.body }}
				/>

				{posted_at && (
					<ActivityHeader timestamp={posted_at} timeZone={timeZone}>
						POSTED
					</ActivityHeader>
				)}

				{isComplete && (
					<p
						data-test="answer-body"
						styleName={cn('styles.AnswerBody', 'styles.AnswerBodyComplete')}>
						{answer.body}
					</p>
				)}

				{this.renderAnswerState()}

				<AnswerActions
					answer={answer}
					posting={posted_at}
					question={question}
					user={user}
					onUnpostAnswer={comment && !this.state.isEditingComment ? this.onUnpostAnswer : null}
					hideCommentButton
				/>

				<AnswerActions
					answer={answer}
					commentKey={commentKey}
					comment={comment}
					posting={posted_at}
					question={question}
					user={user}
					commentWithoutAnswer={true}
					commentRequiresPosting={false}
					onEditComment={(isEditingComment) => {
						this.setState({ isEditingComment });
					}}
					onUnpostAnswer={comment ? null : this.onUnpostAnswer}
				/>
				{postedEventCount > 0 && (
					<ActivityHistory attemptCount={postedEventCount}>
						{answer.history
							.filter((v) => v.event !== 'saved')
							.map((item, index) => (
								<VersionHistoryItem
									key={`${item.event}-${index}`}
									version={item}
									studentId={user.id}
									timeZone={timeZone}
								/>
							))}
					</ActivityHistory>
				)}
			</div>
		);
	}
}

ResponseBoard.propTypes = {
	currentUser: object.isRequired,
	api: object.isRequired,
	course: object.isRequired,
	featureFlags: object,
	uiActions: object.isRequired,
	chapter: object.isRequired,
	page: object.isRequired,
	user: object.isRequired,
	question: object.isRequired,
	activity: object,
	current_answer: object,
	comment: object,
	posted_at: string,
	timeZone: string.isRequired,
	inContext: bool.isRequired,
	users: object.isRequired,
	postedAnswers: array.isRequired,
	unpostAnswer: func,
	dispatch: func
};

const mapStateToProps = (state, props) => {
	const { student: userId } = state.ui.currentViewParams;

	const postedAnswers = (state.entities.postedAnswerIds[props.question.id] || [])
		.map((id) => {
			return state.entities.answers[id];
		})
		.sort(function (a1, a2) {
			if (a1.posted_at < a2.posted_at) return -1;
			if (a1.posted_at > a2.posted_at) return 1;
			return 0;
		});

	const activity = props.activity;
	const current_answer =
		activity && activity.answers && activity.answers[activity.answers.length - 1];

	const commentKey = `${userId}:${props.question.id}`;
	const comment = state.entities.webtextComments[commentKey];
	const posted_at = current_answer ? state.entities.answerPostings[current_answer.id] : null;

	const page = state.entities.pages[props.question.page_id];
	const chapter = state.entities.chapters[page.chapter_id];

	return {
		currentUser: state.entities.currentUser,
		api: state.data.api,
		course: state.entities.course,
		featureFlags: state.entities.featureFlags,
		chapter,
		page,
		timeZone: state.entities.course.time_zone,
		users: state.entities.users,
		postedAnswers,
		current_answer,
		commentKey,
		comment,
		posted_at
	};
};

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uiActions: bindActionCreators(uiActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withLibsThemeProvider(ResponseBoard));
