import React from 'react';

import { PollQuestionElement } from '@soomo/lib/types/WebtextManifest';

import AnalyticsLink from 'Components/AnalyticsLink';
import { Page } from 'Types/index';
import BaseTable from './BaseTable';

import pageViewStyles from '../PageView.scss';
import styles from '../AggregatedPageView.scss';
import InfoItem, { infoItemStyles } from './InfoItem';

interface PollQuestionChoiceAggregated {
	[choice_family_id: string]: number;
}

interface PollQuestionAggregated {
	aggregatedChoices: PollQuestionChoiceAggregated;
	firstAnswerUserId: number;
}

interface Props {
	element: PollQuestionElement;
	isPageActivityLoaded: boolean;
	aggregatedElement?: PollQuestionAggregated;
	totalStudentsCount?: number;
	page: Page;
	uiActions: any;
}

const PollQuestion: React.FC<Props> = (props) => {
	const { element, isPageActivityLoaded, aggregatedElement, totalStudentsCount, page, uiActions } =
		props;
	const { aggregatedChoices, firstAnswerUserId } = aggregatedElement || {};

	const completedCount = aggregatedChoices
		? Object.values(aggregatedChoices).reduce((curr, acc) => acc + curr, 0)
		: 0;

	const headerRow = ['Response', 'Student Selection'];
	const dataRows = element.choices.map((choice) => [
		<span dangerouslySetInnerHTML={{ __html: choice.body }} key={choice.family_id} />,
		aggregatedChoices?.[choice.family_id] || 0
	]);

	const onViewPollResults = () => {
		uiActions.updateCurrentViewParams({
			scope: page.id,
			student: firstAnswerUserId,
			showInContext: true,
			scrollTo: `poll_question_${element.id}`
		});
	};

	return (
		<div>
			<div className={pageViewStyles.ActivitySummary}>
				<span className={pageViewStyles.QuestionType}>Poll Question</span>
				{completedCount > 0 && (
					<>
						<span>&nbsp;&mdash;&nbsp;</span>
						<AnalyticsLink variant="text-uppercase" onClick={onViewPollResults}>
							view poll results
						</AnalyticsLink>
					</>
				)}
			</div>
			<h2
				className={pageViewStyles.QuestionBody}
				dangerouslySetInnerHTML={{ __html: element.body }}
			/>

			<BaseTable header={headerRow} rows={dataRows} isDataLoaded={isPageActivityLoaded} />

			<div className={styles.AggregatedQuestionInfo}>
				<InfoItem
					label="Completed:"
					data={
						isPageActivityLoaded ? `${completedCount}/${totalStudentsCount} students` : undefined
					}
				/>

				{completedCount > 0 && (
					<div className={infoItemStyles}>
						<AnalyticsLink variant="text" onClick={onViewPollResults}>
							View poll results
						</AnalyticsLink>
					</div>
				)}
			</div>
		</div>
	);
};

export default PollQuestion;
