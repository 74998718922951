// Takes object with keys and values to be stored in sessionStorage
export const setSessionStorage = (data) => {
	try {
		Object.keys(data).map((key) => {
			const serializedData = JSON.stringify(data[key]);
			sessionStorage.setItem(key, serializedData);
		});
		return true;
	} catch (error) {
		return error;
	}
};

// If passed a key, returns value if exists, otherwise returns all data in sessionStorage
export const getSessionStorage = (key) => {
	try {
		if (key) {
			// Only get data for this key
			const serializedData = sessionStorage.getItem(key);
			if (!serializedData) {
				return new Error('This key does not exist in sessionStorage');
			}
			return JSON.parse(serializedData);
		} else {
			// Get all sessionStorage data
			return Object.keys(sessionStorage).reduce((acc, key) => {
				return {
					...acc,
					[key]: sessionStorage.getItem(key)
				};
			}, {});
		}
	} catch (error) {
		return error;
	}
};

export const deleteSessionStorage = (key) => sessionStorage.removeItem(key);
