import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatDuration } from 'Utils/format';
import TippyRollover from '../TippyRollover';
import styles from '../GridView.scss';

class CourseTimingCell extends React.PureComponent {
	static propTypes = {
		user: PropTypes.object,
		scope: PropTypes.object,
		scopeType: PropTypes.string,
		course: PropTypes.object.isRequired,
		courseTimingLoaded: PropTypes.bool.isRequired,
		courseAverageTimings: PropTypes.object.isRequired,
		onlyMinutes: PropTypes.bool,
		isScrolling: PropTypes.bool
	};

	render() {
		const {
			course,
			courseAverageTimings,
			courseTimingLoaded,
			user,
			scope,
			scopeType,
			onlyMinutes,
			isScrolling
		} = this.props;

		let totalTimeSpent, explanation;

		if (user) {
			totalTimeSpent = user.total_time_spent;
			explanation = 'Total active time across all pages in the webtext for this student.';
		} else {
			if (scope) {
				totalTimeSpent = courseTimingLoaded ? courseAverageTimings[scope.id] || 0 : null;

				const type = scopeType === 'pageGroup' ? 'group of pages' : scopeType;
				let scopeText = 'on this page';
				if (scopeType === 'chapter') scopeText = 'in this chapter';
				if (scopeType === 'pageGroup') scopeText = 'in this group of pages';

				explanation = `Average active time ${scopeText} for all students in this class who visited the ${type}.`;
			} else {
				totalTimeSpent = course.average_total_time_spent;
				explanation =
					'Average active time for all students in this class who have some active time in the webtext.';
			}
		}

		const timeSpentInWords = formatDuration({ duration: totalTimeSpent, onlyMinutes });

		const style = {
			display: 'inline-block',
			padding: '0 8px'
		};

		return (
			<TippyRollover
				content={
					isScrolling ? null : <span className={styles.CellHoverExplanation}>{explanation}</span>
				}>
				<span style={style}>{timeSpentInWords}</span>
			</TippyRollover>
		);
	}
}

const mapStateToProps = (state) => ({
	course: state.entities.course,
	courseAverageTimings: state.entities.courseAverageTimings,
	courseTimingLoaded: state.entities.courseTimingLoaded
});

export default connect(mapStateToProps)(CourseTimingCell);
