import store from '../store';
import { updateCurrentViewParams } from 'Actions/uiActions';
import { requiredEntitiesAvailable, loadInitialData } from 'Actions/dataActions';
import saveMetric from 'Requests/SaveMetricMutation';

export default {
	load: () => {
		const loadStartedAt = Date.now();

		const detectAllRequiredEntitiesAvailable = setInterval(() => {
			const state = store.getState();

			if (!Object.keys(state.entities.elements).length) return;
			if (!state.entities.enrollments._loaded) return;
			if (!state.entities.currentUser.id) return;
			if (!state.entities.course.id) return;
			if (!state.entities.studentGrades) return;

			if (state.ui.initialView.element) {
				const element = state.entities.elements[state.ui.initialView.element];

				store.dispatch(
					updateCurrentViewParams({
						element: element.id,
						scope: element.page_id,
						student: state.entities.userIds[0],
						pageViewStudent: state.entities.userIds[0],
						welcomed: true
					})
				);
			}

			store.dispatch(
				saveMetric({
					courseId: state.entities.course.id,
					type: 'timing',
					name: 'analytics.app.ready',
					value: Date.now() - loadStartedAt
				})
			);
			store.dispatch(requiredEntitiesAvailable());
			store.dispatch(updateCurrentViewParams({}));

			clearInterval(detectAllRequiredEntitiesAvailable);
		}, 250);

		// Fetch all required initial data.
		store.dispatch(loadInitialData());
	}
};
