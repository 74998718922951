import { requestAsync, mergeData } from './';

export default ({ courseId, force = false }) =>
	requestAsync({
		path: '/api/courses/v1/comments',
		params: { course_id: courseId },
		transform: (payload) => {
			const webtextComments = {};

			payload.comments.forEach(function (comment) {
				webtextComments[`${comment.recipient_id}:${comment.thing_family_id}`] = {
					body: comment.comment_body,
					raw_body: comment.raw_comment_body,
					first_saved_at: comment.first_saved_at,
					last_saved_at: comment.last_saved_at,
					author_name: comment.author_name,
					seen_at: comment.comment_seen_at
				};
			});

			return { webtextComments };
		},
		update: {
			webtextComments: mergeData
		},
		force
	});
