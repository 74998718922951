import { createSelector } from 'reselect';

const chapterLabel = (chapter, chapterNamesIncludeNumbering) => {
	const chapterNumber = parseInt(chapter.chapter_number);
	let label = chapter.chapter_name;
	if (chapterNumber && !chapterNamesIncludeNumbering)
		label = chapterNumber + '&nbsp;&nbsp;' + label;
	return label;
};

const pageGroupLabel = (pageGroup) => {
	let label = pageGroup.name;
	if (pageGroup.page_group_number) label = pageGroup.page_group_number + ' ' + label;
	return label;
};

const pageLabel = (page) => {
	let label = page.page_name;
	if (page.page_nav_label) label = '<em>' + page.page_nav_label + ':</em> ' + label;
	if (page.page_number) label = page.page_number + ' ' + label;
	return label;
};

const pageGroupItem = (pageGroup, pagesById, elementsById, tocFilters) => ({
	id: pageGroup.id,
	label: pageGroupLabel(pageGroup),
	pages: pageGroup.page_ids
		.map((id) => pageItem(pagesById[id], elementsById, tocFilters))
		.filter(Boolean)
});

const pageItem = (page, elementsById, tocFilters) => {
	return pageVisible(page, elementsById, tocFilters)
		? {
				id: page.id,
				label: pageLabel(page)
		  }
		: null;
};

const pageVisible = (page, elementsById, tocFilters) => {
	if (!tocFilters.length) return true;

	return tocFilters.some((filter) => {
		switch (filter) {
			case 'templates': {
				return page.element_ids.some((id) => elementsById[id].type === 'interactive_template');
			}
			default:
				return true;
		}
	});
};

export default createSelector(
	(state) => state.entities.toc,
	(state) => state.entities.chapters,
	(state) => state.entities.page_groups,
	(state) => state.entities.pages,
	(state) => state.entities.elements,
	(state) => state.toc.filters,
	(toc, chaptersById, pageGroupsById, pagesById, elementsById, tocFilters) => {
		const tocHasPageGroups = Object.keys(pageGroupsById).length > 0;
		const chapterNamesIncludeNumbering = toc.config.chapters.chapter_names_include_numbering;

		const chapterIds = [];
		const pageGroupIds = [];
		const pageIds = [];

		const chapterOutlines = toc.chapter_ids.map((chapterId) => {
			chapterIds.push(chapterId);

			const chapter = chaptersById[chapterId];
			const chapterConfig = toc.config.chapters.types[chapter.chapter_type] || {};
			const chapterSettings = chapterConfig.settings || {};
			const chapterHasPageGroups = chapterSettings.page_groups_enabled;

			let chapterPageGroups = null;
			if (chapterHasPageGroups) {
				chapterPageGroups = chapter.page_group_ids.map((id) => {
					pageGroupIds.push(id);
					const pageGroupOutline = pageGroupItem(
						pageGroupsById[id],
						pagesById,
						elementsById,
						tocFilters
					);
					pageGroupOutline.pages.forEach((page) => {
						pageIds.push(page.id);
					});
					return pageGroupOutline;
				});
			}

			let chapterPages = null;
			if (!chapterHasPageGroups) {
				chapterPages = chapter.page_ids
					.map((id) => {
						const pageOutline = pageItem(pagesById[id], elementsById, tocFilters);
						if (pageOutline) {
							pageIds.push(pageOutline.id);
						}
						return pageOutline;
					})
					.filter(Boolean);
			}

			const singleCollapsedPage =
				chapterPages &&
				chapterPages.length === 1 &&
				chapterSettings.toc_nav_item_collapsed_if_single_page &&
				tocFilters.length === 0;

			return {
				id: chapter.id,
				label: chapterLabel(chapter, chapterNamesIncludeNumbering),
				pageGroups: chapterPageGroups,
				pages: chapterPages,
				hasPageGroups: chapterHasPageGroups,
				singleCollapsedPage
			};
		});

		return {
			hasPageGroups: tocHasPageGroups,
			chapters: chapterOutlines,
			chapterIds,
			pageGroupIds,
			pageIds
		};
	}
);
