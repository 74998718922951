import styles from './SelectorPanel.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import ScopeSelector from '../ScopeSelector';
import StudentSelector from '../StudentSelector';
import ElementSelector from '../ElementSelector';
import QuickActionsSelector from '../QuickActionsSelector';
import { viewTemplateTypes } from '../../constants';

const SelectorPanel = ({ currentViewTemplate, selectedScopeType, selectedElementId }) => {
	const isElementView = currentViewTemplate === viewTemplateTypes.ELEMENT;
	const isElementPresent = selectedElementId && selectedElementId.length > 0;
	const studentSelectorVisible = selectedScopeType === 'page' ? !isElementView : false;
	const elementSelectorVisible =
		selectedScopeType === 'page' && isElementPresent && selectedElementId.indexOf(':') > -1;

	return (
		<Paper elevation={1} square={true} className={styles.SelectorPanel}>
			<ScopeSelector />
			{studentSelectorVisible && <StudentSelector />}
			{elementSelectorVisible && <ElementSelector />}
			<QuickActionsSelector />
		</Paper>
	);
};

SelectorPanel.propTypes = {
	selectedScopeType: PropTypes.string,
	selectedElementId: PropTypes.string,
	currentViewTemplate: PropTypes.string
};

const mapStateToProps = (state) => ({
	selectedScopeType: state.entities.scopeTypes[state.ui.currentViewParams.scope],
	selectedElementId: state.ui.currentViewParams.element,
	currentViewTemplate: state.ui.viewTemplate
});

export default withRouter(connect(mapStateToProps)(SelectorPanel));
