import styles from './StudentSelector.scss';

import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { colors } from 'Theme';
import { studentsFilterOptions } from '../StudentList/StudentsFilters';

const studentsFilterLabels = {
	completed: (
		<span>
			<strong>completed</strong> the templates on this page (no comment yet)
		</span>
	),
	inProgress: (
		<span>
			<strong>started</strong> the templates on this page (no comment yet)
		</span>
	),
	noResponse: (
		<span>
			<strong>not started</strong> the templates on this page (no comment yet)
		</span>
	),
	revised: (
		<span>
			<strong>revised</strong> work in a template since you last commented
		</span>
	),
	alreadyCommented: (
		<span>
			<strong>received a comment</strong> on this page
		</span>
	),
	all: <span>Select all</span>
};

const theme = createTheme({
	typography: {
		useNextVariants: true
	},
	palette: {
		primary: {
			main: colors.blue
		},
		secondary: {
			main: colors.lighterBlue
		}
	}
});

function Filter({ filter, studentsFilters, onFilterChange }) {
	const checked =
		filter === 'all'
			? studentsFilters.length === studentsFilterOptions.length
			: studentsFilters.indexOf(filter) !== -1;

	return (
		<ThemeProvider theme={theme}>
			<FormControlLabel
				classes={{ root: styles.StudentsFilterFormControlLabel }}
				control={
					<Checkbox
						classes={{ root: styles.StudentsFilterCheckbox }}
						checked={checked}
						onChange={onFilterChange}
						value={filter}
					/>
				}
				label={studentsFilterLabels[filter]}
			/>
		</ThemeProvider>
	);
}

Filter.propTypes = {
	filter: PropTypes.string,
	studentsFilters: PropTypes.array,
	onFilterChange: PropTypes.func
};

export default Filter;
