import { requestAsync, mergeData } from './';

export default ({ courseId, userId, interactiveTemplateId, force }) =>
	requestAsync({
		path: `/api/courses/v1/webtext/builders/${interactiveTemplateId}`,
		params: { course_id: courseId, user_id: userId },
		transform: (data) => {
			const entities = { webtextBuilders: {} };
			entities.webtextBuilders[userId + ':' + interactiveTemplateId] = data;
			return entities;
		},
		update: { webtextBuilders: mergeData },
		force
	});
