import React from 'react';

import { CorrectnessIcon } from '@soomo/lib/notebook/components';

import ActivityHeader from 'Components/ActivityHeader';

import activityHistoryStyles from './ActivityHistory.scss';
import styles from './MultipleChoiceHistoryItem.scss';

import type { MultipleChoiceAnswer } from './types';

interface MultipleChoiceHistoryItemProps {
	timeZone: string;
	answerVersion: MultipleChoiceAnswer;
}

const MultipleChoiceHistoryItem: React.FC<MultipleChoiceHistoryItemProps> = (props) => {
	const { timeZone, answerVersion } = props;

	return (
		<div className={activityHistoryStyles.ActivityHistoryItem} data-test="activity-history-item">
			<ActivityHeader timestamp={answerVersion.created_at} timeZone={timeZone}>
				<div className={styles.CorrectnessAndChoice}>
					<CorrectnessIcon correct={answerVersion.correct} className={styles.CorrectnessIcon} />
					<span dangerouslySetInnerHTML={{ __html: answerVersion.body }} />
				</div>
			</ActivityHeader>
		</div>
	);
};

export default MultipleChoiceHistoryItem;
