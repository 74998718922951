import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import completionCellStyles from './CompletionCell.scss';
import LoadingShimmer from '../../containers/Views/AggregatedElements/LoadingShimmer';

const CompletionCell = ({ completion, fullyCompleted, opened, onClick, loaded }) => {
	if (!loaded) return <LoadingShimmer />;
	let innerStyle = {};
	let cellContent = '';
	let materialIcons = false;

	if (opened) {
		if (fullyCompleted) {
			cellContent = 'check';
			materialIcons = true;
		} else if (completion === 0.0 && opened) {
			cellContent = 'remove';
			materialIcons = true;
			innerStyle.height = '100%';
		} else {
			innerStyle.height = (1.0 - completion) * 100.0 + '%';
			innerStyle.borderRadius = completion > 0.05 ? '2px 2px 0 0' : '';
		}
	}

	return opened ? (
		<div className={completionCellStyles.Outer} onClick={onClick}>
			<div
				className={cx(
					completionCellStyles.Inner,
					fullyCompleted && completionCellStyles.FullyCompleted,
					opened && completion == 0.0 && completionCellStyles.OpenedOnly,
					{
						'material-icons': materialIcons
					}
				)}
				style={innerStyle}>
				{cellContent}
			</div>
		</div>
	) : (
		<div />
	);
};

CompletionCell.propTypes = {
	completion: PropTypes.number,
	fullyCompleted: PropTypes.bool,
	opened: PropTypes.bool,
	onClick: PropTypes.func
};

export default CompletionCell;
