import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuestionCountStats from './QuestionCountStats';

const PageGroupHeaderCellHover = ({ pageGroup, pageGroupStats }) => {
	const averageTimeInPageGroupInWords = pageGroupStats.avg_web_time_spent
		? `${Math.ceil(pageGroupStats.avg_web_time_spent / 60).toFixed(0)}m`
		: '0m';

	const averageScoreInPageGroup =
		pageGroupStats.avg_score != null ? (
			(pageGroupStats.avg_score * 100).toFixed(0) + '%'
		) : (
			<span>&mdash;</span>
		);

	return (
		<div>
			<div className="header">
				<h1
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: pageGroup.page_group_name }}
				/>
			</div>
			<div className="content">
				<h2>Summary</h2>
				<p>
					<span className="label">Words:</span>
					<span>{pageGroup.word_count}</span>
				</p>
				<p>
					<QuestionCountStats chapter={pageGroup} />
				</p>
				<p>
					<span className="label">Average Time in Page Group:</span>
					<span>
						<i className="ss-clock" /> {averageTimeInPageGroupInWords}
					</span>
				</p>
				<p>
					<span className="label">Average Score in Page Group:</span>
					<span>{averageScoreInPageGroup}</span>
				</p>
			</div>
			<div className="footer">
				<p className="note">
					<i className="ss-clock" /> Total time spent working on pages: clicking, typing, scrolling.
				</p>
			</div>
		</div>
	);
};

PageGroupHeaderCellHover.propTypes = {
	pageGroup: PropTypes.object,
	pageGroupStats: PropTypes.object
};

export default connect((state, props) => {
	const pageGroup = state.entities.page_groups[props.pageGroupId];

	const pageGroupStats = {
		avg_web_time_spent: state.entities.courseTimingLoaded
			? state.entities.courseAverageTimings[pageGroup.id] || 0
			: null,
		avg_score: state.entities.courseAverageScores[pageGroup.id]
	};

	return {
		pageGroup,
		pageGroupStats
	};
})(PageGroupHeaderCellHover);
