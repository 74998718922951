import { requestAsync } from './';

export default ({ courseId, force = false }) =>
	requestAsync({
		path: '/api/courses/v1/page_timings/summary',
		params: { course_id: courseId },
		transform: (payload) => {
			const entities = {
				courseAverageTimings: {},
				courseStdDevTimings: {},
				courseLowTimingCutoffs: {},
				courseHighTimingCutoffs: {},
				courseTimingCounts: {},
				courseTimingLoaded: true
			};

			Object.keys(payload).forEach(function (pageId) {
				const avg = payload[pageId][0];
				const stddev = payload[pageId][1];
				const lowTimingCutoff = payload[pageId][2];
				const highTimingCutoff = payload[pageId][3];
				const count = payload[pageId][4];

				entities.courseAverageTimings[pageId] = avg;
				entities.courseStdDevTimings[pageId] = stddev;
				entities.courseLowTimingCutoffs[pageId] = lowTimingCutoff;
				entities.courseHighTimingCutoffs[pageId] = highTimingCutoff;
				entities.courseTimingCounts[pageId] = count;
			});

			return entities;
		},
		update: {
			courseAverageTimings: (prev, current) => current || {},
			courseStdDevTimings: (prev, current) => current || {},
			courseLowTimingCutoffs: (prev, current) => current || {},
			courseHighTimingCutoffs: (prev, current) => current || {},
			courseTimingCounts: (prev, current) => current || {},
			courseTimingLoaded: (prev, current) => current || false
		},
		force
	});
