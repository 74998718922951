import { requestAsync, mergeData } from './';

export default ({ courseId, userId, popupQuizId, force }) =>
	requestAsync({
		path: `/api/courses/v1/webtext/popup_quizzes/${popupQuizId}`,
		params: { course_id: courseId, user_id: userId },
		transform: (data) => {
			const entities = { webtextPopupQuizzes: {} };
			entities.webtextPopupQuizzes[userId + ':' + popupQuizId] = data;
			return entities;
		},
		update: { webtextPopupQuizzes: mergeData },
		force
	});
