import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './ScreenLoading.scss';

const ScreenLoading = () => (
	<div styleName="ScreenLoading">
		<CircularProgress size={60} />
	</div>
);

export default ScreenLoading;
