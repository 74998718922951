import { requestAsync, mergeData } from './';

// Luxon doesn't support US/* names.
const resolveTimeZone = (timeZone) => {
	return (
		{
			'US/Pacific': 'America/Los_Angeles',
			'US/Mountain': 'America/Denver',
			'US/Central': 'America/Chicago',
			'US/Eastern': 'America/New_York'
		}[timeZone] || timeZone
	);
};

export default ({ courseId }) =>
	requestAsync({
		path: '/api/courses/v1/info',
		params: { course_id: courseId },
		transform: (payload) => ({
			course: {
				...payload.course,
				time_zone: resolveTimeZone(payload.course.time_zone)
			},
			featureFlags: payload.featureFlags
		}),
		update: {
			course: mergeData,
			featureFlags: (prev, current) => current || {}
		}
	});
