import React, { FC, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { AutoSizer } from 'react-virtualized';

import { GoReactElement } from '@soomo/lib/components/pageElements/GoReact/types';
import GoReactIframe from '@soomo/lib/components/pageElements/GoReact/GoReactIframe';

import RaisedButton from 'Components/RaisedButton';
import { useAppDispatch, useAppSelector } from 'Store/index';
import { updateCurrentViewParams } from 'Actions/uiActions';

import externalToolConfigQuery from 'Requests/ExternalToolConfigQuery';
import questionBulkStyles from '../QuestionBulkGraderView.scss';
import styles from './GoReactBulkGrader.scss';

const GoReactBulkGrader: FC = () => {
	const dispatch = useAppDispatch();

	const familyId = useAppSelector((state) => state.ui.currentViewParams.element);
	const element = useAppSelector<GoReactElement>((state) => state.entities.elements[familyId]);

	const courseId = useAppSelector((state) => state.entities.course.id);
	const scope = useAppSelector((state) => state.ui.currentViewParams.scope);

	const instructorId = useAppSelector((state) => state.entities.currentUser?.id);
	const pageViewStudent = useAppSelector((state) => state.ui.currentViewParams.pageViewStudent);

	const toolConfig = useAppSelector((state) => state.entities.externalToolsConfigs[familyId]);
	useEffect(() => {
		if (!toolConfig) {
			dispatch(externalToolConfigQuery({ courseId, studentId: null, instructorId, familyId }));
		}
	}, [dispatch, courseId, familyId, instructorId, toolConfig]);

	const handleBackToPageClick = () =>
		dispatch(
			updateCurrentViewParams({
				scope,
				student: pageViewStudent,
				element: null,
				pageViewStudent: null
			})
		);

	return (
		<div className={questionBulkStyles.Container}>
			<h2 className={questionBulkStyles.QuestionBody}>{element.title}</h2>
			<Paper className={questionBulkStyles.Paper} elevation={1} square={true}>
				<div className={questionBulkStyles.Buttons}>
					<RaisedButton
						label={`Back to ${pageViewStudent ? 'Page' : 'Overview'}`}
						onClick={handleBackToPageClick}
					/>
				</div>
				<div className={styles.VideoRecorderContainer}>
					<AutoSizer>
						{({ width, height }) => (
							<>
								{toolConfig ? (
									<div style={{ width, height }}>
										<GoReactIframe
											familyId={familyId}
											launchURL={toolConfig.launchURL}
											launchParams={toolConfig.launchParams}
											className={styles.VideoRecorder}
										/>
									</div>
								) : (
									<div style={{ width, height }} className={styles.VideoRecorderLoader}>
										<CircularProgress />
									</div>
								)}
							</>
						)}
					</AutoSizer>
				</div>
			</Paper>
		</div>
	);
};

export default GoReactBulkGrader;
