import styles from './SelfAssessment.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import pageViewStyles from '../PageView.scss';

import React, { Component } from 'react';
import { object, string, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import { unlinkify } from '@soomo/lib/utils';

import { PopupQuiz } from '@soomo/lib/components/pageElements';
import StudentAnswers from '@soomo/lib/components/pageElements/PopupQuiz/StudentAnswers';
import themes from '@soomo/lib/styles/themes';
import ThemeProvider from '@soomo/lib/styles/themes/ThemeProvider';
import * as uiActions from 'Actions/uiActions';
import resetQuizMutation from '../../../requests/ResetQuizMutation';
import pageActivityQuery from '../../../requests/PageActivityQuery';
import popupQuizQuery from 'Requests/PopupQuizQuery';
import metricsQuery from 'Requests/MetricsQuery';
import ConfirmDialog from 'Components/ConfirmDialog';
import PopupQuizNotebookView, {
	transformAnswerForNotebookView
} from '@soomo/lib/components/pageElements/PopupQuiz/NotebookView';
import ActivityHeader from 'Components/ActivityHeader';

class SelfAssessmentContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			confirmResetDialogVisible: false
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate() {
		this.fetchData();
	}

	fetchData = (force) => {
		const { dispatch, course, user, selfAssessment } = this.props;
		if (!user || !selfAssessment) return;

		dispatch(
			popupQuizQuery({
				courseId: course.id,
				userId: user.id,
				popupQuizId: selfAssessment.id,
				force
			})
		);
	};

	resetClickHandler = () => {
		this.setState({ confirmResetDialogVisible: true });
		return false;
	};

	confirmResetClickHandler = () => {
		const { course, user, chapter, page, selfAssessment: quiz, resetQuiz } = this.props;
		resetQuiz({ course, user, chapter, page, quiz });
		return false;
	};

	renderInContext = () => {
		const { toc, page, policyDocument: coursePolicy, selfAssessment: popupQuiz } = this.props;
		const pageConfig = toc.config.pages.types[page.page_type] || {};
		const elementConfig = (pageConfig.elements || {})['PopupQuiz'] || {};
		const data = this.props.popupQuizData;

		if (!data) return null;

		if (data.explanation) {
			data.explanation = unlinkify(data.explanation);
		}

		const themeName = popupQuiz.config.ui?.theme || this.props.toc.theme;
		return (
			<ThemeProvider theme={themes[themeName]}>
				<PopupQuiz
					popupQuiz={{
						family_id: popupQuiz.family_id,
						headingText: elementConfig.heading_text,
						...popupQuiz.config
					}}
					coursePolicy={coursePolicy}
					api={{
						load: () => Promise.resolve({ data }),
						save: () => undefined,
						reset: () => undefined
					}}
					pollingOptions={{
						disabled: true
					}}
					readOnly={true}
				/>
			</ThemeProvider>
		);
	};

	render() {
		if (this.props.inContext) return this.renderInContext();

		const { selfAssessment, activity, timeZone } = this.props;

		const config = selfAssessment.config;

		const answers = activity ? activity.answers || [] : [];
		const puqAnswers = answers?.map(transformAnswerForNotebookView) || [];

		const latestAnswer = puqAnswers[puqAnswers.length - 1];
		const results = latestAnswer && !latestAnswer.reset_at && latestAnswer.data;

		const hasNonResetAnswers = puqAnswers.some((answer) => !answer.reset_at);
		const showStudentAnswers = config.ui?.analytics?.showStudentAnswers;

		let resetAvailable = true;
		if (config.mode === 'assessment' && results && results.passed) {
			resetAvailable = false;
		}

		const onDialogClose = () => {
			this.setState({ confirmResetDialogVisible: false });
		};

		const dialog = (
			<ConfirmDialog
				open={this.state.confirmResetDialogVisible}
				onConfirm={this.confirmResetClickHandler}
				onClose={onDialogClose}
				dataTest="reset-puq-dialog">
				<p>
					Resetting a self-assessment will allow the student to re-answer the questions. Students
					are notified of all reset responses via email.
				</p>
				<p>{`Would you like to reset this student’s self-assessment?`}</p>
			</ConfirmDialog>
		);

		return (
			<div styleName="styles.SelfAssessment">
				{dialog}
				{results?.completed && hasNonResetAnswers && (
					<div styleName={cn('pageViewStyles.Symbol', 'pageViewStyles.IsCompleteSymbol')} />
				)}

				<div styleName="styles.SpaceLeft">
					<span styleName="pageViewStyles.QuestionType">Self Assessment</span>
				</div>

				<h2
					styleName="pageViewStyles.QuestionBody"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: config.name }}
				/>

				<ThemeProvider theme={themes[this.props.toc.theme]}>
					{results?.completed && (
						<ActivityHeader timestamp={latestAnswer.saved_at} timeZone={timeZone}>
							SAVED
						</ActivityHeader>
					)}

					<div styleName="styles.SpaceLeft">
						<PopupQuizNotebookView
							popupQuiz={config}
							questions={config.questions}
							answers={puqAnswers}
						/>
					</div>
				</ThemeProvider>

				{results && resetAvailable && (
					<div styleName="styles.Controls">
						<div>
							<button styleName="styles.Button" onClick={this.resetClickHandler}>
								Reset
							</button>
						</div>
					</div>
				)}

				{showStudentAnswers && results?.completed && (
					<StudentAnswers
						questions={config.questions}
						answers={puqAnswers}
						className={styles.StudentAnswersContainer}
					/>
				)}
			</div>
		);
	}
}

SelfAssessmentContainer.propTypes = {
	course: object.isRequired,
	policyDocument: object.isRequired,
	user: object,
	toc: object.isRequired,
	chapter: object.isRequired,
	page: object.isRequired,
	selfAssessment: object.isRequired,
	popupQuizData: object,
	activity: object,
	timeZone: string.isRequired,
	inContext: bool.isRequired,
	resetQuiz: func.isRequired,
	dispatch: func.isRequired
};

const mapStateToProps = (state, props) => {
	const course = state.entities.course;
	const toc = state.entities.toc;
	const page = state.entities.pages[props.selfAssessment.page_id];
	const chapter = state.entities.chapters[page.chapter_id];
	const popupQuizData = props.user
		? state.entities.webtextPopupQuizzes[props.user.id + ':' + props.selfAssessment.id]
		: null;

	return {
		course,
		policyDocument: state.entities.course.policy_document,
		toc,
		chapter,
		page,
		popupQuizData,
		timeZone: course.time_zone
	};
};

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uiActions: bindActionCreators(uiActions, dispatch),
	resetQuiz: ({ course, user, chapter, page, quiz }) =>
		dispatch(
			resetQuizMutation({ courseId: course.id, userId: user.id, pageId: page.id, quizId: quiz.id })
		).then(() => {
			dispatch(
				pageActivityQuery({ courseId: course.id, userId: user.id, pageId: page.id, force: true })
			);
			dispatch(
				metricsQuery({
					courseId: course.id,
					userIds: [user.id],
					scopeId: chapter.id,
					groupby: 'page',
					force: true
				})
			);
		})
});

export default connect(mapStateToProps, mapDispatchToProps)(SelfAssessmentContainer);
