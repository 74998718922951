import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NotScorableIcon from './NotScorableIcon';
import { formatScore } from 'Utils/format';
import TippyRollover from '../TippyRollover';
import styles from '../GridView.scss';

class CohortScoreCell extends React.PureComponent {
	static propTypes = {
		scope: PropTypes.object.isRequired,
		scopeTypes: PropTypes.object.isRequired,
		cohortAverageScore: PropTypes.number,
		cohortAverageScores: PropTypes.object.isRequired,
		isScrolling: PropTypes.bool
	};

	render() {
		const { cohortAverageScore, cohortAverageScores, scope, scopeTypes, isScrolling } = this.props;

		let score, scoreText, explanation;

		const scopeType = scopeTypes[scope.id];

		if (scopeType === 'toc') {
			score = cohortAverageScore;
			explanation = 'Average of all student scores in all sections.';
		} else {
			score = cohortAverageScores[scope.id];

			let scopeText = 'on this page';
			if (scopeType === 'chapter') scopeText = 'in this chapter';
			if (scopeType === 'pageGroup') scopeText = 'in this group of pages';

			if (scope.question_counts.mc > 0 || scope.question_counts.mcqp > 0) {
				explanation = `Average score of students in all sections who attempted at least one multiple-choice question ${scopeText}.`;
			} else {
				explanation = `There are no scoreable questions ${scopeText}.`;
				scoreText = <NotScorableIcon />;
			}
		}

		if (!scoreText) {
			scoreText = score != null ? formatScore({ score }) : <span>&mdash;</span>;
		}

		return (
			<TippyRollover
				content={
					isScrolling ? null : <span className={styles.CellHoverExplanation}>{explanation}</span>
				}>
				<span>{scoreText}</span>
			</TippyRollover>
		);
	}
}

const mapStateToProps = (state) => ({
	cohortAverageScore: state.entities.cohortAverageScore,
	cohortAverageScores: state.entities.cohortAverageScores,
	scopeTypes: state.entities.scopeTypes
});

export default connect(mapStateToProps)(CohortScoreCell);
