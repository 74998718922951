import styles from './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class ViewSelectorFilter extends React.Component {
	static propTypes = {
		label: PropTypes.string,
		content: PropTypes.node,
		open: PropTypes.bool,
		onOpenChange: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null
		};
	}

	onToggleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
		this.props.onOpenChange(!this.props.open);
	};

	onPopoverClose = () => {
		this.setState({ anchorEl: null });
		this.props.onOpenChange(false);
	};

	render() {
		const { label, content, open } = this.props;
		const { anchorEl } = this.state;

		return (
			<>
				<div
					className={cn(styles.SelectorFilterToggleContainer, {
						[styles.SelectorFilterToggleContainerWhenPopoverOpen]: open
					})}>
					<Button classes={{ root: styles.SelectorFilterToggle }} onClick={this.onToggleClick}>
						<span className={styles.SelectorFilterToggleLabel}>
							{label}
							<ExpandMoreIcon />
						</span>
					</Button>
				</div>
				<Popover
					classes={{ paper: styles.SelectorFilterContent }}
					open={open}
					anchorEl={anchorEl}
					onClose={this.onPopoverClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}>
					{content}
				</Popover>
			</>
		);
	}
}

export default ViewSelectorFilter;
