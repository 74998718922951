import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withCourseScopedQuery } from 'Requests';
import pageActivityQuery from 'Requests/PageActivityQuery';
import LabeledLine from './LabeledLine';
import LabeledSpan from './LabeledSpan';
import { formatDuration, formatPercentage, formatTime } from 'Utils/format';
import TextLoadingIndicator from 'Components/TextLoadingIndicator';

const PageCellHover = ({ page, pageActivity, timeZone }) => {
	const {
		questions_completed: questionsCompleted,
		questions_correct: questionsCorrect,
		questions_graded: questionsGraded,
		last_saved_at: lastSavedAt
	} = pageActivity || {};

	const questionsPossible = page.questions_possible;
	const questionsScored = page.question_counts.mc + page.question_counts.mcqp;
	const questionsGradable = page.question_counts.instructor_grade_external_tool;

	const percentageCompleted = formatPercentage({
		num: questionsCompleted,
		den: questionsPossible
	});
	const percentageCorrect = formatPercentage({ num: questionsCorrect, den: questionsScored });
	const percentageGraded = formatPercentage({
		num: questionsGraded,
		den: questionsGradable
	});

	const lastViewedAt = pageActivity && pageActivity.last_activity_at;
	const timeSpent = pageActivity
		? (pageActivity.web_time_spent || 0) + (pageActivity.mobile_time_spent || 0)
		: 0;

	const loadingIndicator = <TextLoadingIndicator color="darkWhite" size={5} />;

	const lastViewedText = pageActivity
		? formatTime({ time: lastViewedAt, timeZone }) || <span>&mdash;</span>
		: loadingIndicator;

	const activityText = pageActivity ? formatDuration({ duration: timeSpent }) : loadingIndicator;

	const completedText = pageActivity ? (
		<span>
			{questionsCompleted != null ? questionsCompleted : <span>&ndash;</span>}/{questionsPossible}
			{percentageCompleted && <span> ({percentageCompleted})</span>}
		</span>
	) : (
		loadingIndicator
	);

	const scoredText = pageActivity ? (
		<span>
			{questionsCorrect != null ? questionsCorrect : <span>&ndash;</span>}/{questionsScored}
			{percentageCorrect && <span> ({percentageCorrect})</span>}
		</span>
	) : (
		loadingIndicator
	);

	const gradedText = pageActivity ? (
		<span>
			{questionsGraded != null ? questionsGraded : <span>&ndash;</span>}/{questionsGradable}
			{percentageGraded && <span> ({percentageGraded})</span>}
		</span>
	) : (
		loadingIndicator
	);

	const lastSavedText = pageActivity
		? formatTime({ time: lastSavedAt, timeZone }) || <span>&mdash;</span>
		: loadingIndicator;

	let page_title = page.page_name;
	if (page.page_nav_label) page_title = `<em>${page.page_nav_label}</em>: ${page_title}`;

	return (
		<div>
			<div className="header">
				<h1 className="title" dangerouslySetInnerHTML={{ __html: page_title }} />
			</div>
			<div className="content">
				<LabeledLine label="Last Viewed">{lastViewedText}</LabeledLine>
				<LabeledLine label="Activity">
					<i className="ss-clock" /> {activityText}
				</LabeledLine>
				{questionsPossible > 0 && (
					<div>
						<p className="questionsProgress">
							<LabeledSpan idx={0} label="Answered">
								{completedText}
							</LabeledSpan>
							{questionsScored > 0 && (
								<LabeledSpan idx={1} label="Correct">
									{scoredText}
								</LabeledSpan>
							)}
							{questionsGradable > 0 && (
								<LabeledSpan idx={2} label="Graded">
									{gradedText}
								</LabeledSpan>
							)}
						</p>
						<LabeledLine label="Last Update">{lastSavedText}</LabeledLine>
					</div>
				)}
			</div>
			<div className="footer">
				<p className="note">
					<i className="ss-clock" /> Total time spent working on pages: clicking, typing, scrolling.
				</p>
			</div>
		</div>
	);
};

PageCellHover.propTypes = {
	userId: PropTypes.number.isRequired,
	pageId: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
	page: PropTypes.object.isRequired,
	pageActivity: PropTypes.object,
	timeZone: PropTypes.string
};

const mapStateToProps = (state, props) => {
	return {
		user: state.entities.users[props.userId],
		page: state.entities.pages[props.pageId],
		pageActivity: state.entities.pageActivities[props.userId + ':' + props.pageId],
		timeZone: state.entities.course.time_zone
	};
};

const mapPropsToQueryParams = ({ userId, pageId }) => ({ userId, pageId });

export default connect(mapStateToProps)(
	withCourseScopedQuery(pageActivityQuery, mapPropsToQueryParams)(PageCellHover)
);
