import React, { Component } from 'react';
import { object, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

import { MultipleChoiceQuestion } from '@soomo/lib/components/pageElements';
import { CorrectnessIcon } from '@soomo/lib/notebook/components';

import styles from './MCQuestion.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import pageViewStyles from '../PageView.scss';

import ActivityHeader from 'Components/ActivityHeader';
import ActivityHistory, { MultipleChoiceHistoryItem } from 'Containers/ActivityHistory';
import withLibsThemeProvider from 'Hocs/withLibsThemeProvider';

export const buildAnswerForLibsComponent = ({ page, question, activity, policyDocument }) => {
	let answer = activity && activity.answers && activity.answers[activity.answers.length - 1];
	if (answer && answer.reset_at) answer = null;
	if (!answer) return null;

	let policy = policyDocument.policies[policyDocument.instances[page.id]];
	policy || (policy = policyDocument.policies[policyDocument.defaults['NG::Traditional::Page']]);

	const attemptsAllowed = policy.attempts_allowed;
	const resetsTaken =
		(activity &&
			activity.answers &&
			activity.answers.filter((answer) => !!answer.reset_at).length) ||
		0;
	const attemptsTaken = resetsTaken + (answer ? 1 : 0);
	const attemptsLeft = attemptsAllowed > 0 ? Math.max(attemptsAllowed - attemptsTaken, 0) : -1;
	const finalAttempt = attemptsLeft === 0;

	const selectedChoice = answer
		? question.choices.filter((choice) => choice.id === answer.body)[0]
		: null;

	const rejoinder = selectedChoice
		? finalAttempt
			? selectedChoice.rejoinder_final_attempt
			: selectedChoice.rejoinder
		: null;

	return {
		body: answer.body,
		correct: selectedChoice.is_correct,
		completed: answer.body != null,
		rejoinder: rejoinder,
		updated_at: answer.saved_at
	};
};

class MCQuestion extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			showAttemptsHistory: false
		};

		this.renderInContext = this.renderInContext.bind(this);
		this.toggleAttemptsHistory = this.toggleAttemptsHistory.bind(this);
	}

	toggleAttemptsHistory() {
		this.setState({ showAttemptsHistory: !this.state.showAttemptsHistory });
		return false;
	}

	getElementLabel() {
		const { page, policyDocument } = this.props;
		let policy = policyDocument.policies[policyDocument.instances[page.id]];
		policy || (policy = policyDocument.policies[policyDocument.defaults['NG::Traditional::Page']]);
		return policy.mc_question_type_label;
	}

	renderInContext() {
		const { page, question, activity, policyDocument } = this.props;

		return (
			<MultipleChoiceQuestion
				elementLabel={this.getElementLabel()}
				questionFamilyId={question.id}
				body={question.body}
				choices={question.choices.map((choice) => ({
					family_id: choice.id,
					body: choice.body
				}))}
				onSubmit={() => null}
				answer={buildAnswerForLibsComponent({ page, question, activity, policyDocument })}
				readOnly
			/>
		);
	}

	render() {
		const { question, activity, inContext, timeZone } = this.props;

		if (inContext) return this.renderInContext();

		let answer = activity && activity.answers && activity.answers[activity.answers.length - 1];
		if (answer && answer.reset_at) answer = null;
		const correct_choice_ids = question.choices.filter((ch) => ch.is_correct).map((ch) => ch.id);
		const correct = answer && correct_choice_ids.indexOf(answer.body) > -1;

		const buildActivityHistory = () => {
			if (!activity || !activity.answers) return [];
			return activity.answers
				.map((a) => ({
					...a,
					created_at: a.saved_at,
					body: question.choices.find((v) => v.id === a.body).body,
					event: 'submitted',
					correct: correct_choice_ids.indexOf(a.body) > -1
				}))
				.reverse();
		};

		const answerVersions = buildActivityHistory();

		return (
			<div styleName="styles.MCQuestion">
				{answer && <CorrectnessIcon correct={correct} className={styles.CurrentIsCorrect} />}
				<div styleName="styles.ActivitySummary">
					<span styleName="pageViewStyles.QuestionType">{this.getElementLabel()}</span>
				</div>
				<h2
					styleName="pageViewStyles.QuestionBody"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: question.body }}
				/>
				{Boolean(answer && answer.saved_at) && (
					<ActivityHeader timestamp={answer.saved_at} timeZone={timeZone}>
						LATEST ATTEMPT
					</ActivityHeader>
				)}
				<ul styleName="styles.QuestionChoiceList">
					{question.choices.map((choice) => {
						let styleNames = ['styles.QuestionChoice'];
						if (choice.is_correct) {
							styleNames.push('styles.CorrectChoice');
						}

						return (
							<li key={choice.id} styleName={cn(styleNames)}>
								<input
									type="radio"
									id={`choice_${choice.version}`}
									checked={answer ? answer.body === choice.id : false}
									disabled={true}
								/>
								<label
									htmlFor={`choice_${choice.version}`}
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={{ __html: choice.body }}
								/>
							</li>
						);
					})}
				</ul>

				{answerVersions.length > 0 && (
					<ActivityHistory
						answer={answer}
						answerVersions={answerVersions}
						attemptCount={answerVersions.length}
						timeZone={timeZone}>
						{answerVersions.map((item, index) => (
							<MultipleChoiceHistoryItem
								timeZone={this.props.timeZone}
								answerVersion={item}
								key={`history-${index}`}
							/>
						))}
					</ActivityHistory>
				)}
			</div>
		);
	}
}

MCQuestion.propTypes = {
	page: object.isRequired,
	question: object.isRequired,
	activity: object,
	timeZone: string.isRequired,
	policyDocument: object.isRequired,
	inContext: bool.isRequired,
	toc: object
};

const mapStateToProps = (state) => ({
	page: state.entities.pages[state.ui.currentViewParams.scope],
	timeZone: state.entities.course.time_zone,
	policyDocument: state.entities.course.policy_document,
	toc: state.entities.toc
});

export default connect(mapStateToProps)(withLibsThemeProvider(MCQuestion));
