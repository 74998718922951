import Utils from './Utils';
import OutputContext from './OutputContext';

export default function ({ builder, selections }) {
	const output = new OutputContext(builder, selections);

	switch (builder.config.output.type) {
		case 'text':
			return buildOutput({ output, items: builder.config.output.text });

		case 'outline':
			return buildOutput({ output, items: builder.config.output.outline, addPara: true });

		default:
			return '';
	}
}

function buildOutput({ output, items, addPara }) {
	const options = { addPara: !!addPara };
	let result = '';

	items.forEach(function (item) {
		if (!item.show || item.show === 'output') {
			switch (item.kind) {
				case 'para':
					result +=
						'<p>' + buildOutput({ output, items: item.items, addPara: options.addPara }) + '</p>';
					break;

				case 'list':
					result +=
						'<ol>' + buildOutput({ output, items: item.items, addPara: options.addPara }) + '</ol>';
					break;

				case 'item':
					result +=
						'<li>' +
						buildOutput({ output, items: item.elements, addPara: options.addPara }) +
						'</li>';
					break;

				case 'fill-in':
				case 'paste-in': {
					const addPara = options.addPara && item.para === true;
					const key = item.dest || item.source;
					const sourceItem = output.builder.getSourceFromDestination(item);
					let value;

					if (addPara) result += '<p>';
					if (sourceItem && sourceItem.redactorEnabled && sourceItem.citationsEnabled) {
						value = output.getFillInWithCitationOutput({
							item,
							onScreen: true,
							useMarkerCitations: true
						});
						value = value.replace(/\n/gi, '');
					} else {
						if (item.redactorEnabled) {
							value = output.selections[key];
						} else {
							value = output.selections[key] || '';
						}
					}
					if (!output.builder.isParagraphAllowed({ source: key })) {
						value = Utils.removeParagraphTags(value);
					}
					result += value;
					if (addPara) result += '</p>';
					break;
				}

				case 'fill-in-with-citations': {
					const addPara = options.addPara && item.para === true;
					if (addPara) result += '<p>';
					const value = output.getFillInWithCitationOutput({
						item,
						onScreen: true,
						useMarkerCitations: false
					});
					result += value;
					if (addPara) result += '</p>';
					break;
				}

				case 'value-of':
					result += output.valueOf(item);
					break;

				case 'text': {
					const addPara = options.addPara && (!item.para || item.para === true);
					if (addPara) result += '<p>';
					result += item.value;
					if (addPara) result += '</p>';
					break;
				}

				case 'table': {
					const numRows = item.rows.length;
					result += '<table width="100%" class="table-prompt-output">';
					for (let rowIdx = 0; rowIdx < numRows; rowIdx++) {
						const row = item.rows[rowIdx];
						const numColumns = row.length;
						const columnWidth = 100 / numColumns;
						result += '<tr>';
						for (let colIdx = 0; colIdx < numColumns; colIdx++) {
							const rowItem = row[colIdx];
							result += `<td width="${columnWidth}%">${buildOutput({
								output,
								items: [rowItem]
							})}</td>`;
						}
						result += '</tr>';
					}
					result += '</table>';
					break;
				}
			}
		}
	});

	return result;
}
