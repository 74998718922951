import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatCompletion } from 'Utils/format';
import TippyRollover from '../TippyRollover';
import styles from '../GridView.scss';

class CohortCompletionCell extends React.PureComponent {
	static propTypes = {
		scope: PropTypes.object.isRequired,
		scopeTypes: PropTypes.object.isRequired,
		cohortAverageCompletions: PropTypes.object.isRequired,
		cohortCountVisited: PropTypes.object.isRequired,
		cohortUserCount: PropTypes.number,
		isScrolling: PropTypes.bool
	};

	render() {
		const {
			cohortAverageCompletions,
			cohortCountVisited,
			cohortUserCount,
			scope,
			scopeTypes,
			isScrolling
		} = this.props;

		let completion, explanation;

		const scopeType = scopeTypes[scope.id];

		if (scope.total_question_count === 0) {
			const visited = cohortCountVisited[scope.id];
			completion = visited && cohortUserCount ? visited / cohortUserCount : 0;

			if (scopeType === 'toc') {
				explanation = 'Percentage of students in all sections who have accessed the webtext.';
			} else if (scopeType === 'chapter') {
				explanation = 'Percentage of students in all sections who have opened this chapter.';
			} else if (scopeType === 'pageGroup') {
				explanation =
					'Percentage of students in all sections who have opened a page in this group of pages.';
			} else if (scopeType === 'page') {
				explanation = 'Percentage of students in all sections who have opened this page.';
			}
		} else {
			completion = cohortAverageCompletions[scope.id] || 0;
			explanation = 'Average percentage of questions answered by students in all sections.';
		}

		const completionText =
			completion != null ? formatCompletion({ completion }) : <span>&mdash;</span>;

		return (
			<TippyRollover
				content={
					isScrolling ? null : <span className={styles.CellHoverExplanation}>{explanation}</span>
				}>
				<span>{completionText}</span>
			</TippyRollover>
		);
	}
}

const mapStateToProps = (state) => ({
	cohortAverageCompletions: state.entities.cohortAverageCompletions,
	cohortCountVisited: state.entities.cohortCountVisited,
	cohortUserCount: state.entities.cohortUserCount,
	scopeTypes: state.entities.scopeTypes
});

export default connect(mapStateToProps)(CohortCompletionCell);
